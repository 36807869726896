import { FC, createContext } from 'react';

import useContextWithCheck from 'contexts/useContextWithCheck';
import useMemoProps from 'contexts/useMemoProps';
import { noop } from 'shared/helpers/noop';
import { ChildrenProp } from 'types';
import { Technologies, TechnologiesApi, TechnologyDetails } from 'types/technology.types';

import { initFiltersState } from './hooks/useFiltersService';
import useSearchTechnologies from './hooks/useSearchTechnologies';
import { FiltersState } from './services/Filters.service';

const FilteredTechnologiesContext = createContext<
  TechnologiesProviderProps['filteredTechnologies']
>({});
export const useFilteredTechnologies = () =>
  useContextWithCheck(FilteredTechnologiesContext, 'FilteredTechnologiesContext');

const TechnologyContext = createContext<TechnologiesProviderProps['technology']>(null);
export const useTechnology = () => useContextWithCheck(TechnologyContext, 'TechnologyContext');

const SetTechnologyContext = createContext<{
  setTechnology: TechnologiesProviderProps['setTechnology'];
  resetTechnology: TechnologiesProviderProps['resetTechnology'];
}>({
  setTechnology: noop,
  resetTechnology: noop,
});
export const useSetTechnology = () =>
  useContextWithCheck(SetTechnologyContext, 'SetTechnologyContext');

const TechnologiesContext = createContext<TechnologiesProviderProps['technologies']>(null);
export const useTechnologies = () =>
  useContextWithCheck(TechnologiesContext, 'TechnologiesContext');

const InitTechnologiesToStateContext =
  createContext<TechnologiesProviderProps['initTechnologiesToState']>(noop);
export const useInitTechnologiesToState = () =>
  useContextWithCheck(InitTechnologiesToStateContext, 'InitTechnologiesToStateContext');

const ApplyFilterContext = createContext<TechnologiesProviderProps['applyFilter']>(noop);
export const useApplyFilter = () => useContextWithCheck(ApplyFilterContext, 'ApplyFilterContext');

const FiltersStateContext =
  createContext<TechnologiesProviderProps['filtersState']>(initFiltersState);
export const useFiltersState = () =>
  useContextWithCheck(FiltersStateContext, 'FiltersStateContext');

const FilterTechnologiesContext = createContext<ReturnType<typeof useSearchTechnologies>>({
  handleSearchChange: noop,
  resetSelections: noop,
  searchPhrase: '',
});
export const useFilterTechnologies = () =>
  useContextWithCheck(FilterTechnologiesContext, 'FilterTechnologiesContext');

export interface TechnologiesProviderProps extends ChildrenProp {
  filterTechnologies: ReturnType<typeof useSearchTechnologies>;
  filteredTechnologies: Technologies;
  technologies: Technologies | null;
  initTechnologiesToState: (v: TechnologiesApi) => void;
  applyFilter: (filterState: Partial<FiltersState>) => void;
  filtersState: FiltersState;
  technology: Nullable<TechnologyDetails>;
  setTechnology: (v: TechnologyDetails) => void;
  resetTechnology: () => void;
}

const TechnologiesProvider: FC<TechnologiesProviderProps> = ({
  children,
  filteredTechnologies,
  technologies,
  initTechnologiesToState,
  filterTechnologies,
  applyFilter,
  filtersState,
  technology,
  setTechnology,
  resetTechnology,
}: TechnologiesProviderProps) => {
  const setters = useMemoProps({
    setTechnology,
    resetTechnology,
  });

  return (
    <FilteredTechnologiesContext.Provider value={filteredTechnologies}>
      <FilterTechnologiesContext.Provider value={filterTechnologies}>
        <InitTechnologiesToStateContext.Provider value={initTechnologiesToState}>
          <TechnologiesContext.Provider value={technologies}>
            <ApplyFilterContext.Provider value={applyFilter}>
              <FiltersStateContext.Provider value={filtersState}>
                <SetTechnologyContext.Provider value={setters}>
                  <TechnologyContext.Provider value={technology}>
                    {children}
                  </TechnologyContext.Provider>
                </SetTechnologyContext.Provider>
              </FiltersStateContext.Provider>
            </ApplyFilterContext.Provider>
          </TechnologiesContext.Provider>
        </InitTechnologiesToStateContext.Provider>
      </FilterTechnologiesContext.Provider>
    </FilteredTechnologiesContext.Provider>
  );
};

export default TechnologiesProvider;
