import { HStack, VStack } from '@chakra-ui/react';
import { capitalize } from 'lodash';

import Accordion from 'components/Accordion/Accordion';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import SidePanelSectionHeader from 'components/SidePanel/SidePanelSectionHeader/SidePanelSectionHeader';
import Skeleton from 'components/Skeleton/Skeleton';
import { TechnologyDetails } from 'types/technology.types';

import ResourcesList from './ResourcesList/ResourcesList';
import {
  BlogPostResource,
  BlogPostSource,
  CaseStudyDomain,
  CaseStudyResource,
  ResourceType,
} from './types';
import useBlogPostsQuery from './useBlogPostsQuery';
import useCaseStudiesQuery from './useCaseStudiesQuery';

interface TechnologyDetailsResourcesProps {
  technology: TechnologyDetails;
}

const TechnologyDetailsResources = ({ technology }: TechnologyDetailsResourcesProps) => {
  const caseStudiesQueryResults = useCaseStudiesQuery({ technologyId: technology.id });
  const blogPostsQueryResults = useBlogPostsQuery({ technologyId: technology.id });

  const caseStudiesData: CaseStudyResource[] = caseStudiesQueryResults.data?.items || [];
  const blogPostData: BlogPostResource[] = blogPostsQueryResults.data?.items || [];

  const getCaseStudiesByDomain = (caseStudyDomain: CaseStudyDomain) => {
    return caseStudiesData?.filter(i => i.domain === caseStudyDomain);
  };

  const getBlogPostBySource = (blogPostSource: BlogPostSource) => {
    return blogPostData?.filter(i => i.source === blogPostSource);
  };

  const getLabelForCaseStudyGroup = (caseStudyDomain: CaseStudyDomain) => {
    return `${capitalize(caseStudyDomain)} (${getCaseStudiesByDomain(caseStudyDomain).length})`;
  };

  const getLabelForBlogPostGroup = (blogPostSource: BlogPostSource) => {
    return `${capitalize(blogPostSource)} (${getBlogPostBySource(blogPostSource).length})`;
  };

  return (
    <>
      <SidePanelSectionHeader>
        <HStack spacing="8px">
          <span>Resources</span>
          <InfoTooltip tooltipText="Resources consist of Netguru knowledge and case studies gathered in one place. You can review all the resources to a related skill." />
        </HStack>
      </SidePanelSectionHeader>

      <VStack spacing="32px" align="stretch">
        <Skeleton isLoaded={!caseStudiesQueryResults.isLoading}>
          <Accordion label="Case studies" defaultExpanded>
            <VStack spacing="24px" align="stretch">
              <Accordion label={getLabelForCaseStudyGroup(CaseStudyDomain.Behance)} level={2}>
                <ResourcesList
                  resources={getCaseStudiesByDomain(CaseStudyDomain.Behance)}
                  resourceType={ResourceType.CaseStudy}
                  technologyName={technology.name}
                />
              </Accordion>
              <Accordion label={getLabelForCaseStudyGroup(CaseStudyDomain.Dribbble)} level={2}>
                <ResourcesList
                  resources={getCaseStudiesByDomain(CaseStudyDomain.Dribbble)}
                  resourceType={ResourceType.CaseStudy}
                  technologyName={technology.name}
                />
              </Accordion>
              <Accordion label={getLabelForCaseStudyGroup(CaseStudyDomain.Netguru)} level={2}>
                <ResourcesList
                  resources={getCaseStudiesByDomain(CaseStudyDomain.Netguru)}
                  resourceType={ResourceType.CaseStudy}
                  technologyName={technology.name}
                />
              </Accordion>
            </VStack>
          </Accordion>
        </Skeleton>

        <Skeleton isLoaded={!blogPostsQueryResults.isLoading}>
          <Accordion label="R&D" defaultExpanded>
            <VStack spacing="24px" align="stretch">
              <Accordion label={getLabelForBlogPostGroup(BlogPostSource.Confluence)} level={2}>
                <ResourcesList
                  resources={getBlogPostBySource(BlogPostSource.Confluence)}
                  resourceType={ResourceType.CaseStudy}
                  technologyName={technology.name}
                />
              </Accordion>
              <Accordion label={getLabelForBlogPostGroup(BlogPostSource.External)} level={2}>
                <ResourcesList
                  resources={getBlogPostBySource(BlogPostSource.External)}
                  resourceType={ResourceType.CaseStudy}
                  technologyName={technology.name}
                />
              </Accordion>
            </VStack>
          </Accordion>
        </Skeleton>

        <Skeleton isLoaded={!blogPostsQueryResults.isLoading}>
          <Accordion label="Blog articles" defaultExpanded>
            <VStack spacing="24px" align="stretch">
              <ResourcesList
                resources={getBlogPostBySource(BlogPostSource.NetguruBlog)}
                resourceType={ResourceType.Article}
                technologyName={technology.name}
                shouldDisplayTags={false}
                shouldDisplaySummary={false}
              />
            </VStack>
          </Accordion>
        </Skeleton>
      </VStack>
    </>
  );
};

export default TechnologyDetailsResources;
