import { ReactNode } from 'react';

import cn from 'classnames';

import styles from './BigCardWrapper.module.scss';

export interface BigCardWrapperProps {
  label: ReactNode;
  subLabel: ReactNode;
  children: ReactNode;
  disabled?: boolean;
}

const BigCardWrapper = ({ label, children, subLabel, disabled }: BigCardWrapperProps) => {
  return (
    <label
      className={cn(styles.label, {
        [styles['label--disabled']]: disabled,
      })}
    >
      <span
        className={cn(styles.subLabel, {
          [styles['subLabel--disabled']]: disabled,
        })}
      >
        {subLabel}
      </span>
      <span
        className={cn(styles.title, {
          [styles['title--disabled']]: disabled,
        })}
      >
        {label}
      </span>
      {children}
    </label>
  );
};

export default BigCardWrapper;
