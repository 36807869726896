import { useContext, Context as IContext } from 'react';

const useContextWithCheck = <T>(Context: IContext<T>, contextName: string): T => {
  const value = useContext(Context);
  if (value === undefined) {
    throw new Error(`Component is beyond context. ${contextName}`);
  }
  return value;
};

export default useContextWithCheck;
