/* eslint-disable camelcase */
import constants from 'shared/constants';
import {
  Technologies,
  TechnologiesApi,
  TechWithStage,
  STAGES_ENUM,
  TechnologyDetailsApi,
  TechnologyDetails,
} from 'types/technology.types';

const TechnologiesDataService = () => {
  function filterOutUnsupportedStages<T extends TechWithStage>(technologies: T[]): T[] {
    return technologies.filter(({ stage }) => {
      if (stage?.name) {
        return constants.SUPPORTED_STAGES.includes(stage.name);
      }

      return true;
    });
  }

  const adaptAllTechnologiesFromApi = (technologiesApi: TechnologiesApi): Technologies => {
    const technologies: Technologies = {};

    filterOutUnsupportedStages(technologiesApi.items).forEach(technology => {
      const { node_size, name, section, stage, tech_bet, has_active_projects, ...rest } =
        technology;
      technologies[technology.id] = {
        ...rest,
        label: name,
        techBet: tech_bet,
        stage: stage?.name ?? STAGES_ENUM.NO_STAGE,
        section: section?.name ?? constants.QUADRANT.AREAS_METHODS,
        hasActiveProjects: has_active_projects,
        numberOfChildren: node_size.children,
        numberOfProjects: node_size.projects,
        numberOfResearches: node_size.blog_posts,
        numberOfOpps: node_size.opps,
        numberOfLeads: node_size.leads,
        numberOfTalents: node_size.talents,
        isRadarTechnology: !!stage?.name && !!section?.name,
      };
    });

    return technologies;
  };

  const adaptSingleTechnologyFromApi = (
    technologyDetailsApi: TechnologyDetailsApi
  ): TechnologyDetails => {
    return {
      ...technologyDetailsApi,
      stage: technologyDetailsApi.stage?.name || null,
      section: technologyDetailsApi.section?.name || null,
      related_technologies: filterOutUnsupportedStages(technologyDetailsApi.related_technologies),
    };
  };

  return {
    adaptAllTechnologiesFromApi,
    adaptSingleTechnologyFromApi,
  };
};

export default TechnologiesDataService;
