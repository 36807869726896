import { SizeNodesBy } from 'contexts/Interactions/Interactions.type';
import { InitializedNodeObject } from 'types/graph.types';
import { STAGES_ENUM } from 'types/technology.types';

const BASE_NODE_SIZE = 50; // Average heigh in px for "big" node (sizeFactor = 1), all node shapes are sized based on this value
const BASE_BORDER_SIZE = 16; // Used to add border to nodes
const SOFT_POINTER_AREA_SPACE = 5;
const BASE_POINTER_AREA_SIZE = BASE_NODE_SIZE + BASE_BORDER_SIZE + SOFT_POINTER_AREA_SPACE; // Due to issue with pointer-area while using borders on them, we're forced to draw shapes bigger to compensate border width

function getScale(sizeFactor: number) {
  // BE provides size of node as one of 3 numbers:
  // 1 - regular (big) node, 2 - medium node, 3 - small node
  // This function transpose these values to correct values which then can be used to calculate final dimension as follows:
  // finalDimension = baseDimension * getScale(sizeFactor)
  if (sizeFactor === 3) return 0.25;
  if (sizeFactor === 2) return 0.5;

  return 1;
}

interface DrawShapeParams {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  scale: number;
  isPointerArea?: boolean;
}

function drawCircle({ ctx, x, y, scale, isPointerArea }: DrawShapeParams) {
  const baseRadius = (isPointerArea ? BASE_POINTER_AREA_SIZE : BASE_NODE_SIZE) / 2;
  const radius = baseRadius * scale;

  ctx.beginPath();
  ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
}

function drawDiamond({ ctx, x, y, scale, isPointerArea }: DrawShapeParams) {
  const DEFAULT_OFFSET = (isPointerArea ? BASE_POINTER_AREA_SIZE : BASE_NODE_SIZE) / 2;
  const offset = DEFAULT_OFFSET * scale;

  ctx.beginPath();
  ctx.moveTo(x, y - offset);
  ctx.lineTo(x - offset, y);
  ctx.lineTo(x, y + offset);
  ctx.lineTo(x + offset, y);
  ctx.closePath();
}

function drawHexagon({ ctx, x, y, scale, isPointerArea }: DrawShapeParams) {
  const a = (2 * Math.PI) / 6;
  const r = (isPointerArea ? BASE_POINTER_AREA_SIZE : BASE_NODE_SIZE) / 2;

  ctx.beginPath();
  for (let i = 0; i < 6; i += 1) {
    ctx.lineTo(x + r * Math.cos(a * i) * scale, y + r * Math.sin(a * i) * scale);
  }
  ctx.closePath();
}

function drawTriangle({ ctx, x, y, scale, isPointerArea }: DrawShapeParams) {
  const oneThirdOfHeight = (isPointerArea ? BASE_POINTER_AREA_SIZE : BASE_NODE_SIZE) * 0.34;
  const TwoThirdOfHeight = (isPointerArea ? BASE_POINTER_AREA_SIZE : BASE_NODE_SIZE) * 0.67;

  ctx.beginPath();
  ctx.moveTo(x, y - TwoThirdOfHeight * scale);
  ctx.lineTo(x - TwoThirdOfHeight * scale, y + oneThirdOfHeight * scale);
  ctx.lineTo(x + TwoThirdOfHeight * scale, y + oneThirdOfHeight * scale);
  ctx.closePath();
}

function drawRectangle({ ctx, x, y, scale, isPointerArea }: DrawShapeParams) {
  const size = (isPointerArea ? BASE_POINTER_AREA_SIZE : BASE_NODE_SIZE) * scale;
  const offset = size / 2;

  ctx.beginPath();
  ctx.rect(x - offset, y - offset, size, size);
}

const sizeByToNodeSizeFactorMap = {
  [SizeNodesBy.LEADS]: 'numberOfLeadsSizeFactor',
  [SizeNodesBy.OPPS]: 'numberOfOppsSizeFactor',
  [SizeNodesBy.PROJECTS]: 'numberOfProjectsSizeFactor',
  [SizeNodesBy.TALENTS]: 'numberOfTalentsSizeFactor',
};

function drawNodeShape(
  node: InitializedNodeObject,
  ctx: CanvasRenderingContext2D,
  sizeBy: SizeNodesBy,
  isPointerArea?: boolean
): { maxWidth: number; scale: number } {
  const BASE_MAX_TEXT_WIDTH = 232;

  const sizeFactorKey = sizeByToNodeSizeFactorMap[sizeBy];
  const scale = getScale(node[sizeFactorKey]);
  const maxWidth = BASE_MAX_TEXT_WIDTH * scale;
  const params = { ctx, x: node.x, y: node.y, scale, isPointerArea };

  if (node.stage === STAGES_ENUM.IN_USE) {
    drawCircle(params);
  } else if (node.stage === STAGES_ENUM.EXPERIMENT) {
    drawDiamond(params);
  } else if (node.stage === STAGES_ENUM.BET) {
    drawHexagon(params);
  } else if (node.stage === STAGES_ENUM.WATCH) {
    drawTriangle(params);
  } else if (node.stage === STAGES_ENUM.NO_STAGE) {
    drawRectangle(params);
  }

  return { maxWidth, scale };
}

export { drawNodeShape, BASE_NODE_SIZE, BASE_BORDER_SIZE };
