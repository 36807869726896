import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import ForceGraph2D, { ForceGraphMethods, LinkObject, NodeObject } from 'react-force-graph-2d';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'contexts/Auth/Auth.context';
import { useInteractions, useSetInteractions } from 'contexts/Interactions/Interactions.context';
import {
  useFilteredTechnologies,
  useFilterTechnologies,
} from 'contexts/Technologies/Technologies.context';
import makeGraphData from 'contexts/Technologies/utils/makeGraphData';
import cnst from 'shared/constants';
import APP_ROUTES from 'shared/routes';
import {
  makeLinkColor,
  makeNodeCanvasObject,
  makeOnNodeDragEnd,
  makeNodePointerAreaPaint,
} from 'shared/utils';
import { InitializedNodeObject, NodeHighlightOptions, ZoomValues } from 'types/graph.types';
import useSearchedTechnologies from 'views/SearchResults/hooks/useSearchedTechnologies';

import useNetworkForceLayout from './hooks/useNetworkForceLayout';
import styles from './NetworkGraph.module.scss';
import './overrides.scss';

interface NetworkGraphProps {
  forceGraphRef: React.MutableRefObject<ForceGraphMethods | undefined>;
  onZoomChange: (values: ZoomValues) => void;
}

const NetworkGraph: FC<NetworkGraphProps> = ({ forceGraphRef, onZoomChange }) => {
  const [shouldZoom, setShouldZoom] = useState(true);
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const filteredTechnologies = useFilteredTechnologies();
  const [throttledGraphData, setThrottledGraphData] = useState(() =>
    makeGraphData(filteredTechnologies)
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setThrottledGraphData(makeGraphData(filteredTechnologies));
    }, 100); // timeout needed for UI components to update smoothly before data visualisation rerenders

    return () => clearTimeout(timer);
  }, [filteredTechnologies]);

  const { setHighlightedTechnologyId } = useSetInteractions();
  const { sizeNodesBy, hoveredSearchResultNodeId, highlightedTechnologyId } = useInteractions();
  const { searchedTechnologies } = useSearchedTechnologies();
  const { searchPhrase } = useFilterTechnologies();

  useNetworkForceLayout(forceGraphRef);

  const handleNodeClick = useCallback(
    (node: NodeObject) => {
      const initializedNode = node as InitializedNodeObject;

      navigate(`${APP_ROUTES.GRAPH}/${initializedNode.id}`);
    },
    [navigate]
  );

  const handleLinkColor = useCallback(
    (linkObject: LinkObject) =>
      makeLinkColor(linkObject, searchPhrase, hoveredSearchResultNodeId, highlightedTechnologyId),
    [hoveredSearchResultNodeId, highlightedTechnologyId, searchPhrase]
  );

  const handleEngineStop = useCallback(() => {
    if (shouldZoom) {
      forceGraphRef.current?.zoomToFit(
        cnst.GRAPH_SETTINGS.ZOOM_DURATION,
        cnst.GRAPH_SETTINGS.ZOOM_PADDING
      );
      setShouldZoom(false);
    }
  }, [forceGraphRef, shouldZoom]);

  const handleOnZoomEnd = useCallback(
    (transform: ZoomValues) => onZoomChange(transform),
    [onZoomChange]
  );

  const handleHoverOverNode = useCallback(
    (node: NodeObject | null) => {
      const initializedNode = node as InitializedNodeObject | null;
      const technologyId = initializedNode?.id || null;
      setHighlightedTechnologyId(technologyId);
    },
    [setHighlightedTechnologyId]
  );

  const searchResultHoverHighlightGroup = useMemo((): Set<string> => {
    if (
      !hoveredSearchResultNodeId ||
      !filteredTechnologies ||
      !filteredTechnologies[hoveredSearchResultNodeId]
    ) {
      return new Set();
    }

    const highlightIds = new Set([
      ...filteredTechnologies[hoveredSearchResultNodeId].children,
      hoveredSearchResultNodeId,
    ]);

    return highlightIds;
  }, [hoveredSearchResultNodeId, filteredTechnologies]);

  const searchPhraseHighlightGroup = useMemo(() => {
    return new Set(Object.keys(searchedTechnologies));
  }, [searchedTechnologies]);

  const handleNodeCanvasObject = useCallback(
    (node: NodeObject, ctx: CanvasRenderingContext2D, globalScale: number) => {
      const initializedNode = node as InitializedNodeObject;

      const createHighlightOptions = (): NodeHighlightOptions | null => {
        // hovering node on graph takes priority...
        if (highlightedTechnologyId) {
          return {
            nodesIds: new Set([
              highlightedTechnologyId,
              ...filteredTechnologies[highlightedTechnologyId].children,
            ]),
            highlightColor: 'accent',
          };
        }

        // hovering over skill on `all skills` list or on `search results` list
        if (searchResultHoverHighlightGroup.size > 0) {
          return {
            nodesIds: searchResultHoverHighlightGroup,
            highlightColor: 'standard',
          };
        }

        // when search input is being used
        if (searchPhrase) {
          return {
            nodesIds: searchPhraseHighlightGroup,
            highlightColor: 'accent',
          };
        }

        return null;
      };

      return makeNodeCanvasObject(
        initializedNode,
        ctx,
        globalScale,
        sizeNodesBy,
        createHighlightOptions()
      );
    },
    [
      sizeNodesBy,
      searchResultHoverHighlightGroup,
      searchPhraseHighlightGroup,
      searchPhrase,
      filteredTechnologies,
      highlightedTechnologyId,
    ]
  );

  const handleNodePointerAreaPaint = useCallback(
    (node: NodeObject, paintColor: string, ctx: CanvasRenderingContext2D, globalScale: number) => {
      const initializedNode = node as InitializedNodeObject;
      return makeNodePointerAreaPaint(initializedNode, paintColor, ctx, sizeNodesBy);
    },
    [sizeNodesBy]
  );

  const handleNodeLabel = useCallback(
    (node: NodeObject) => {
      const initializedNode = node as InitializedNodeObject;

      const details = isLoggedIn
        ? `
            <dl class="${styles['tooltip__data-list']}">
              <div class="${styles['tooltip__data-list__item']}">
                <dt class="${styles['tooltip__data-list__item__label']}">All projects:</dt>
                <dd class="${styles['tooltip__data-list__item__value']}">${
            initializedNode.numberOfProjects || 0
          }</dd>
              </div>
              <div class="${styles['tooltip__data-list__item']}">
                <dt class="${styles['tooltip__data-list__item__label']}">Last 30d Opps:</dt>
                <dd class="${styles['tooltip__data-list__item__value']}">${
            initializedNode.numberOfOpps || 0
          }</dd>
              </div>
              <div class="${styles['tooltip__data-list__item']}">
                <dt class="${styles['tooltip__data-list__item__label']}">Talents:</dt>
                <dd class="${styles['tooltip__data-list__item__value']}">${
            initializedNode.numberOfTalents || 0
          }</dd>
              </div>
            </dl>
          `
        : '';

      return `
        <div class="${styles.tooltip}">
          <div class="${styles.tooltip__header}">${initializedNode.label}</div>
          ${details}
        </div>
      `;
    },
    [isLoggedIn]
  );

  return (
    <ForceGraph2D
      nodeLabel={handleNodeLabel}
      d3VelocityDecay={0.7}
      ref={forceGraphRef}
      graphData={throttledGraphData}
      linkColor={handleLinkColor}
      linkWidth={0.2}
      nodeCanvasObject={handleNodeCanvasObject}
      nodePointerAreaPaint={handleNodePointerAreaPaint}
      onNodeDragEnd={makeOnNodeDragEnd}
      onNodeClick={handleNodeClick}
      maxZoom={cnst.GRAPH_SETTINGS.MAX_ZOOM}
      minZoom={cnst.GRAPH_SETTINGS.MIN_ZOOM}
      warmupTicks={100}
      cooldownTicks={100}
      cooldownTime={500}
      onEngineStop={handleEngineStop}
      onZoomEnd={handleOnZoomEnd}
      onNodeHover={handleHoverOverNode}
    />
  );
};

export default memo(NetworkGraph);
