import { inRange } from 'lodash';

export default function getNodeSizeFactor(basisValue?: number) {
  if (!basisValue) return 3;
  const range = [6, 13];

  if (inRange(basisValue, 0, range[0])) return 3;
  if (inRange(basisValue, range[0], range[1])) return 2;
  return 1;
}
