import { easeCircle } from 'd3-ease';
import { select } from 'd3-selection';
import { arc } from 'd3-shape';

import { interpolRotate, animation } from 'core/radarUtils';

export default () => {
  const container = select('svg .restBlips');
  const arcGenerator = arc();

  const path = arcGenerator({
    startAngle: Math.PI / 2,
    endAngle: 2 * Math.PI,
    innerRadius: 0,
    outerRadius: 400,
  });

  container
    .append('path')
    .attr('d', path)
    .attr('fill', 'url(#sweepGradient)')
    .attr('opacity', 0)
    .transition(animation(1500, easeCircle))
    .delay(1900)
    .attr('opacity', 1)
    .attrTween('transform', () => interpolRotate)
    // @ts-ignore FIXME
    .transition(animation(1000))
    .attr('opacity', 0)
    .remove();

  container
    .append('line')
    .attr('x1', '0')
    .attr('y1', '0')
    .attr('x2', '0')
    .attr('y2', '-400')
    .attr('stroke', '#233446')
    .attr('stroke-width', '1')
    .attr('opacity', 0)
    .transition(animation(1500, easeCircle))
    .delay(1900)
    .on('end', () => select('svg .container').classed('enterAnimationComplete', true))
    .attr('opacity', 1)
    .attrTween('transform', () => interpolRotate)
    .remove();
};
