import { ReactNode } from 'react';

import cn from 'classnames';

import styles from './SidePanelSection.module.scss';

interface SidePanelSectionProps {
  children: ReactNode;
  hasBottomBorder?: boolean;
}

const SidePanelSection = ({ children, hasBottomBorder = false }: SidePanelSectionProps) => {
  return (
    <section className={cn(styles.section, { [styles.withBottomBorder]: hasBottomBorder })}>
      {children}
    </section>
  );
};

export default SidePanelSection;
