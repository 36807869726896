import { Callbacks } from 'core/types';
import { getSectionForQuadrant } from 'shared/helpers/radar';

import focusQuarter from './focusQuarter';
import hideAura from './hideAura';

export default (g: number, callbacks?: Callbacks) => {
  focusQuarter(g + 1, true);
  callbacks?.onQuarterHighlighted(getSectionForQuadrant(g));
  callbacks?.onNodeSelected(null);
  hideAura();
};
