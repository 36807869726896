export enum CaseStudyDomain {
  Behance = 'behance',
  Netguru = 'netguru',
  Dribbble = 'dribbble',
}

export enum BlogPostSource {
  Confluence = 'confluence',
  NetguruBlog = 'blog',
  External = 'external',
}

export enum ResourceType {
  CaseStudy = 'caseStudy',
  Article = 'article',
}

export interface Resource {
  title: string;
  summary: string;
  tags: string[];
  url: string;
}

export interface CaseStudyResource extends Resource {
  domain: CaseStudyDomain;
}

export interface BlogPostResource extends Resource {
  source: BlogPostSource;
}

export interface CaseStudiesResponse {
  items: CaseStudyResource[];
}

export interface BlogPostsResponse {
  items: BlogPostResource[];
}
