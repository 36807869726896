import Isvg from 'react-inlinesvg';

import closeIcn from 'assets/svg/close.svg';
import { ChildrenProp } from 'types';

import styles from './Chip.module.scss';

type ChipProps = ChildrenProp & {
  /**
   * If passed, Chip will be rendered with a close button.
   */
  onClose?: () => void;
};

const Chip = ({ children, onClose }: ChipProps) => {
  return (
    <span className={styles.chip}>
      <span className={styles.chip__label}>{children}</span>

      {onClose ? (
        <button
          type="button"
          aria-label="Close"
          onClick={onClose}
          className={styles['chip__close-button']}
        >
          <Isvg src={closeIcn} className={styles['chip__close-button__icon']} aria-hidden />
        </button>
      ) : null}
    </span>
  );
};

export default Chip;
