import { useMemo, useState } from 'react';

import { Box, HStack, VStack } from '@chakra-ui/react';
import { map, Truthy } from 'lodash';

import Accordion from 'components/Accordion/Accordion';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import SearchInput from 'components/SearchInput/SearchInput';
import SidePanelSectionHeader from 'components/SidePanel/SidePanelSectionHeader/SidePanelSectionHeader';
import { useTechnology, useTechnologies } from 'contexts/Technologies/Technologies.context';
import List from 'views/List/List';

import { SearchResultsEmptyLabel, SearchResultsLabel } from '../TechnologyDetailsSearch';

function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

const TechnologyDetailsRelatedTechnologies = () => {
  const technologies = useTechnologies();
  const [searchPhrase, setSearchPhrase] = useState('');

  const technology = useTechnology();
  const relatedTechnologies = useMemo(
    () => map(technology?.related_technologies, t => technologies?.[t.id]).filter(truthy),
    [technologies, technology]
  );

  const filteredRelatedTechnologies = useMemo(() => {
    if (!relatedTechnologies) {
      return [];
    }

    const searchPhraseLowerCase = searchPhrase.toLocaleLowerCase();

    return relatedTechnologies.filter(({ label }) =>
      label.toLocaleLowerCase().includes(searchPhraseLowerCase)
    );
  }, [relatedTechnologies, searchPhrase]);

  if (!technology) return null;

  const parentTechnologies = relatedTechnologies.filter(t => t.children?.includes(technology!.id));
  const childrenTechnologies = relatedTechnologies.filter(t => t.parents?.includes(technology!.id));

  const parentLabel = <div>Parent ({parentTechnologies.length})</div>;
  const childLabel = <div>Child ({childrenTechnologies.length})</div>;

  return (
    <>
      <SidePanelSectionHeader>
        <HStack spacing="8px">
          <span>Related to</span>
          <InfoTooltip tooltipText="Shows connections with other skills. You can track in what ecosystem specific skill is appearing and what are the relations to other skills." />
        </HStack>
      </SidePanelSectionHeader>

      <Box mb="32px">
        <SearchInput placeholder="Search" value={searchPhrase} onChange={setSearchPhrase} />
      </Box>

      {searchPhrase ? (
        <>
          <SearchResultsLabel itemCount={filteredRelatedTechnologies.length} />

          {filteredRelatedTechnologies.length ? (
            <List items={filteredRelatedTechnologies} />
          ) : (
            <SearchResultsEmptyLabel>No skills match your search.</SearchResultsEmptyLabel>
          )}
        </>
      ) : (
        <VStack spacing="16px" align="stretch">
          {parentTechnologies.length ? (
            <Accordion label={parentLabel} level={2} defaultExpanded>
              <List items={parentTechnologies} />
            </Accordion>
          ) : (
            parentLabel
          )}

          {childrenTechnologies.length ? (
            <Accordion label={childLabel} level={2}>
              <List items={childrenTechnologies} />
            </Accordion>
          ) : (
            childLabel
          )}
        </VStack>
      )}
    </>
  );
};

export default TechnologyDetailsRelatedTechnologies;
