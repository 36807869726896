import { FC, useCallback, useEffect, useRef, useState } from 'react';

import ForceGraph2D, { ForceGraphMethods, NodeObject } from 'react-force-graph-2d';
import { useNavigate } from 'react-router-dom';

import { useInteractions } from 'contexts/Interactions/Interactions.context';
import useNetworkForceLayout from 'screens/NetworkScreen/NetworkContent/hooks/useNetworkForceLayout';
import APP_ROUTES from 'shared/routes';
import {
  makeLinkColor,
  makeNodeCanvasObject,
  makeNodePointerAreaPaint,
  makeOnNodeDragEnd,
} from 'shared/utils';
import { InitializedNodeObject } from 'types/graph.types';

import useNetworkDetailsGraphData from '../hooks/useNetworkDetailsGraphData';

interface TechnologiesProps {
  label?: string;
}

const Technologies: FC<TechnologiesProps> = () => {
  const [shouldZoom, setShouldZoom] = useState(true);
  const navigate = useNavigate();
  const forceGraphRef = useRef<ForceGraphMethods>();
  const subgraphData = useNetworkDetailsGraphData();
  const { sizeNodesBy } = useInteractions();

  useNetworkForceLayout(forceGraphRef);

  const handleNodeClick = (node: NodeObject) => {
    const initializedNode = node as InitializedNodeObject;

    navigate(`${APP_ROUTES.GRAPH}/${initializedNode.id}`);
  };

  const handleEngineStop = () => {
    if (shouldZoom) {
      forceGraphRef.current?.zoomToFit(300, 150);
      setShouldZoom(false);
    }
  };

  const handleNodePointerAreaPaint = useCallback(
    (node: NodeObject, paintColor: string, ctx: CanvasRenderingContext2D, globalScale: number) => {
      const initializedNode = node as InitializedNodeObject;
      return makeNodePointerAreaPaint(initializedNode, paintColor, ctx, sizeNodesBy);
    },
    [sizeNodesBy]
  );

  useEffect(() => {
    setShouldZoom(true);
  }, [subgraphData]);

  return (
    <ForceGraph2D
      d3VelocityDecay={0.7}
      ref={forceGraphRef}
      graphData={subgraphData}
      linkColor={makeLinkColor}
      linkWidth={0.5}
      nodeCanvasObject={(node, ctx, globalScale) => {
        const initializedNode = node as InitializedNodeObject;
        makeNodeCanvasObject(initializedNode, ctx, globalScale, sizeNodesBy, null);
      }}
      nodePointerAreaPaint={handleNodePointerAreaPaint}
      onNodeDragEnd={makeOnNodeDragEnd}
      onNodeClick={handleNodeClick}
      maxZoom={3}
      minZoom={0.2}
      warmupTicks={100}
      cooldownTicks={100}
      cooldownTime={500}
      onEngineStop={handleEngineStop}
    />
  );
};

export default Technologies;
