import { VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import TechnologyDetailsDescription from 'views/TechnologyDetails/TechnologyDetailsDescription/TechnologyDetailsDescription';
import TechnologyDetailsLeads from 'views/TechnologyDetails/TechnologyDetailsLeads/TechnologyDetailsLeads';
import TechnologyDetailsOpps from 'views/TechnologyDetails/TechnologyDetailsOpps/TechnologyDetailsOpps';
import TechnologyDetailsProjects from 'views/TechnologyDetails/TechnologyDetailsProjects/TechnologyDetailsProjects';
import TechnologyDetailsStatistics from 'views/TechnologyDetails/TechnologyDetailsStatistics/TechnologyDetailsStatistics';

const TechnologyDetailsOverview = () => {
  const { technologyId } = useParams();

  if (!technologyId) {
    throw new Error('technologyId query param is missing');
  }

  return (
    <VStack spacing="32px" align="stretch">
      <TechnologyDetailsDescription />
      <TechnologyDetailsStatistics technologyId={technologyId} />
      <TechnologyDetailsProjects />
      <TechnologyDetailsOpps />
      <TechnologyDetailsLeads />
    </VStack>
  );
};

export default TechnologyDetailsOverview;
