import { useAuth } from 'contexts/Auth/Auth.context';

import useLoadTechnology from './hooks/useLoadTechnology';
import TechnologyDetailsAuthed from './TechnologyDetailsAuthed/TechnologyDetailsAuthed';
import TechnologyDetailsHeader from './TechnologyDetailsHeader/TechnologyDetailsHeader';
import TechnologyDetailsUnauthed from './TechnologyDetailsUnathed/TechnologyDetailsUnauthed';

interface TechnologyDetailsProps {
  technologyId: string;
}

const TechnologyDetails = ({ technologyId }: TechnologyDetailsProps) => {
  const { isLoggedIn } = useAuth();
  const {
    technology,
    notFound: technologyNotFound,
    technologyLoading,
  } = useLoadTechnology({ technologyId });

  const technologyName = technology?.name || '\u00A0'; // non breaking space to preserve space

  return (
    <>
      <TechnologyDetailsHeader technology={technology} isLoading={technologyLoading} />

      {isLoggedIn && !technologyNotFound ? (
        /* TAB SWITCH + OUTLET (BASED ON ROUTE) */
        <TechnologyDetailsAuthed isLoading={technologyLoading} technologyId={technologyId} />
      ) : (
        /* "ASK FREELY" CARD + "RELATED TO" LIST (FALLBACK) */
        <TechnologyDetailsUnauthed isLoading={technologyLoading} technologyName={technologyName} />
      )}
    </>
  );
};

export default TechnologyDetails;
