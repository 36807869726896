import SidePanelSectionHeader from 'components/SidePanel/SidePanelSectionHeader/SidePanelSectionHeader';
import { useTechnology } from 'contexts/Technologies/Technologies.context';
import useToggle from 'shared/hooks/useToggle';

import {
  DESCRIPTION_LENGTH_THRESHOLD,
  TRUNCATED_DESCRIPTION_MAX_LENGTH,
} from './TechnologyDetailsDescription.constants';
import styles from './TechnologyDetailsDescription.module.scss';

const TechnologyDetailsDescription = () => {
  const [isDescriptionVisible, toggleDescription] = useToggle();

  const technology = useTechnology();
  const fullDescription = technology?.description || 'None';

  const needToTruncateDescription = fullDescription.length > DESCRIPTION_LENGTH_THRESHOLD;

  const truncatedDescription = needToTruncateDescription
    ? `${fullDescription.substring(0, TRUNCATED_DESCRIPTION_MAX_LENGTH)}...`
    : fullDescription;

  return (
    <div className={styles.wrapper}>
      <SidePanelSectionHeader>About</SidePanelSectionHeader>

      <div className={styles.description}>
        {isDescriptionVisible ? fullDescription : truncatedDescription}
      </div>

      {needToTruncateDescription ? (
        <button type="button" className={styles.show_more_button} onClick={toggleDescription}>
          {isDescriptionVisible ? 'Less' : 'Read more'}
        </button>
      ) : null}
    </div>
  );
};

export default TechnologyDetailsDescription;
