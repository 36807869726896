import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';

import { ToastSeverity, useToast } from 'components/Toast/useToast';
import TechnologiesDataService from 'contexts/Technologies/services/TechnologiesData.service';
import { useSetTechnology, useTechnology } from 'contexts/Technologies/Technologies.context';
import useApiCalls from 'shared/hooks/useApiCalls';

const service = TechnologiesDataService();

interface UseLoadTechnologyParams {
  technologyId: string;
}

const useLoadTechnology = ({ technologyId }: UseLoadTechnologyParams) => {
  const [technologyLoading, setTechnologyLoading] = useState(true);
  const { getTechnology } = useApiCalls();
  const { setTechnology } = useSetTechnology();
  const toast = useToast();

  useEffect(() => {
    const getTechnologyDetails = async () => {
      setTechnologyLoading(true);
      try {
        const { data: tech } = await getTechnology(technologyId);
        setTechnology(service.adaptSingleTechnologyFromApi(tech));
      } catch {
        toast({
          message:
            "We weren't able to download selected technology details. Please try again later.",
          severity: ToastSeverity.Error,
        });
      } finally {
        setTechnologyLoading(false);
      }
    };

    getTechnologyDetails();
  }, [technologyId, getTechnology]);

  const technology = useTechnology();

  return {
    notFound: !technologyLoading && isEmpty(technology),
    technology,
    technologyLoading,
  };
};

export default useLoadTechnology;
