import NavigationTab from 'components/NavigationTab/NavigationTab';
import NavigationTabGroup from 'components/NavigationTabGroup/NavigationTabGroup';
import APP_ROUTES from 'shared/routes';

import styles from './ScreenSwitcher.module.scss';

const ScreenSwitcher = () => {
  return (
    <div className={styles.screenSwitcher}>
      <NavigationTabGroup>
        <NavigationTab to={APP_ROUTES.RADAR}>Radar View</NavigationTab>
        <NavigationTab to={APP_ROUTES.GRAPH}>Graph View</NavigationTab>
      </NavigationTabGroup>
    </div>
  );
};

export default ScreenSwitcher;
