import { isUndefined } from 'lodash';

import { filterObject } from 'shared/utils';
import { STAGES_ENUM, Technologies, Technology } from 'types/technology.types';

export enum FilterOptionsEnum {
  yes = 'yes',
  no = 'no',
  all = 'all',
}

export interface FiltersState {
  activeProjects: FilterOptionsEnum;
  researches: FilterOptionsEnum;
  techBet: FilterOptionsEnum;
  stageInUse: boolean;
  stageBet: boolean;
  stageExperiment: boolean;
  stageWatch: boolean;
  stageNoStage: boolean;
}

type FilterCallback = (t: Technology) => boolean;

const FiltersService = () => {
  const getActiveStages = (appliedFilters: FiltersState) => {
    const activeStages: STAGES_ENUM[] = [];

    if (appliedFilters.stageInUse) activeStages.push(STAGES_ENUM.IN_USE);
    if (appliedFilters.stageBet) activeStages.push(STAGES_ENUM.BET);
    if (appliedFilters.stageExperiment) activeStages.push(STAGES_ENUM.EXPERIMENT);
    if (appliedFilters.stageWatch) activeStages.push(STAGES_ENUM.WATCH);
    if (appliedFilters.stageNoStage) activeStages.push(STAGES_ENUM.NO_STAGE);

    return activeStages;
  };

  const makeFiltersConfig = (appliedFilters: FiltersState) => {
    // FILTER CALLBACKS
    const showWithActiveProjects = (t: Technology) => {
      return isUndefined(t.hasActiveProjects) ? false : t.hasActiveProjects;
    };
    const hideWithActiveProjects = (t: Technology) =>
      isUndefined(t.numberOfProjects) ? true : t.numberOfProjects === 0;

    const showWithResearches = (t: Technology) =>
      isUndefined(t.numberOfResearches) ? false : t.numberOfResearches > 0;
    const hideWithResearches = (t: Technology) =>
      isUndefined(t.numberOfResearches) ? true : t.numberOfResearches === 0;

    const showWithTechBet = (t: Technology) => (isUndefined(t.techBet) ? false : t.techBet);
    const hideWithTechBet = (t: Technology) => (isUndefined(t.techBet) ? true : !t.techBet);

    const activeStages = getActiveStages(appliedFilters);

    const showStage = (t: Technology) => activeStages.includes(t.stage);

    // FILTER CONFIG
    const filtersConfig: [condition: boolean, callback: FilterCallback][] = [
      [appliedFilters.activeProjects === FilterOptionsEnum.yes, showWithActiveProjects],
      [appliedFilters.activeProjects === FilterOptionsEnum.no, hideWithActiveProjects],

      [appliedFilters.researches === FilterOptionsEnum.yes, showWithResearches],
      [appliedFilters.researches === FilterOptionsEnum.no, hideWithResearches],

      [appliedFilters.techBet === FilterOptionsEnum.yes, showWithTechBet],
      [appliedFilters.techBet === FilterOptionsEnum.no, hideWithTechBet],

      [true, showStage],
    ];

    return filtersConfig;
  };

  const filterTechnologies = (
    technologies: Technologies | null,
    appliedFilters: FiltersState
  ): Technologies => {
    if (!technologies) return {};

    const functionsToApply: FilterCallback[] = [];

    const filtersConfig = makeFiltersConfig(appliedFilters);

    filtersConfig.forEach(([condition, callback]) => {
      if (condition) functionsToApply.push(callback);
    });

    return filterObject(technologies, t => {
      // eslint-disable-next-line
      for (const callback of functionsToApply) {
        if (!callback(t)) return false;
      }
      return true;
    });
  };

  return {
    filterTechnologies,
  };
};

export default FiltersService;
