import { useMemo, useState } from 'react';

import { pickBy } from 'lodash';

import { Technologies, TechnologiesApi } from 'types/technology.types';

import FiltersService, { FiltersState } from '../services/Filters.service';
import TechnologiesDataService from '../services/TechnologiesData.service';

const dataService = TechnologiesDataService();
const filtersService = FiltersService();

interface UseTechnologiesDataServiceParams {
  filters: FiltersState;
}

const useTechnologiesDataService = ({ filters }: UseTechnologiesDataServiceParams) => {
  const [technologies, setTechnologies] = useState<Technologies | null>(null);
  const [radarTechnologies, setRadarTechnologies] = useState<Technologies | null>(null);

  const filteredTechnologies = useMemo<Technologies>(() => {
    return filtersService.filterTechnologies(technologies, filters);
  }, [filters, technologies]);

  const initTechnologiesToState = (technologiesApi: TechnologiesApi) => {
    const adaptedTechnologies = dataService.adaptAllTechnologiesFromApi(technologiesApi);

    const adaptedTechnologiesForRadar = pickBy(adaptedTechnologies, tech => tech.isRadarTechnology);

    setTechnologies(adaptedTechnologies);
    setRadarTechnologies(adaptedTechnologiesForRadar);
  };

  return {
    technologies,
    filteredTechnologies,
    radarTechnologies,
    initTechnologiesToState,
  };
};

export default useTechnologiesDataService;
