import { ReactNode, Ref } from 'react';

import cn from 'classnames';

import styles from './Input.module.scss';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  leftAddition?: ReactNode;
  rightAddition?: ReactNode;
  inputRef?: Ref<HTMLInputElement> | undefined;
}

const Input = ({ leftAddition, rightAddition, inputRef, className, ...props }: InputProps) => (
  <div className={styles.input}>
    {leftAddition ? (
      <div className={cn(styles.input__addition, styles['input__addition--left'])}>
        {leftAddition}
      </div>
    ) : null}

    <input
      type="text"
      className={cn(
        styles.input__native,
        {
          [styles['input__native--left-padding']]: leftAddition,
          [styles['input__native--right-padding']]: rightAddition,
        },
        className
      )}
      ref={inputRef}
      {...props}
    />

    {rightAddition ? (
      <div className={cn(styles.input__addition, styles['input__addition--right'])}>
        {rightAddition}
      </div>
    ) : null}
  </div>
);

export default Input;
