import { LinkObject } from 'react-force-graph-2d';

import { NodeDatum } from 'types/graph.types';

import getCssColorPropertyValue from './getCssPropertyValue';

export default function makeLinkColor(
  { source }: LinkObject,
  searchUsed?: string,
  hoveredSearchResultNodeId?: string | null,
  hoveredNodeId?: string | number | null
): string {
  const { id, color } = source as NodeDatum;

  const hoverOnSearchResultButNotInGroup =
    hoveredSearchResultNodeId && hoveredSearchResultNodeId !== id;
  const hoverOnSearchResultAndInGroup = hoveredSearchResultNodeId === id;

  const hoverOnCanvasNodeButNotInGroup = hoveredNodeId && hoveredNodeId !== id;
  const hoverOnCanvasNodeAndNodeInGroup = hoveredNodeId && hoveredNodeId === id;

  if (searchUsed && !hoveredSearchResultNodeId && !hoveredNodeId)
    return getCssColorPropertyValue('--neutral-nodeLink-inactive');

  if (hoverOnSearchResultButNotInGroup)
    return getCssColorPropertyValue('--neutral-nodeLink-inactive');

  if (hoverOnSearchResultAndInGroup) return color;

  if (hoverOnCanvasNodeButNotInGroup)
    return getCssColorPropertyValue('--neutral-nodeLink-inactive');
  if (hoverOnCanvasNodeAndNodeInGroup) return getCssColorPropertyValue('--accent-cta');

  // Default link coloring
  return getCssColorPropertyValue('--neutral-nodeLink');
}
