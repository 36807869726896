import { useState, useEffect } from 'react';

import { ToastSeverity, useToast } from 'components/Toast/useToast';
import { useInitTechnologiesToState } from 'contexts/Technologies/Technologies.context';
import useApiCalls from 'shared/hooks/useApiCalls';

const useLoadTechnologies = () => {
  const initTechnologiesToState = useInitTechnologiesToState();
  const { getAllTechnologies } = useApiCalls();
  const [listLoaded, setListLoaded] = useState(false);
  const toast = useToast();

  useEffect(() => {
    getAllTechnologies()
      .then(allTechnologiesResponse => {
        initTechnologiesToState(allTechnologiesResponse.data);
      })
      .catch(() =>
        toast({
          message: "We weren't able to download technologies. Please try again later.",
          severity: ToastSeverity.Error,
        })
      )
      .finally(() => setListLoaded(true));
  }, [getAllTechnologies]);

  return { listLoaded };
};

export default useLoadTechnologies;
