import { BASE_BORDER_SIZE } from './drawNodeShape';

function hashToRgb(hashColor: string) {
  const r = parseInt(hashColor.substring(1, 3), 16);
  const g = parseInt(hashColor.substring(3, 5), 16);
  const b = parseInt(hashColor.substring(5, 7), 16);

  return [r, g, b];
}

function shadeColor(color: string, percent: number) {
  let [r, g, b] = hashToRgb(color);

  r = Math.floor((r * (100 + percent)) / 100);
  g = Math.floor((g * (100 + percent)) / 100);
  b = Math.floor((b * (100 + percent)) / 100);

  r = r < 255 ? r : 255;
  g = g < 255 ? g : 255;
  b = b < 255 ? b : 255;

  const rr = r.toString(16).padStart(2, '0');
  const gg = g.toString(16).padStart(2, '0');
  const bb = b.toString(16).padStart(2, '0');

  return `#${rr}${gg}${bb}`;
}

function fillColor(ctx: CanvasRenderingContext2D, color: string) {
  ctx.fillStyle = color;
  ctx.fill();
}

function applyLineWidthAndColor(
  ctx: CanvasRenderingContext2D,
  width: number,
  color: string,
  globalScale: number
) {
  // render border only if it's wide enough to be seen
  if (globalScale * width < 3) {
    return;
  }
  ctx.lineWidth = width;
  ctx.lineJoin = 'round';
  ctx.strokeStyle = color;
  ctx.stroke();
}

const colorNode = (
  ctx: CanvasRenderingContext2D,
  scale: number,
  globalScale: number,
  color: string,
  shadeValue = 0
) => {
  const strokeWidth = BASE_BORDER_SIZE * scale;

  if (shadeValue) {
    fillColor(ctx, shadeColor(color, shadeValue));
    applyLineWidthAndColor(ctx, strokeWidth, shadeColor(color, shadeValue), globalScale);
  } else {
    fillColor(ctx, color);
    applyLineWidthAndColor(ctx, strokeWidth, color, globalScale);
  }
};

export default colorNode;
