import { FC } from 'react';

import Loader from 'components/Loader/Loader';
import type { ChildrenProp } from 'types';

import { useLoadTechnologies } from './hooks';
import styles from './TechnologiesScreensWrapper.module.scss';

const TechnologiesScreensWrapper: FC<ChildrenProp> = ({ children }) => {
  const { listLoaded } = useLoadTechnologies();

  return (
    <div className={styles.container}>
      {listLoaded ? (
        children
      ) : (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default TechnologiesScreensWrapper;
