import { useLocation } from 'react-router-dom';

import APP_ROUTES from 'shared/routes';

const useWhichScreenView = () => {
  const { pathname } = useLocation();

  return {
    isWelcome: pathname === APP_ROUTES.WELCOME,
    isRadar: pathname.includes(APP_ROUTES.RADAR),
    isGraph: pathname.includes(APP_ROUTES.GRAPH),
  };
};

export default useWhichScreenView;
