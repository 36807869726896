import { Box, VStack } from '@chakra-ui/react';
import { partition } from 'lodash';

import Accordion from 'components/Accordion/Accordion';
import ChipList from 'components/ChipList/ChipList';
import ExternalLinkChip from 'components/ExternalLinkChip/ExternalLinkChip';
import { useTechnology } from 'contexts/Technologies/Technologies.context';

const TechnologyDetailsProjects = () => {
  const technology = useTechnology();

  const [activeProjects, inactiveProjects] = partition(
    technology?.projects,
    project => project.active
  );

  const activeProjectLabel = <div>Active projects ({activeProjects.length})</div>;
  const inactiveProjectLabel = <div>Inactive projects ({inactiveProjects.length})</div>;

  return (
    <Accordion defaultExpanded label="Projects">
      <VStack spacing="16px" align="stretch">
        {activeProjects.length ? (
          <Accordion defaultExpanded level={2} label={activeProjectLabel} headerGap="24px">
            <Box pl="12px" pr="12px">
              <ChipList>
                {activeProjects.map(project => (
                  <ExternalLinkChip key={project.id} href={project.salesforce_url}>
                    {project.name}
                  </ExternalLinkChip>
                ))}
              </ChipList>
            </Box>
          </Accordion>
        ) : (
          activeProjectLabel
        )}

        {inactiveProjects.length ? (
          <Accordion level={2} label={inactiveProjectLabel} headerGap="24px">
            <Box pl="12px" pr="12px">
              <ChipList>
                {inactiveProjects.map(project => (
                  <ExternalLinkChip key={project.id} href={project.salesforce_url}>
                    {project.name}
                  </ExternalLinkChip>
                ))}
              </ChipList>
            </Box>
          </Accordion>
        ) : (
          inactiveProjectLabel
        )}
      </VStack>
    </Accordion>
  );
};

export default TechnologyDetailsProjects;
