import { RenderProps } from '@chakra-ui/react';
import Isvg from 'react-inlinesvg';

import circleAlertIcon from 'assets/svg/alert-circle.svg';
import circleCheckIcon from 'assets/svg/check-circle.svg';
import closeIcn from 'assets/svg/close.svg';
import infoIcon from 'assets/svg/info.svg';

import styles from './Toast.module.scss';
import { ToastSeverity, useToastProps } from './useToast';

interface ToastProps extends useToastProps, Pick<RenderProps, 'onClose'> {
  severity?: ToastSeverity;
}

const getSeverityIcon = (severity: ToastSeverity) => {
  if (severity === ToastSeverity.Error) return circleAlertIcon;
  if (severity === ToastSeverity.Success) return circleCheckIcon;

  return infoIcon;
};

const Toast = ({ severity, message, onClose }: ToastProps) => {
  return (
    <div className={styles.toast}>
      <div className={styles.leftSide}>
        {severity ? (
          <div className={styles.severityWrapper}>
            <Isvg src={getSeverityIcon(severity)} className={styles.severityIcon} aria-hidden />
          </div>
        ) : null}
        {message}
      </div>
      <button type="button" onClick={onClose} className={styles.closeButton}>
        <Isvg src={closeIcn} className={styles.closeIcon} aria-hidden />
      </button>
    </div>
  );
};

export { Toast, type ToastProps };
