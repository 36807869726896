import { select } from 'd3-selection';

import config from 'core/radarConfig';
import { rings } from 'core/radarParams';
import { animation, generateQuartersShapes } from 'core/radarUtils';
import { Callbacks } from 'core/types';

import highlightQuarter from './highlightQuarter';

const toggleQuarterDescription = (
  quadrant: { index: number; name: string; description: string },
  isExpanded: boolean
) => {
  const infoModal = document.getElementById('js-info-modal');
  const infoModalHeader = document.getElementById('js-info-modal-header');
  const infoModalBody = document.getElementById('js-info-modal-body');

  if (!(infoModal && infoModalHeader && infoModalBody)) {
    // eslint-disable-next-line no-console
    console.warn('Cannot find #js-info-modal');
    return;
  }

  if (isExpanded) {
    infoModal.classList.add('info-modal--expanded');
  } else {
    infoModal.classList.remove('info-modal--expanded');
  }

  infoModalHeader.innerText = quadrant.name;
  infoModalBody.innerText = quadrant.description;
};

export default (callbacks?: Callbacks) => {
  const legend = select('svg .legend');
  const arcy = generateQuartersShapes(
    rings[rings.length - 2].radius,
    rings[rings.length - 2].radius
  );

  legend
    .selectAll('path[class^=inner-circle_]')
    .data(arcy)
    .enter()
    .append('path')
    .attr('d', (d, i) => {
      // https://www.visualcinnamon.com/2015/09/placing-text-on-arcs.html
      let path = d;
      const firstArcSection = /(^.+?)Z/;

      let newArc = firstArcSection.exec(path)![1];
      newArc = newArc.replace(/,/g, ' ');

      if (i < 2) {
        const startLoc = /M(.*?)A/;
        const middleLoc = /A(.*?)0 0 1/;
        const endLoc = /0 0 1 (.*?)$/;
        const newStart = endLoc.exec(newArc)![1];
        const newEnd = startLoc.exec(newArc)![1];
        const middleSec = middleLoc.exec(newArc)![1];

        path = `M ${newStart} A${middleSec} 0 0 0 ${newEnd}`;
      }

      return path;
    })
    .style('fill', 'none')
    .attr('id', (d, i) => `inner-circle_${i}`);

  const data = config.quadrants.map((quadrant, index) => ({ ...quadrant, index }));

  legend
    .selectAll('.label')
    .data(data)
    .enter()
    .append('text')
    .attr('class', 'label')
    .attr('x', 0)
    .attr('dy', (d, i) => (i < 2 ? 30 : -20))
    .on('mouseover', (e, d) => toggleQuarterDescription(d, true))
    .on('mouseout', (e, d) => toggleQuarterDescription(d, false))
    .style('font-size', '14px')
    .append('textPath')
    .attr('class', 'quadrantTitle')
    .attr('fill', '#fff')
    .attr('xlink:href', (d, i) => `#inner-circle_${i}`)
    .attr('startOffset', (d, i) => (i < 2 ? '35%' : '20%'))
    .style('cursor', 'pointer')
    .text(d => `${d.name.toUpperCase()} ⓘ`)
    .on('click', (e, d) => highlightQuarter(d.index, callbacks))
    .style('opacity', 0)
    .transition(animation(1500))
    .delay(2000)
    .style('opacity', 1);
};
