import { randomUniform } from 'd3';
import { polygonContains } from 'd3-polygon';
import { has } from 'lodash';

import { restOfTechnologiesBounds } from 'core/radarParams';
import { segment } from 'core/radarUtils';
import { getRingForStage, getQuadrantForSection } from 'shared/helpers/radar';
import { TechnologiesRadar } from 'types/radar.types';
import { Technologies, Technology } from 'types/technology.types';

const hasTechnologyChanged = (
  technology: Technology,
  presentTechnologies: TechnologiesRadar | null
) =>
  presentTechnologies?.[technology.id].quadrant !== getQuadrantForSection(technology.section) ||
  presentTechnologies?.[technology.id].ring !== getRingForStage(technology.stage);

const technologyPresentAndUnchanged = (
  technology: Technology,
  presentTechnologies: TechnologiesRadar | null
) =>
  has(presentTechnologies, technology.id) && !hasTechnologyChanged(technology, presentTechnologies);

const randomPositionInside = entrySegment => {
  const point = entrySegment.random();
  return { x: point.x, y: point.y };
};

const randomPositionOutside = (quadrant: keyof typeof restOfTechnologiesBounds) => {
  const width = 150;
  const height = 350;
  const polygon = restOfTechnologiesBounds[quadrant];
  const isXNegative = polygon[0][0] < 0;
  const isYNegative = polygon[0][1] < 0;

  let x = randomUniform(310, 310 + width)();
  if (isXNegative) x *= -1;

  let y = randomUniform(25, 25 + height)();
  if (isYNegative) y *= -1;

  while (!polygonContains(polygon, [x, y])) {
    y = randomUniform(25, 25 + height)();
    if (isYNegative) y *= -1;
  }

  return { x, y };
};

const decorateTechnologiesForRadar = (
  upcomingTechnologies: Technologies,
  presentTechnologies: TechnologiesRadar | null
) => {
  return Object.values(upcomingTechnologies).reduce((result, technology) => {
    let position = {};
    const quadrant = getQuadrantForSection(technology.section);
    const ring = getRingForStage(technology.stage);
    const targetSegment = segment(quadrant, ring);
    if (technologyPresentAndUnchanged(technology, presentTechnologies)) {
      position = {
        x: presentTechnologies?.[technology.id].x,
        y: presentTechnologies?.[technology.id].y,
      };
    } else if (ring < 4) {
      position = randomPositionInside(targetSegment);
    } else {
      // @ts-ignore
      position = randomPositionOutside(quadrant);
    }

    return {
      ...result,
      [technology.id]: {
        ring,
        quadrant,
        color: technology.color,
        id: technology.id,
        segment: targetSegment,
        label: technology.label,
        stage: technology.stage,
        section: technology.section,
        numberOfProjects: technology.numberOfProjects,
        numberOfChildren: technology.numberOfChildren,
        children: technology.children,
        ...position,
      },
    };
  }, {});
};

export default decorateTechnologiesForRadar;
