import Isvg from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';

import copyIcn from 'assets/svg/copy.svg';
import TextButton from 'components/TextButton/TextButton';
import { useToast, ToastSeverity } from 'components/Toast/useToast';
import { useFilterTechnologies, useTechnology } from 'contexts/Technologies/Technologies.context';
import useWhichScreenView from 'shared/hooks/useWhichScreenView';
import APP_ROUTES from 'shared/routes';

import styles from './TechnologyDetailsNavigation.module.scss';

const TechnologyDetailsNavigation = () => {
  const navigate = useNavigate();
  const { resetSelections } = useFilterTechnologies();
  const { isGraph, isRadar } = useWhichScreenView();
  const technology = useTechnology();
  const toast = useToast();

  const goBack = () => {
    resetSelections();
    if (isRadar) navigate(APP_ROUTES.RADAR);
    if (isGraph) navigate(APP_ROUTES.GRAPH);
  };

  const copyDirectUrlToClipboard = () => {
    if (!technology) return;

    navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}${APP_ROUTES.RADAR}/${technology.id}`
    );

    toast({
      message: 'Direct URL has been copied to the clipboard.',
      severity: ToastSeverity.Info,
    });
  };

  return (
    <div className={styles.navigation}>
      <TextButton
        isBackBtn
        onClick={goBack}
        data-testid="view-technologydetails_navigation__back-button"
      >
        Back to main view
      </TextButton>

      {isRadar && technology ? (
        <button type="button" className={styles.copyButton} onClick={copyDirectUrlToClipboard}>
          <span className={styles.copyButtonLabel}>Copy direct URL</span>
          <Isvg src={copyIcn} />
        </button>
      ) : null}
    </div>
  );
};

export default TechnologyDetailsNavigation;
