import { useRef } from 'react';

import type { ComboBoxProps } from '@react-types/combobox';
import { useComboBox, useFilter } from 'react-aria';
import { useComboBoxState } from 'react-stately';

import Input from 'components/Input/Input';

import styles from './Combobox.module.scss';
import ComboboxInputButton from './ComboboxInputButton/ComboboxInputButton';
import ListBox from './ListBox/ListBox';
import Popover from './Popover/Popover';

const Combobox = <T extends object>({ ...props }: ComboBoxProps<T>) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef(null);
  const popoverRef = useRef(null);

  const { contains } = useFilter({ sensitivity: 'base' });
  const state = useComboBoxState<T>({
    ...props,
    defaultFilter: contains,
    menuTrigger: 'focus',
  });

  const { inputProps, listBoxProps, buttonProps } = useComboBox(
    {
      ...props,
      buttonRef,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state
  );

  return (
    <div className={styles.combobox}>
      <Input
        {...inputProps}
        onClick={e => {
          state.open();
          inputProps.onClick?.(e);
        }}
        className={styles.combobox__input}
        inputRef={inputRef}
        autoComplete="off"
        rightAddition={<ComboboxInputButton {...buttonProps} ref={buttonRef} />}
      />

      {state.isOpen && (
        <Popover ref={popoverRef} triggerRef={inputRef} state={state}>
          <ListBox {...listBoxProps} ref={listBoxRef} state={state} />
        </Popover>
      )}
    </div>
  );
};

export default Combobox;
