import { select } from 'd3-selection';

import { rings } from 'core/radarParams';
import { generateQuartersShapes } from 'core/radarUtils';
import { Callbacks } from 'core/types';

import highlightQuarter from './highlightQuarter';
import toggleQuarter from './toggleQuarter';

export default (callbacks?: Callbacks) => {
  const pathData = generateQuartersShapes(0, rings[rings.length - 2].radius);
  const data = pathData.map((path, index) => ({ path, index }));
  const quarters = select('.quarters');

  quarters
    .selectAll('path')
    .data(data)
    .enter()
    .append('path')
    .attr('class', 'quarter')
    .attr('d', d => d.path)
    .attr('fill', (d, index) => `url(#focusQuarterGradient${index})`)
    .attr('opacity', '0')
    .style('cursor', 'pointer')
    .on('mouseover', (e, d) => toggleQuarter('mouseover', d.index + 1))
    .on('mouseout', (e, d) => toggleQuarter('mouseout', d.index + 1))
    .on('click', (e, d) => highlightQuarter(d.index, callbacks));
};
