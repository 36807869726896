import { ReactNode, useContext } from 'react';

import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { NavigationTabContext } from '../NavigationTabGroup/NavigationTabGroup';
import styles from './NavigationTab.module.scss';

interface NavigationTabProps {
  children: ReactNode;
  to: string;
}

const NavigationTab = ({ children, to }: NavigationTabProps) => {
  const { variant } = useContext(NavigationTabContext);

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cn(styles.navlink, styles[`navlink--${variant}`], {
          [styles[`navlink--${variant}--active`]]: isActive,
        })
      }
    >
      {children}
    </NavLink>
  );
};

export default NavigationTab;
