import cnst from 'shared/constants';
import { STAGES_ENUM } from 'types/technology.types';

const radarConfig = {
  svg_id: 'radar',
  width: 1070,
  height: 810,
  colors: {
    background: 'transparent',
    grid: '#233446',
    inactive: '#ddd',
  },
  quadrants: [
    // eslint-disable-next-line max-len
    {
      name: cnst.QUADRANT.LANGUAGES_FRAMEWORKS,
      description: cnst.QUADRANT_DESCRIPTION.LANGUAGES_FRAMEWORKS,
    },
    { name: cnst.QUADRANT.PRODUCTS_TOOLS, description: cnst.QUADRANT_DESCRIPTION.PRODUCTS_TOOLS },
    { name: cnst.QUADRANT.AREAS_METHODS, description: cnst.QUADRANT_DESCRIPTION.AREAS_METHODS },
    { name: cnst.QUADRANT.PLATFORMS, description: cnst.QUADRANT_DESCRIPTION.PLATFORMS },
  ],
  rings: [
    {
      name: STAGES_ENUM.IN_USE,
      offset: [80, 100],
      shadow: '#06101a',
      bgColor: '#0b151f',
      color: '#3c71d0',
      x1: '0%',
      y1: '0%',
      x2: '100%',
      y2: '100%',
    },
    {
      name: STAGES_ENUM.BET,
      offset: [88, 100],
      shadow: '#06101a',
      bgColor: '#0b151f',
      color: '#e0223e',
      x1: '100%',
      y1: '0%',
      x2: '0%',
      y2: '100%',
    },
    {
      name: STAGES_ENUM.EXPERIMENT,
      offset: [92, 100],
      shadow: '#06101a',
      bgColor: '#0b151f',
      color: '#53c419',
      x1: '100%',
      y1: '100%',
      x2: '0%',
      y2: '0%',
    },
    {
      name: STAGES_ENUM.WATCH,
      offset: [94, 100],
      shadow: '#06101a',
      bgColor: '#0b151f',
      color: '#ffbf0e',
      x1: '0%',
      y1: '100%',
      x2: '100%',
      y2: '0%',
    },
    {
      name: '',
      bgColor: '#020304',
      color: '#ffbf0e',
    },
  ],
  print_layout: false,
};

export default radarConfig;
