import { useEffect } from 'react';

import { has } from 'lodash';
import { useParams } from 'react-router-dom';

import ScreenLayout from 'components/ScreenLayout/ScreenLayout';
import { ToastSeverity, useToast } from 'components/Toast/useToast';
import { useFilterTechnologies, useTechnologies } from 'contexts/Technologies/Technologies.context';

import RadarContent from './RadarContent/RadarContent';
import RadarLeftside from './RadarLeftside/RadarLeftside';
import RadarTop from './RadarTop/RadarTop';

const RadarScreen = () => {
  const { technologyId } = useParams();
  const technologies = useTechnologies();
  const { handleSearchChange } = useFilterTechnologies();
  const toast = useToast();

  useEffect(() => {
    if (!technologies || !technologyId) return;

    if (!has(technologies, technologyId)) {
      toast({
        message: 'Wrong or corrupted direct technology URL.',
        severity: ToastSeverity.Error,
      });
    }
  }, [technologyId, technologies]);

  // should fire only on mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleSearchChange(''), []);

  return (
    <ScreenLayout
      leftSide={<RadarLeftside />}
      top={<RadarTop hasScreenSwitcher={!technologyId} />}
      content={<RadarContent />}
    />
  );
};

export default RadarScreen;
