import { useMemo } from 'react';

import { reduce } from 'lodash';

import { useTechnologies, useTechnology } from 'contexts/Technologies/Technologies.context';
import makeGraphData from 'contexts/Technologies/utils/makeGraphData';
import { NodeDatum, LinkDatum, GraphData } from 'types/graph.types';

export default () => {
  const technologies = useTechnologies();
  const technology = useTechnology();

  return useMemo<GraphData<NodeDatum, LinkDatum>>(() => {
    if (!technology || !technologies) {
      return {
        nodes: [],
        links: [],
      };
    }

    const subtree = reduce(
      technology.related_technologies,
      (graphData, relatedTechnology) => {
        if (!technologies[relatedTechnology.id]) return graphData;

        return { ...graphData, [relatedTechnology.id]: technologies[relatedTechnology.id] };
      },
      {
        [technology.id]: technologies[technology.id],
      }
    );

    return makeGraphData(subtree);
  }, [technologies, technology]);
};
