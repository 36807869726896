import cnst from 'shared/constants/index';

interface SlackUriBuilderParams {
  teamId: string;
  channelId: string;
}

const defaultParams = {
  teamId: cnst.SLACK.TEAM_ID,
  channelId: cnst.SLACK.RND_CHANNEL_ID,
};

const slackUriBuilder = ({ teamId, channelId }: SlackUriBuilderParams = defaultParams) => {
  return `slack://channel?team=${teamId}&id=${channelId}`;
};

export default slackUriBuilder;
