import { useParams } from 'react-router-dom';

import AllStagesSkillsList from 'components/SidePanel/AllSkillList/AllStagesSkillsList';
import SidePanel from 'components/SidePanel/SidePanel';
import SidePanelSection from 'components/SidePanel/SidePanelSection/SidePanelSection';
import SidePanelSectionHeader from 'components/SidePanel/SidePanelSectionHeader/SidePanelSectionHeader';
import { useFilterTechnologies } from 'contexts/Technologies/Technologies.context';
import Search from 'views/Search/Search';
import SearchResults from 'views/SearchResults/SearchResults';
import TechnologyDetails from 'views/TechnologyDetails/TechnologyDetails';

const RadarLeftside = () => {
  const { technologyId } = useParams();
  const { searchPhrase: userIsUsingSearch } = useFilterTechnologies();

  return (
    <SidePanel>
      {!technologyId ? (
        <>
          <SidePanelSection hasBottomBorder>
            <SidePanelSectionHeader>Search skill</SidePanelSectionHeader>
            <Search />
          </SidePanelSection>
          <SidePanelSection>
            {userIsUsingSearch ? (
              <SearchResults />
            ) : (
              <>
                <SidePanelSectionHeader>All skills</SidePanelSectionHeader>
                <AllStagesSkillsList withStageIcon withStageTooltip />
              </>
            )}
          </SidePanelSection>
        </>
      ) : (
        <TechnologyDetails technologyId={technologyId} />
      )}
    </SidePanel>
  );
};

export default RadarLeftside;
