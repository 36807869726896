import { AriaAttributes, ReactNode } from 'react';

import { VStack } from '@chakra-ui/react';

import styles from './CardVertical.module.scss';

interface CardVerticalProps extends AriaAttributes {
  icon?: ReactNode;
  supLabel: ReactNode;
  label: ReactNode;
  subLabel: ReactNode;
}

const CardVertical = ({ icon, supLabel, label, subLabel, ...props }: CardVerticalProps) => {
  return (
    <VStack className={styles.card} spacing="8px" role="group" {...props}>
      {icon ? <div className={styles.icon}>{icon}</div> : null}

      <div className={styles.supLabel}>{supLabel}</div>

      <div className={styles.label}>{label}</div>

      <div className={styles.subLabel}>{subLabel}</div>
    </VStack>
  );
};

export default CardVertical;
