import { forwardRef } from 'react';

import { useObjectRef } from '@react-aria/utils';
import cn from 'classnames';
import { AriaButtonProps, useButton } from 'react-aria';
import Isvg from 'react-inlinesvg';

import caretIcn from 'assets/svg/caret.svg';
import InputButton from 'components/Input/InputAdditions/InputButton';

import styles from './ComboboxInputButton.module.scss';

type ComboboxInputButtonProps = AriaButtonProps<'button'>;

const ComboboxInputButton = forwardRef<HTMLButtonElement, ComboboxInputButtonProps>(
  function ComboboxInputButton(props, ref) {
    const buttonRef = useObjectRef(ref);

    const { buttonProps } = useButton(props, buttonRef);

    return (
      <InputButton
        ref={buttonRef}
        className={cn(styles.button, buttonProps.className)}
        {...buttonProps}
      >
        <Isvg src={caretIcn} className={styles.button__icon} />
      </InputButton>
    );
  }
);

export default ComboboxInputButton;
