import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import { ChildrenProp } from 'types';

import styles from './RadioGroup.module.scss';

interface RadioButtonGroupProps extends ChildrenProp {
  groupLabel: string;
  tooltip?: string;
}

const RadioGroup = ({ groupLabel, tooltip, children }: RadioButtonGroupProps) => {
  return (
    <fieldset className={styles.radioGroupWrapper}>
      <legend className={styles.radioGroupLabelWrapper}>
        <span>{groupLabel}</span>
        {tooltip ? <InfoTooltip tooltipText={tooltip} /> : null}
      </legend>
      <div className={styles.radioGroupControls}>{children}</div>
    </fieldset>
  );
};

export default RadioGroup;
