import { pick } from 'lodash';

import getNodeSizeFactor from 'shared/helpers/getNodeSizeFactor';
import { NodeDatum, LinkDatum } from 'types/graph.types';
import { Technologies, Technology } from 'types/technology.types';

const makeGraphData = (technologies: Technologies | null) => {
  const nodes: NodeDatum[] = [];
  const links: LinkDatum[] = [];

  if (!technologies) {
    return {
      nodes,
      links,
    };
  }

  const makeNode = (technology: Technology) => {
    const {
      numberOfProjects,
      numberOfChildren,
      numberOfOpps,
      numberOfResearches,
      numberOfLeads,
      numberOfTalents,
    } = technology;
    nodes.push({
      ...pick(technology, [
        'id',
        'color',
        'label',
        'section',
        'stage',
        'numberOfProjects',
        'numberOfOpps',
        'numberOfTalents',
      ]),
      numberOfProjectsSizeFactor: getNodeSizeFactor(numberOfProjects),
      numberOfChildrenSizeFactor: getNodeSizeFactor(numberOfChildren),
      numberOfOppsSizeFactor: getNodeSizeFactor(numberOfOpps),
      numberOfResearchesSizeFactor: getNodeSizeFactor(numberOfResearches),
      numberOfLeadsSizeFactor: getNodeSizeFactor(numberOfLeads),
      numberOfTalentsSizeFactor: getNodeSizeFactor(numberOfTalents),
    });
  };

  const makeLinks = (technology: Technology) => {
    technology.children.forEach(target => {
      const childExist = Boolean(technologies[target]);
      if (childExist) {
        links.push({
          source: technology.id,
          target,
        });
      }
    });
  };

  Object.values(technologies).forEach(technology => {
    makeNode(technology);
    makeLinks(technology);
  });

  return {
    nodes,
    links,
  };
};

export default makeGraphData;
