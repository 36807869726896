import { ReactNode } from 'react';

import Isvg from 'react-inlinesvg';

import infoIcon from 'assets/svg/info.svg';
import Tooltip from 'components/Tooltip/Tooltip';

import styles from './InfoTooltip.module.scss';

interface Props {
  tooltipText: ReactNode;
}

const InfoTooltip = ({ tooltipText }: Props) => {
  return (
    <Tooltip content={tooltipText} placement="right-start" offset={{ mainAxis: 6, crossAxis: 16 }}>
      <span className={styles['info-tooltip']} data-testid="components-infoTooltip">
        <Isvg src={infoIcon} />
      </span>
    </Tooltip>
  );
};

export default InfoTooltip;
