import HeaderLayout from 'components/HeaderLayout/HeaderLayout';
import AuthView from 'views/AuthView/AuthView';
import ScreenSwitcher from 'views/ScreenSwitcher/ScreenSwitcher';

interface RadarTopProps {
  hasScreenSwitcher: boolean;
}

const RadarTop = ({ hasScreenSwitcher }: RadarTopProps) => (
  <HeaderLayout middle={hasScreenSwitcher ? <ScreenSwitcher /> : null} right={<AuthView />} />
);

export default RadarTop;
