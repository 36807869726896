import { Box } from '@chakra-ui/react';

import { useTechnology } from 'contexts/Technologies/Technologies.context';

import TechnologyDetailsArticleList from '../../TechnologyDetailsArticles/TechnologyDetailsArticles';

const TechnologyDetailsArticles = () => {
  const technology = useTechnology();

  if (!technology) return null;

  return (
    <Box mb="40px">
      <TechnologyDetailsArticleList technology={technology} />
    </Box>
  );
};

export default TechnologyDetailsArticles;
