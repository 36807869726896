import { Box } from '@chakra-ui/react';
import Isvg from 'react-inlinesvg';

import externalLinkIcon from 'assets/svg/external_link.svg';
import Chip from 'components/Chip/Chip';
import ChipList from 'components/ChipList/ChipList';
import { BlogPost } from 'types/technology.types';

import styles from './ArticlesListItem.module.scss';

interface ArticlesListItemProps {
  article: BlogPost;
}

const ArticlesListItem = ({ article }: ArticlesListItemProps) => {
  const { title, url, summary, tags } = article;
  const preparedSummary = summary ? `"${summary}"` : "This article doesn't have summary";

  return (
    <li className={styles.articleListItem}>
      <a href={url} target="_blank" rel="noopener noreferrer" className={styles.itemLink}>
        <h4 className={styles.header}>
          <span>{title}</span>

          <Isvg className={styles.externalLinkIcon} src={externalLinkIcon} aria-hidden />
        </h4>

        <Box mt="12px" className={styles.summary}>
          {preparedSummary}
        </Box>

        {tags?.length ? (
          <Box mt="24px">
            <ChipList>
              {tags.map(label => (
                <Chip key={label}>{label}</Chip>
              ))}
            </ChipList>
          </Box>
        ) : null}
      </a>
    </li>
  );
};

export default ArticlesListItem;
