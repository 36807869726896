import { ReactNode } from 'react';

import styles from './SidePanelSectionSubheader.module.scss';

interface SidePanelSectionSubheaderProps {
  children: ReactNode;
}

const SidePanelSectionSubheader = ({ children }: SidePanelSectionSubheaderProps) => {
  return <h3 className={styles.subheader}>{children}</h3>;
};

export default SidePanelSectionSubheader;
