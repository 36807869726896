import { ButtonHTMLAttributes } from 'react';

import Isvg from 'react-inlinesvg';

import caret from 'assets/svg/caret.svg';

import styles from './CollapseButton.module.scss';

interface CollapseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  isExpanded: boolean;
}

const CollapseButton = ({ onClick, isExpanded, ...props }: CollapseButtonProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={styles.collaspeButton}
      aria-expanded={isExpanded}
      aria-label={isExpanded ? 'Close' : 'Open'}
      aria-controls={props['aria-controls']}
    >
      <Isvg src={caret} className={styles['collaspeButton-caret']} aria-hidden />
    </button>
  );
};

export default CollapseButton;
