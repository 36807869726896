import { ForwardedRef, forwardRef, ButtonHTMLAttributes, ReactNode } from 'react';

import cn from 'classnames';

import styles from './Button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: 'md' | 'sm';
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  fullWidth?: boolean;
}

const Button = forwardRef(function Button(
  {
    className,
    variant = 'primary',
    size = 'md',
    leftIcon,
    rightIcon,
    fullWidth,
    children,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      type="button"
      className={cn(
        styles.button,
        styles[`button--${variant}`],
        styles[`button--${size}`],
        fullWidth && styles['button--full-width'],
        className
      )}
      {...props}
    >
      {leftIcon ? (
        <span className={cn(styles.button__icon, styles['button__icon--left'])}>{leftIcon}</span>
      ) : null}

      {children ? <span>{children}</span> : null}

      {rightIcon ? (
        <span className={cn(styles.button__icon, styles['button__icon--right'])}>{rightIcon}</span>
      ) : null}
    </button>
  );
});

export default Button;
