import Radio, { RadioProps } from 'components/Radio/Radio';

import SmallCardWrapper, {
  SmallCardWrapperProps,
} from '../CardWrapper/SmallCardWrapper/SmallCardWrapper';

type RadioCardSmallProps = Omit<SmallCardWrapperProps, 'children'> & Omit<RadioProps, 'standalone'>;

const RadioCardSmall = ({ label, fullWidth, ...inputProps }: RadioCardSmallProps) => {
  return (
    <SmallCardWrapper label={label} disabled={inputProps.disabled} fullWidth={fullWidth}>
      <Radio {...inputProps} standalone={false} />
    </SmallCardWrapper>
  );
};

export default RadioCardSmall;
