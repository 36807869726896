export enum FeatureFlag {
  SidePanelTalents = 'side_panel_talents',
  SidePanelResources = 'side_panel_resources',
}

enum Environment {
  Test = 'test',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

const featureFlags: Record<Environment, Record<FeatureFlag, boolean>> = {
  [Environment.Test]: {
    [FeatureFlag.SidePanelTalents]: true,
    [FeatureFlag.SidePanelResources]: true,
  },
  [Environment.Development]: {
    [FeatureFlag.SidePanelTalents]: true,
    [FeatureFlag.SidePanelResources]: true,
  },
  [Environment.Staging]: {
    [FeatureFlag.SidePanelTalents]: true,
    [FeatureFlag.SidePanelResources]: true,
  },
  [Environment.Production]: {
    [FeatureFlag.SidePanelTalents]: false,
    [FeatureFlag.SidePanelResources]: false,
  },
};

export const isFlagEnabled = (featureFlagName: FeatureFlag) => {
  if (process.env.NODE_ENV === Environment.Test) {
    return featureFlags[Environment.Test][featureFlagName];
  }

  // Development (Local environment)
  if (process.env.NODE_ENV === Environment.Development) {
    return featureFlags[Environment.Development][featureFlagName];
  }

  // Staging environment
  if (process.env.REACT_APP_ENVIRONMENT === Environment.Staging) {
    return featureFlags[Environment.Staging][featureFlagName];
  }

  // Production environment
  if (process.env.REACT_APP_ENVIRONMENT === Environment.Production) {
    return featureFlags[Environment.Production][featureFlagName];
  }

  return false;
};
