import { Box, VStack } from '@chakra-ui/react';
import Isvg from 'react-inlinesvg';

import Accordion from 'components/Accordion/Accordion';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import cnst from 'shared/constants';
import List from 'views/List/List';
import useSearchedTechnologies from 'views/SearchResults/hooks/useSearchedTechnologies';

import styles from './AllStagesSkillsList.module.scss';

const { SUPPORTED_STAGES, SIMLIFIED_SUPPORTED_STAGES_ICONS, STAGE_FILTERS_DESCRIPTION } = cnst;

interface AllStagesSkillsListProps {
  withStageIcon?: boolean;
  withStageTooltip?: boolean;
}

const AllStagesSkillsList = ({
  withStageIcon = false,
  withStageTooltip = false,
}: AllStagesSkillsListProps) => {
  const { searchedTechnologiesByStage } = useSearchedTechnologies();

  return (
    <VStack spacing="24px" align="stretch">
      {SUPPORTED_STAGES.map(stage => {
        return searchedTechnologiesByStage[stage]?.length ? (
          <Accordion
            key={stage}
            level={2}
            label={
              <Box display="flex" alignItems="center">
                {withStageIcon && (
                  <Isvg
                    src={SIMLIFIED_SUPPORTED_STAGES_ICONS[stage]}
                    className={styles.labelIcon}
                    aria-hidden
                  />
                )}
                <div className={styles.labelText}>
                  {stage} ({searchedTechnologiesByStage[stage].length})
                </div>
                {withStageTooltip && <InfoTooltip tooltipText={STAGE_FILTERS_DESCRIPTION[stage]} />}
              </Box>
            }
          >
            <List items={searchedTechnologiesByStage[stage]} />
          </Accordion>
        ) : null;
      })}
    </VStack>
  );
};

export default AllStagesSkillsList;
