import { useEffect } from 'react';

import * as d3 from 'd3';

export default forceGraphRef => {
  useEffect(() => {
    const fg = forceGraphRef.current;

    fg.d3Force('charge', d3.forceManyBody().strength(-280));
    fg.d3Force('collide', d3.forceCollide(42));
    fg.d3Force('link', d3.forceLink().distance(200));
  }, []);
};
