import { FC } from 'react';

import cn from 'classnames';
import Isvg from 'react-inlinesvg';

import bulbIcn from 'assets/svg/bulb.svg';
import setupIcn from 'assets/svg/setup.svg';
import stackIcn from 'assets/svg/stack.svg';
import toolsIcn from 'assets/svg/tools.svg';
import TextButton from 'components/TextButton/TextButton';
import cnst from 'shared/constants';

import styles from './QuadrantHeader.module.scss';

const sectionIcn = {
  [cnst.QUADRANT.AREAS_METHODS]: bulbIcn,
  [cnst.QUADRANT.PRODUCTS_TOOLS]: toolsIcn,
  [cnst.QUADRANT.PLATFORMS]: stackIcn,
  [cnst.QUADRANT.LANGUAGES_FRAMEWORKS]: setupIcn,
};

interface QuadrantHeaderProps {
  sectionName: string;
  onClose?: () => void;
}

const QuadrantHeader: FC<QuadrantHeaderProps> = ({ sectionName, onClose }) => {
  return (
    <div className={styles.header}>
      {onClose && (
        <TextButton isBackBtn onClick={onClose}>
          Main view
        </TextButton>
      )}
      <div className={styles.row}>
        <div className={styles.col}>
          {sectionIcn[sectionName] && (
            <Isvg className={styles.icon} src={sectionIcn[sectionName]} />
          )}
        </div>
        <div className={cn([styles.col, styles.col__flexGrow1])}>
          <h5>Quadrant</h5>
          <h3>{sectionName}</h3>
        </div>
      </div>
    </div>
  );
};

export default QuadrantHeader;
