import { useState } from 'react';

import { debounce, isNumber } from 'lodash';

import { useSetInteractions } from 'contexts/Interactions/Interactions.context';
import { focusQuarter, triggerEvent, toggleQuarter, hideAura } from 'core/radar';

const useSearchTechnologies = () => {
  const [searchPhrase, setSearchPhrase] = useState('');

  const { setHighlightedTechnologyId, setHighlightedQuarter } = useSetInteractions();

  const resetSelections = (id?: string, quadrant?: number) => {
    if (id && isNumber(quadrant)) {
      triggerEvent('mouseout', id);
      toggleQuarter('mouseout', quadrant + 1);
    }

    setHighlightedQuarter(null);
    setHighlightedTechnologyId('');
    focusQuarter(null, false);
    hideAura();
  };

  const debounceResetSelections = debounce(() => resetSelections(), 300);

  const handleSearchChange = (phrase: string) => {
    setSearchPhrase(phrase);

    if (phrase) {
      debounceResetSelections();
    }
  };

  return { handleSearchChange, resetSelections, searchPhrase };
};

export default useSearchTechnologies;
