import { ForwardedRef, forwardRef } from 'react';

import InlineSVG from 'react-inlinesvg';

import externalLinkIcon from 'assets/svg/external_link.svg';
import Button, { ButtonProps } from 'components/Button/Button';

interface ExternalLinkButtonProps extends Pick<ButtonProps, 'fullWidth' | 'disabled' | 'children'> {
  href: string;
}

const ExternalLinkButton = forwardRef(function ExternalLinkButton(
  { href, ...props }: ExternalLinkButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <Button
      ref={ref}
      role="link"
      onClick={() => {
        window.open(href, '_blank', 'noopener,noreferrer');
      }}
      variant="secondary"
      rightIcon={<InlineSVG width="16px" height="16px" src={externalLinkIcon} />}
      {...props}
    />
  );
});

export default ExternalLinkButton;
