import { FC, ReactNode } from 'react';

import styles from './HeaderLayout.module.scss';

interface HeaderLayoutProps {
  left?: ReactNode;
  middle?: ReactNode;
  right?: ReactNode;
}

const HeaderLayout: FC<HeaderLayoutProps> = ({ left = null, middle = null, right = null }) => (
  <div data-testid="components-headerlayout" className={styles.wrapper}>
    <div className={styles.wrapper__left}>{left}</div>
    <div>{middle}</div>
    <div className={styles.wrapper__right}>{right}</div>
  </div>
);

export default HeaderLayout;
