import Isvg from 'react-inlinesvg';

import closeIcn from 'assets/svg/close.svg';
import searchIcn from 'assets/svg/search.svg';
import Input from 'components/Input/Input';
import InputButton from 'components/Input/InputAdditions/InputButton';
import InputIcon from 'components/Input/InputAdditions/InputIcon';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const SearchInput = ({ value, onChange, placeholder }: SearchInputProps) => {
  return (
    <Input
      onChange={event => onChange(event.target.value)}
      rightAddition={
        value ? (
          <InputButton onClick={() => onChange('')} aria-label="Clear">
            <Isvg src={closeIcn} />
          </InputButton>
        ) : (
          <InputIcon>
            <Isvg src={searchIcn} />
          </InputIcon>
        )
      }
      placeholder={placeholder}
      value={value}
    />
  );
};

export default SearchInput;
