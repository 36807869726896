import { useToast as useToastBase } from '@chakra-ui/react';

import { Toast } from './Toast';

interface useToastProps {
  message: string;
  severity?: ToastSeverity;
}

export enum ToastSeverity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
}

const useToast = () => {
  const toastBase = useToastBase({
    containerStyle: {
      width: '100%',
    },
  });

  const returnFunction = (options: useToastProps) =>
    toastBase({
      // Render function does not receive the props passed to toast function, so
      // following kind of wrapper is needed to get access to passed props
      render: ({ onClose }) => <Toast {...options} onClose={onClose} />,
    });

  return Object.assign(returnFunction, toastBase);
};

export { useToast, type useToastProps };
