import { ReactNode } from 'react';

import styles from './ScreenLayout.module.scss';

interface ScreenLayoutProps {
  leftSide?: ReactNode;
  top?: ReactNode;
  content?: ReactNode;
  bottom?: ReactNode;
}

const ScreenLayout = ({
  leftSide = null,
  top = null,
  content = null,
  bottom = null,
}: ScreenLayoutProps) => (
  <div className={styles.wrapper}>
    {leftSide}
    <div className={styles.rightSide}>
      {top}
      {content}
      {bottom}
    </div>
  </div>
);

export default ScreenLayout;
