import { easeCircleOut } from 'd3-ease';
import { interpolateString } from 'd3-interpolate';
import { arc } from 'd3-shape';
import { transition } from 'd3-transition';
import { map } from 'lodash';

import bulbIcn from 'assets/svg/bulb.svg';
import setupIcn from 'assets/svg/setup.svg';
import stackIcn from 'assets/svg/stack.svg';
import toolsIcn from 'assets/svg/tools.svg';
import { FixMe } from 'types';

import config from './radarConfig';
import { quadrants, rings, quartersData } from './radarParams';

// custom random number generator, to make random sequence reproducible
// source: https://stackoverflow.com/questions/521295
let seed = 42;

const random = () => {
  const x = Math.sin((seed += 1)) * 10000;

  return x - Math.floor(x);
};

const randomBetween = (min: number, max: number) => min + random() * (max - min);
const normalBetween = (min: number, max: number) => min + (random() + random()) * 0.5 * (max - min);

const polar = ({ x, y }: { x: number; y: number }) => ({
  t: Math.atan2(y, x),
  r: Math.sqrt(x * x + y * y),
});

const cartesian = ({ r, t }: { r: number; t: number }) => ({
  x: r * Math.cos(t),
  y: r * Math.sin(t),
});

const boundedInterval = (value: number, min: number, max: number) => {
  const low = Math.min(min, max);
  const high = Math.max(min, max);

  return Math.min(Math.max(value, low), high);
};

const boundedRing = (polarCoords: { r: number; t: number }, rMin: number, rMax: number) => ({
  t: polarCoords.t,
  r: boundedInterval(polarCoords.r, rMin, rMax),
});

const boundedBox = (
  point: { x: number; y: number },
  min: { x: number; y: number },
  max: { x: number; y: number }
) => ({
  x: boundedInterval(point.x, min.x, max.x),
  y: boundedInterval(point.y, min.y, max.y),
});

const segment = (quadrant: number, ring: number) => {
  const polarMin = {
    t: quadrants[quadrant].radial_min * Math.PI,
    r: ring === 0 ? 30 : rings[ring - 1].radius,
  };
  const polarMax = {
    t: quadrants[quadrant].radial_max * Math.PI,
    r: rings[ring].radius,
  };
  const cartesianMin = {
    x: 15 * quadrants[quadrant].factor_x,
    y: 15 * quadrants[quadrant].factor_y,
  };
  const cartesianMax = {
    x: rings[3].radius * quadrants[quadrant].factor_x,
    y: rings[3].radius * quadrants[quadrant].factor_y,
  };

  return {
    clipx(d: { x: number; y: number }) {
      const param = d;
      const c = boundedBox(param, cartesianMin, cartesianMax);
      const p = boundedRing(polar(c), polarMin.r + 15, polarMax.r - 15);
      param.x = cartesian(p).x; // adjust data too!

      return param.x;
    },
    clipy(d: { x: number; y: number }) {
      const param = d;
      const c = boundedBox(param, cartesianMin, cartesianMax);
      const p = boundedRing(polar(c), polarMin.r + 15, polarMax.r - 15);
      param.y = cartesian(p).y; // adjust data too!

      return param.y;
    },
    random() {
      return cartesian({
        t: randomBetween(polarMin.t, polarMax.t),
        r: normalBetween(polarMin.r, polarMax.r),
      });
    },
  };
};

const translate = (x: number, y: number) => `translate(${x}, ${y})`;

const getColor = {
  0: '#188eff',
  1: '#1de0d9',
  2: '#ff174b',
  3: '#e9b41a',
};

const getShape = {
  0: 'M 0 0 m 1, 5 a 4,4 0 1,0 8,0 a 4,4 0 1,0 -8,0',
  1: 'M9 7.5 L9 2.5 L5 0.5 L1 2.5 L1 7.5 L5 9.5 Z',
  2: 'M5 10 L10 05 L05 0 L0 05 Z',
  3: 'M0 8 L10 8 L05 0 Z',
};

const getIcon = {
  0: setupIcn,
  1: toolsIcn,
  2: bulbIcn,
  3: stackIcn,
};

const interpolRotate = interpolateString('rotate(0,0,0)', 'rotate(360,0,0)');

const updateEntries = (entries: FixMe) =>
  map(entries, entry => ({
    ...entry,
    bgColor: getColor[entry.quadrant],
    shape: getShape[entry.ring] || getShape[0],
    color:
      entry.active || config.print_layout ? config.rings[entry.ring].color : config.colors.inactive,
  }));

const animation = <OldDatum>(
  duration: number,
  ease: (normalizedTime: number) => number = easeCircleOut
) => transition<OldDatum>().duration(duration).ease(ease);

const generateQuartersShapes = (innerRadius: number, outerRadius: number) => {
  const arcGenerator = arc();

  return quartersData.map(
    ([startAngle, endAngle]) =>
      arcGenerator({ startAngle, endAngle, innerRadius, outerRadius }) as string
  );
};

const getParentNode = (node: FixMe) => node.select(() => node.node().parentNode);

export {
  random,
  polar,
  cartesian,
  randomBetween,
  normalBetween,
  boundedInterval,
  boundedBox,
  boundedRing,
  segment,
  translate,
  getColor,
  getShape,
  getIcon,
  interpolRotate,
  updateEntries,
  animation,
  generateQuartersShapes,
  getParentNode,
};
