import SidePanel from 'components/SidePanel/SidePanel';
import SidePanelSection from 'components/SidePanel/SidePanelSection/SidePanelSection';
import SidePanelSectionHeader from 'components/SidePanel/SidePanelSectionHeader/SidePanelSectionHeader';
import { useFilterTechnologies } from 'contexts/Technologies/Technologies.context';
import Search from 'views/Search/Search';
import SearchResults from 'views/SearchResults/SearchResults';

import FilterOptionsSection from './FilterOptionsSection/FilterOptionsSection';

const FiltersPanel = () => {
  const { searchPhrase: userIsUsingSearch } = useFilterTechnologies();

  return (
    <SidePanel>
      <SidePanelSection hasBottomBorder>
        <SidePanelSectionHeader>Search skill</SidePanelSectionHeader>
        <Search />
      </SidePanelSection>
      <SidePanelSection>
        {userIsUsingSearch ? <SearchResults /> : <FilterOptionsSection />}
      </SidePanelSection>
    </SidePanel>
  );
};

export default FiltersPanel;
