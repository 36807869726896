import { select, selectAll } from 'd3-selection';

import { FixMe } from 'types';

export default (event: string, quarter: number) => {
  let isMouseOver = false;
  let isFaded = false;

  if (event === 'mouseover') {
    isMouseOver = true;
    isFaded = true;
  }

  select('.quarters').select(`path:nth-child(${quarter})`).classed('hoverQuadrant', isMouseOver);

  selectAll('.quadrantTitle')
    .filter((elem, index) => index !== quarter - 1)
    .classed('fadedQuadrantTitle', isFaded);

  selectAll('g[class^=blip]')
    .filter((blip: FixMe) => blip.quadrant !== quarter - 1)
    .selectAll('path')
    .classed('fadedBlip', isFaded);
};
