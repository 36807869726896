import { useState, useCallback } from 'react';

/**
 * @param {Boolean} defaultValue
 * @returns {[Boolean, () => void]}
 */
const useToggle = (defaultValue = false): [boolean, () => void] => {
  const [value, setValue] = useState(defaultValue);
  const toggle = useCallback(() => setValue(prev => !prev), []);
  return [value, toggle];
};

export default useToggle;
