import { useQuery } from '@tanstack/react-query';

import { ToastSeverity, useToast } from 'components/Toast/useToast';
import { useAuth } from 'contexts/Auth/Auth.context';

interface UseUnitsQueryParam {
  technologyId: string;
}

interface Unit {
  id: string;
  name: string;
}

type Units = {
  units: Unit[];
};

const useUnitsQuery = ({ technologyId }: UseUnitsQueryParam) => {
  const { user } = useAuth();
  const toast = useToast();
  const accessToken = user.getToken();

  return useQuery<Units>({
    queryKey: ['units', technologyId, accessToken],
    queryFn: async ({ signal }) => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/units/${technologyId}`, {
        signal,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.json();
    },
    onError: () => {
      toast({
        severity: ToastSeverity.Error,
        message: "We weren't able to download department choices. Please try again later.",
      });
    },
  });
};

export default useUnitsQuery;
