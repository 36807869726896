import { useMemo, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';

import Accordion from 'components/Accordion/Accordion';
import ExternalLinkButton from 'components/ExternalLinkButton/ExternalLinkButton';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import SearchInput from 'components/SearchInput/SearchInput';
import SidePanelSectionHeader from 'components/SidePanel/SidePanelSectionHeader/SidePanelSectionHeader';
import cnst from 'shared/constants/index';
import { BlogPost, TechnologyDetails } from 'types/technology.types';

import { SearchResultsEmptyLabel, SearchResultsLabel } from '../TechnologyDetailsSearch';
import ArticlesList from './ArticlesList/ArticlesList';
import NoRelatedArticlesMessage from './NoRelatedArticlesMessage/NoRelatedArticlesMessage';

interface TechnologyDetailsArticlesProps {
  technology: TechnologyDetails;
}

const TechnologyDetailsArticles = ({ technology }: TechnologyDetailsArticlesProps) => {
  const [searchPhrase, setSearchPhrase] = useState('');

  const blogPosts = technology.blog_posts;
  const filteredBlogPosts = useMemo<BlogPost[]>(() => {
    if (!blogPosts) {
      return [];
    }

    const searchPhraseLowerCase = searchPhrase.toLocaleLowerCase();

    return blogPosts.filter(
      ({ title, tags }) =>
        title.toLocaleLowerCase().includes(searchPhraseLowerCase) ||
        tags?.some(tag => tag.toLocaleLowerCase().includes(searchPhraseLowerCase))
    );
  }, [blogPosts, searchPhrase]);

  return (
    <>
      <SidePanelSectionHeader>
        <HStack spacing="8px">
          <span>Articles</span>
          <InfoTooltip tooltipText="Articles consist of Netguru knowledge gathered in one place. You can review all the articles to a related skill." />
        </HStack>
      </SidePanelSectionHeader>

      <Box mb="32px">
        <SearchInput
          placeholder="Search related articles"
          value={searchPhrase}
          onChange={setSearchPhrase}
        />
      </Box>

      {searchPhrase ? (
        <>
          <SearchResultsLabel itemCount={filteredBlogPosts.length} />

          {filteredBlogPosts.length ? (
            <ArticlesList articles={filteredBlogPosts} />
          ) : (
            <SearchResultsEmptyLabel>No researches match your search.</SearchResultsEmptyLabel>
          )}
        </>
      ) : (
        <Accordion
          defaultExpanded
          level={2}
          label={`Internal researches (${blogPosts?.length || 0})`}
        >
          <Box mb="32px">
            <ExternalLinkButton href={cnst.URLS.ALL_ARTICLES} fullWidth>
              See all researches
            </ExternalLinkButton>
          </Box>

          {blogPosts?.length ? (
            <ArticlesList articles={blogPosts} />
          ) : (
            <NoRelatedArticlesMessage technologyName={technology.name} />
          )}
        </Accordion>
      )}
    </>
  );
};

export default TechnologyDetailsArticles;
