import { Resource, ResourceType } from '../types';
import NoResources from './NoResources/NoResources';
import styles from './ResourcesList.module.scss';
import ResourcesListItem from './ResourcesListItem/ResourcesListItem';

interface ResourcesListProps {
  resources: Resource[];
  resourceType?: ResourceType;
  technologyName: string;
  shouldDisplayTags?: boolean;
  shouldDisplaySummary?: boolean;
}

const ResourcesList = ({
  resources,
  resourceType,
  technologyName,
  shouldDisplayTags = true,
  shouldDisplaySummary = true,
}: ResourcesListProps) => {
  if (!resources?.length)
    return <NoResources resourceType={resourceType} technologyName={technologyName} />;

  return (
    <ul className={styles.resourcesList}>
      {resources.map(resource => (
        <ResourcesListItem
          key={resource.url}
          resource={resource}
          shouldDisplayTags={shouldDisplayTags}
          shouldDisplaySummary={shouldDisplaySummary}
        />
      ))}
    </ul>
  );
};

export default ResourcesList;
