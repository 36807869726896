import { ForwardedRef, forwardRef } from 'react';

import { useObjectRef } from '@react-aria/utils';
import { AriaListBoxOptions, useListBox } from 'react-aria';
import { ListState } from 'react-stately';

import Option from '../Option/Option';
import styles from './ListBox.module.scss';

type Props<T> = AriaListBoxOptions<T> & {
  state: ListState<T>;
};

const ListBox = forwardRef(function ListBox<T>(
  { state, ...props }: Props<T>,
  ref: ForwardedRef<HTMLUListElement>
) {
  const listBoxRef = useObjectRef(ref);
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <ul {...listBoxProps} ref={listBoxRef} className={styles['list-box']}>
      {Array.from(state.collection).map(item => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </ul>
  );
});

export default ListBox;
