import { forceSimulation, forceCollide } from 'd3-force';
import { select } from 'd3-selection';

import { updateEntries, animation } from 'core/radarUtils';
import { Callbacks } from 'core/types';
import { RadarTechnology, TechnologiesRadar } from 'types/radar.types';

import hideBubble from './hideBubble';
import showBlipAura from './showBlipAura';
import showBubble from './showBubble';
import ticked from './ticked';

export default (technologies: TechnologiesRadar | null, callbacks?: Callbacks) => {
  const entries = updateEntries(technologies).filter(updated => updated.ring < 4);

  const blips = select('.rink').selectAll('.blip').data(entries);

  const blipsU = blips
    .enter()
    .append('g')
    .attr('class', 'blip')
    .on('mouseover', (e, d) => {
      showBubble(d, callbacks);
    })
    .on('mouseout', (e, d) => {
      hideBubble(d, null, callbacks);
    })
    .on('click', (e, d) => {
      showBlipAura(d, callbacks);
    })
    .style('opacity', 0)
    .transition(animation(1500))
    .delay(2000)
    .style('opacity', 1);

  // configure each blip
  blipsU.each(function setBlip(d) {
    const blip = select(this);

    blip
      .append('path')
      .style('cursor', 'pointer')
      .attr('class', 'shape')
      .attr('id', d.id)
      .attr('d', d.shape)
      .attr('fill', d.bgColor)
      .attr('fill-opacity', 0.6)
      .style('transition', 'opacity .5s, fill .5s')
      .attr('pointer-events', 'all');
  });

  const blipsAll = select('.rink').selectAll<SVGGElement, RadarTechnology>('.blip');

  forceSimulation()
    .nodes(entries)
    .velocityDecay(0.19) // magic number (found by experimentation)
    .force('collision', forceCollide().radius(12).strength(0.85))
    .on('tick', () => {
      ticked(blipsAll); // make sure that blips stay inside their segment
    });
};
