import { ButtonHTMLAttributes } from 'react';

import Isvg from 'react-inlinesvg';

import arrowBack from 'assets/svg/arrow_left.svg';

import styles from './TextButton.module.scss';

interface TextButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'className'> {
  isBackBtn?: boolean;
}

const TextButton = ({ children, isBackBtn, ...otherProps }: TextButtonProps) => (
  <button className={styles.btn} {...otherProps} type="button">
    {isBackBtn && <Isvg src={arrowBack} className={styles.btn__iconBack} />}
    {children}
  </button>
);

export default TextButton;
