// radial_min / radial_max are multiples of PI
const quadrants = [
  {
    radial_min: 0,
    radial_max: 0.5,
    factor_x: 1,
    factor_y: 1,
  },
  {
    radial_min: 0.5,
    radial_max: 1,
    factor_x: -1,
    factor_y: 1,
  },
  {
    radial_min: -1,
    radial_max: -0.5,
    factor_x: -1,
    factor_y: -1,
  },
  {
    radial_min: -0.5,
    radial_max: 0,
    factor_x: 1,
    factor_y: -1,
  },
];

const rings = [{ radius: 130 }, { radius: 220 }, { radius: 310 }, { radius: 400 }, { radius: 0 }];

const restOfTechnologiesBounds: { [key in 0 | 1 | 2 | 3]: [number, number][] } = {
  0: [
    [460, 375],
    [310, 375],
    [460, 25],
  ],
  1: [
    [-460, 375],
    [-310, 375],
    [-460, 25],
  ],
  2: [
    [-460, -25],
    [-460, -375],
    [-310, -375],
  ],
  3: [
    [460, -25],
    [460, -375],
    [310, -375],
  ],
};

const quartersData = [
  [Math.PI / 2, Math.PI],
  [Math.PI, 1.5 * Math.PI],
  [-Math.PI / 2, 0],
  [0, Math.PI / 2],
] as const;

export { quadrants, rings, quartersData, restOfTechnologiesBounds };
