import { ReactNode, useId } from 'react';

import Isvg from 'react-inlinesvg';

import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import Toggle, { ToggleProps } from 'components/Toggle/Toggle';

import styles from './ToggleButtonCard.module.scss';

interface ToggleButtonCardProps extends ToggleProps {
  icon?: string;
  label: ReactNode;
  tooltip?: ReactNode;
}

const ToggleButtonCard = ({ icon, label, tooltip, ...props }: ToggleButtonCardProps) => {
  const id = useId();
  const labelId = `toggle-label-${id}`;

  return (
    <div className={styles['toggle-button-card']}>
      <div className={styles['toggle-button-card__description']}>
        {icon && (
          <Isvg
            src={icon}
            className={styles['toggle-button-card__description__icon']}
            aria-hidden
          />
        )}

        <div id={labelId} className={styles['toggle-button-card__description__label']}>
          {label}
        </div>

        {tooltip && (
          <div className={styles['toggle-button-card__description__tooltip']}>
            <InfoTooltip tooltipText={tooltip} />
          </div>
        )}
      </div>

      <Toggle {...props} aria-labelledby={labelId} />
    </div>
  );
};

export default ToggleButtonCard;
