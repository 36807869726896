import { FC, createContext } from 'react';

import useContextWithCheck from 'contexts/useContextWithCheck';
import useMemoProps from 'contexts/useMemoProps';
import constants from 'shared/constants';
import { noop } from 'shared/helpers/noop';
import { ChildrenProp } from 'types';

import { Interactions, SetInteractions, SizeNodesBy } from './Interactions.type';

// Interactions
const InteractionsContext = createContext<Interactions>({
  highlightedTechnologyId: '',
  highlightedQuarter: '',
  sizeNodesBy: constants.DEFAULT_SIZE_NODES_BY,
  hoveredSearchResultNodeId: null,
});
export const useInteractions = () =>
  useContextWithCheck(InteractionsContext, 'InteractionsContext');

// SetInteractions
const defaultSetInteractions = {
  setHighlightedTechnologyId: noop,
  setHighlightedQuarter: noop,
  setSizeNodesBy: (type: SizeNodesBy) => {},
  setHoveredSearchResultNodeId: noop,
};
const SetInteractionsContext = createContext<SetInteractions>(defaultSetInteractions);
export const useSetInteractions = () =>
  useContextWithCheck(SetInteractionsContext, 'SetInteractionsContext');

export interface InteractionsProviderProps extends ChildrenProp, Interactions, SetInteractions {}

// Provider
const InteractionsProvider: FC<InteractionsProviderProps> = ({
  children,
  highlightedTechnologyId,
  setHighlightedTechnologyId,
  highlightedQuarter,
  setHighlightedQuarter,
  sizeNodesBy,
  setSizeNodesBy,
  hoveredSearchResultNodeId,
  setHoveredSearchResultNodeId,
}) => {
  const getters = useMemoProps({
    highlightedTechnologyId,
    highlightedQuarter,
    sizeNodesBy,
    hoveredSearchResultNodeId,
  });

  const setters = useMemoProps({
    setHighlightedTechnologyId,
    setHighlightedQuarter,
    setSizeNodesBy,
    setHoveredSearchResultNodeId,
  });

  return (
    <InteractionsContext.Provider value={getters}>
      <SetInteractionsContext.Provider value={setters}>{children}</SetInteractionsContext.Provider>
    </InteractionsContext.Provider>
  );
};

export default InteractionsProvider;
