import { select } from 'd3-selection';

import config from 'core/radarConfig';
import { rings } from 'core/radarParams';
import { animation } from 'core/radarUtils';

export default () => {
  const ringsWrapper = select('svg .rings');

  for (let i = rings.length - 1; i >= 0; i -= 1) {
    ringsWrapper
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', rings[i].radius)
      .style('fill', 'none')
      .style('stroke', config.colors.grid)
      .style('stroke-width', 1)
      .style('opacity', 0)
      .transition(animation(500))
      .delay(2000)
      .style('opacity', 1)
      // @ts-ignore FIXME
      .transition(animation(1200))
      .style('fill', `url(#gradient${i})`);

    ringsWrapper
      .append('text')
      .text(config.rings[i].name)
      .attr('class', 'ringTitle')
      .attr('y', -rings[i].radius + 50)
      .attr('text-anchor', 'middle')
      .style('opacity', 0)
      .transition(animation(1500))
      .delay(2000)
      .style('opacity', 1);
  }
};
