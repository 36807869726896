import { ReactNode } from 'react';

import cn from 'classnames';

import styles from './SmallCardWrapper.module.scss';

export type SmallCardWrapperProps = {
  label: ReactNode;
  children: ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
};

const SmallCardWrapper = ({ label, children, disabled, fullWidth }: SmallCardWrapperProps) => {
  return (
    <label
      className={cn(styles.labelContent, {
        [styles['labelContent--disabled']]: disabled,
        [styles['labelContent--fullWidth']]: fullWidth,
      })}
    >
      <span
        className={cn(styles.labelText, {
          [styles['labelText--disabled']]: disabled,
        })}
      >
        {label}
      </span>
      {children}
    </label>
  );
};

export default SmallCardWrapper;
