import { BlogPost } from 'types/technology.types';

import styles from './ArticlesList.module.scss';
import ArticlesListItem from './ArticlesListItem/ArticlesListItem';

interface ArticlesListProps {
  articles: BlogPost[];
}

const ArticlesList = ({ articles }: ArticlesListProps) => {
  return (
    <ul className={styles.articlesList}>
      {articles.map(article => (
        <ArticlesListItem key={article.url} article={article} />
      ))}
    </ul>
  );
};

export default ArticlesList;
