import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { useAuth } from 'contexts/Auth/Auth.context';
import { Talents, TalentStatuses } from 'types/talents.types';

interface UseTalentsQueryParams {
  technologyId: string;
  unitId?: string;
}

// TODO: Connect react-query when BE is ready
// JIRA: https://netguru.atlassian.net/browse/KVR-480
// Potential url:
//    .../technologies/:technologyId/talents
//    .../technologies/:technologyId/talents?department=:departmentId

const getRandomizedTalents = () => ({
  [TalentStatuses.Total]: {
    sum: _.random(0, 500),
    favorite: _.random(0, 100),
    bySkillRating: {
      1: _.random(0, 100),
      2: _.random(0, 100),
      3: _.random(0, 100),
      4: _.random(0, 100),
      5: _.random(0, 100),
    },
  },
  [TalentStatuses.InProject]: {
    sum: _.random(0, 500),
    favorite: _.random(0, 100),
    bySkillRating: {
      1: _.random(0, 100),
      2: _.random(0, 100),
      3: _.random(0, 100),
      4: _.random(0, 100),
      5: _.random(0, 100),
    },
  },
  [TalentStatuses.OnBench]: {
    sum: _.random(0, 500),
    favorite: _.random(0, 100),
    bySkillRating: {
      1: _.random(0, 100),
      2: _.random(0, 100),
      3: _.random(0, 100),
      4: _.random(0, 100),
      5: _.random(0, 100),
    },
  },
});

const useTalentsQuery = (params: UseTalentsQueryParams) => {
  const { user } = useAuth();
  const accessToken = user.getToken();

  return useQuery<Talents>({
    queryKey: ['talents', params, accessToken],
    queryFn: ({ signal }) => {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(getRandomizedTalents());
        }, 1000);
      });
    },
  });
};

export default useTalentsQuery;
