import { SizeNodesBy } from 'contexts/Interactions/Interactions.type';
import { InitializedNodeObject } from 'types/graph.types';

import { drawNodeShape } from './drawNodeShape';

export default function makeNodePointerAreaPaint(
  node: InitializedNodeObject,
  color: string,
  ctx: CanvasRenderingContext2D,
  sizeBy: SizeNodesBy = SizeNodesBy.PROJECTS
) {
  ctx.fillStyle = color;

  drawNodeShape(node, ctx, sizeBy, true);

  ctx.fill();
}
