import { VStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import InlineSVG from 'react-inlinesvg';

import cnst from 'shared/constants';
import List from 'views/List/List';

import useSearchedTechnologies from './hooks/useSearchedTechnologies';
import styles from './SearchResults.module.scss';
import SearchResultsWrapper from './SearchResultsWrapper/SearchResultsWrapper';

const SearchResults = () => {
  const { searchedTechnologiesByStage } = useSearchedTechnologies();

  if (isEmpty(searchedTechnologiesByStage)) {
    return (
      <SearchResultsWrapper>
        <span className={styles.resultsEmpty}>No skills match your search.</span>
      </SearchResultsWrapper>
    );
  }

  return (
    <SearchResultsWrapper>
      <VStack spacing="32px" align="stretch">
        {cnst.SUPPORTED_STAGES.map(stage => {
          const items = searchedTechnologiesByStage[stage];

          return (
            items && (
              <div key={stage}>
                <h2 className={styles.listHeader}>
                  <InlineSVG
                    src={cnst.SIMLIFIED_SUPPORTED_STAGES_ICONS[stage]}
                    className={styles.headerIcon}
                    aria-hidden
                  />
                  <span>{`${stage} (${items.length})`}</span>
                </h2>

                <List items={items} />
              </div>
            )
          );
        })}
      </VStack>
    </SearchResultsWrapper>
  );
};

export default SearchResults;
