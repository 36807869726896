import { useCallback, useEffect, useMemo, useState } from 'react';

import { CustomUserClaims, UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

const useOktaApi = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const [userInfo, setUserInfo] = useState<UserClaims<CustomUserClaims> | null>(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then(info => {
          setUserInfo(info);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const user = useMemo(
    () => ({
      getName: () => userInfo?.name ?? '',
      getEmail: () => userInfo?.email ?? '',
      getToken: () => oktaAuth.getAccessToken() ?? '',
    }),
    [oktaAuth, userInfo?.email, userInfo?.name]
  );

  const signIn = useCallback(() => {
    oktaAuth.setOriginalUri(window.location.href);
    oktaAuth.token.getWithRedirect();
  }, [oktaAuth]);

  const signOut = useCallback(() => {
    oktaAuth.signOut();
  }, [oktaAuth]);

  return { signIn, signOut, user, isLoggedIn: Boolean(userInfo) };
};

export default useOktaApi;
