import { ReactNode, useId, useState } from 'react';

import { motion, Variants } from 'framer-motion';
import SimpleBarReact from 'simplebar-react';

import CollapseButton from 'components/CollapseButton/CollapseButton';

import styles from './SidePanel.module.scss';

enum SidePanelVisibility {
  OPEN = 'open',
  CLOSED = 'closed',
}

const sidePanelVisibilityVariants: Variants = {
  [SidePanelVisibility.OPEN]: {
    x: 0,
  },
  [SidePanelVisibility.CLOSED]: {
    x: 'calc(-100% + 20px)', // Leaves CollapseButton and part of SidePanel visible
  },
};

interface SidePanelProp {
  children: ReactNode;
}

const SidePanel = ({ children }: SidePanelProp) => {
  const panelId = useId();

  const [visibilityState, setVisibilityState] = useState<SidePanelVisibility>(
    SidePanelVisibility.OPEN
  );

  const isExpanded = visibilityState === SidePanelVisibility.OPEN;

  const handleHideButtonClick = () =>
    isExpanded
      ? setVisibilityState(SidePanelVisibility.CLOSED)
      : setVisibilityState(SidePanelVisibility.OPEN);

  return (
    <motion.aside
      className={styles.sidePanel}
      initial={SidePanelVisibility.OPEN}
      animate={visibilityState}
      variants={sidePanelVisibilityVariants}
      id={panelId}
    >
      <div className={styles['sidePanel-collapseButton']}>
        <CollapseButton
          isExpanded={isExpanded}
          onClick={handleHideButtonClick}
          aria-controls={panelId}
        />
      </div>
      <SimpleBarReact style={{ height: '100vh' }}>{children}</SimpleBarReact>
    </motion.aside>
  );
};

export default SidePanel;
