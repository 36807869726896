import { select } from 'd3-selection';

import config from 'core/radarConfig';
import { rings } from 'core/radarParams';
import { animation } from 'core/radarUtils';

export default () => {
  select('svg .container').classed('enterAnimationComplete', false);

  const container = select('svg .enterAnimation');
  let delay = 200;
  let duration = 2000;

  for (let i = rings.length - 1; i >= 0; i -= 1) {
    container
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 0)
      .style('fill', 'none')
      .style('stroke', config.colors.grid)
      .style('stroke-width', 1)
      .transition(animation(duration))
      .delay(delay)
      .attr('r', rings[i].radius)
      .remove();

    delay += 200;
    duration -= 200;
  }
};
