import { NodeDatum, NodeHighlightOptions } from 'types/graph.types';

import getCssColorPropertyValue from './getCssPropertyValue';

const determineNodeColors = (
  node: NodeDatum,
  highlightOptions: NodeHighlightOptions | null
): { nodeColor: string; shadeValue: number } => {
  // default color for every node
  if (!highlightOptions) {
    return {
      nodeColor: node.color,
      shadeValue: 0,
    };
  }

  // highlight color for highlighted nodes...
  if (highlightOptions.nodesIds.has(node.id)) {
    return {
      nodeColor:
        highlightOptions.highlightColor === 'accent'
          ? getCssColorPropertyValue('--accent-cta')
          : node.color,
      shadeValue: 0,
    };
  }

  // ...and dimmed for not highlighted
  return {
    nodeColor: node.color,
    shadeValue: -70,
  };
};

export default determineNodeColors;
