import { useTechnology } from 'contexts/Technologies/Technologies.context';
import TechnologyDetailsResources from 'views/TechnologyDetails/TechnologyDetailsResources/TechnologyDetailsResources';

const TechnologyDetailsArticles = () => {
  const technology = useTechnology();

  if (!technology) return null;

  return <TechnologyDetailsResources technology={technology} />;
};

export default TechnologyDetailsArticles;
