import { FC, useState } from 'react';

import { SizeNodesBy } from 'contexts/Interactions/Interactions.type';
import cnst from 'shared/constants';
import { ChildrenProp } from 'types';

import InteractionsProvider from './Interactions.context';

const InteractionsWrapper: FC<ChildrenProp> = ({ children }) => {
  const [highlightedTechnologyId, setHighlightedTechnologyId] = useState<string | null>('');
  const [highlightedQuarter, setHighlightedQuarter] = useState<string | null>(null);
  const [sizeNodesBy, setSizeNodesBy] = useState<SizeNodesBy>(cnst.DEFAULT_SIZE_NODES_BY);
  const [hoveredSearchResultNodeId, setHoveredSearchResultNodeId] = useState<string | null>(null);

  return (
    <InteractionsProvider
      highlightedTechnologyId={highlightedTechnologyId}
      setHighlightedTechnologyId={setHighlightedTechnologyId}
      highlightedQuarter={highlightedQuarter}
      setHighlightedQuarter={setHighlightedQuarter}
      sizeNodesBy={sizeNodesBy}
      setSizeNodesBy={setSizeNodesBy}
      hoveredSearchResultNodeId={hoveredSearchResultNodeId}
      setHoveredSearchResultNodeId={setHoveredSearchResultNodeId}
    >
      {children}
    </InteractionsProvider>
  );
};

export default InteractionsWrapper;
