import { useCallback } from 'react';

import { useAuth } from 'contexts/Auth/Auth.context';
import { TechnologiesApi, TechnologyDetailsApi } from 'types/technology.types';

type ApiReturnType<T> = () => Promise<{ data: T }>;

type ApiReturnWithIdType<T> = (id: string) => Promise<{ data: T }>;

interface ApiFunctionsType {
  getTechnology: ApiReturnWithIdType<TechnologyDetailsApi>;
  getAllTechnologies: ApiReturnType<TechnologiesApi>;
}

const apiURL = process.env.REACT_APP_API_URL;
const REST_API_ENDPOINTS = {
  endpointTechnology: '/technologies/',
};

const useApiCalls = (): ApiFunctionsType => {
  const { user } = useAuth();
  const accessToken = user.getToken();

  // error handling is in components
  const get = useCallback(
    async (url: string) => {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error(response.statusText);
      const data = await response.json();

      return { data };
    },
    [accessToken]
  );

  const getAllTechnologies = useCallback(
    () => get(`${apiURL}${REST_API_ENDPOINTS.endpointTechnology}`),
    [get]
  );

  const getTechnology = useCallback(
    (param: string) => get(`${apiURL}${REST_API_ENDPOINTS.endpointTechnology}${param}/`),
    [get]
  );

  return {
    getAllTechnologies,
    getTechnology,
  };
};

export default useApiCalls;
