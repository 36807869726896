import { BaseType, Selection } from 'd3-selection';

import { translate } from 'core/radarUtils';
import { Segment } from 'types/radar.types';

export default <
  GElement extends BaseType,
  Datum extends { x: number; y: number; segment: Segment },
  PElement extends BaseType,
  PDatum
>(
  node: Selection<GElement, Datum, PElement, PDatum>
) => node.attr('transform', d => translate(d.segment.clipx(d), d.segment.clipy(d)));
