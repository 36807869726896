import { FC } from 'react';

import cn from 'classnames';

import { ChildrenProp } from 'types';

import styles from './InputAdditions.module.scss';

interface InputIconProps extends ChildrenProp {
  className?: string;
}

const InputIcon: FC<InputIconProps> = ({ className, children }) => {
  return (
    <span className={cn(styles['input-addition'], className)} role="presentation">
      {children}
    </span>
  );
};

export default InputIcon;
