import cnst from 'shared/constants';
import { STAGES_ENUM } from 'types/technology.types';

const getRingForStage = (stage: STAGES_ENUM) =>
  ({
    [STAGES_ENUM.IN_USE]: 0,
    [STAGES_ENUM.BET]: 1,
    [STAGES_ENUM.EXPERIMENT]: 2,
    [STAGES_ENUM.WATCH]: 3,
    [STAGES_ENUM.SUNSETTING]: 4,
    [STAGES_ENUM.DEAD]: 4,
  }[stage]);

const getQuadrantForSection = (section: string) =>
  ({
    [cnst.QUADRANT.LANGUAGES_FRAMEWORKS]: 0,
    [cnst.QUADRANT.PRODUCTS_TOOLS]: 1,
    [cnst.QUADRANT.AREAS_METHODS]: 2,
    [cnst.QUADRANT.PLATFORMS]: 3,
  }[section]);

const getSectionForQuadrant = (quadrant: number) =>
  ({
    0: cnst.QUADRANT.LANGUAGES_FRAMEWORKS,
    1: cnst.QUADRANT.PRODUCTS_TOOLS,
    2: cnst.QUADRANT.AREAS_METHODS,
    3: cnst.QUADRANT.PLATFORMS,
  }[quadrant] || null);

export { getRingForStage, getQuadrantForSection, getSectionForQuadrant };
