import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

export const oktaDomain = process.env.REACT_APP_OKTA_DOMAIN;

const oktaAuthOptions: OktaAuthOptions = {
  issuer: `https://${oktaDomain}/`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  scopes: ['openid', 'profile', 'email'],
  redirectUri: process.env.REACT_APP_OKTA_LOGIN_REDIRECT_URI,
  postLogoutRedirectUri: process.env.REACT_APP_OKTA_LOGOUT_REDIRECT_URI,
};

const authClient = new OktaAuth(oktaAuthOptions);

export default authClient;
