import { select } from 'd3-selection';

import colors from 'shared/styles/_colors.module.scss';
import typography from 'shared/styles/_typography.module.scss';

export default () => {
  const radar = select('.container');
  const bubble = radar
    .append('g')
    .attr('class', 'bubble')
    .attr('x', 0)
    .attr('y', 0)
    .style('opacity', 0)
    .style('pointer-events', 'none')
    .style('user-select', 'none');

  bubble.append('rect').attr('rx', 4).attr('ry', 4).style('fill', colors.navy500);

  bubble
    .append('text')
    .style('font-family', typography.ffamily)
    .style('font-size', typography.fs14)
    .style('font-weight', typography.fwSemiBold)
    .style('fill', colors.navy50);
};
