import { ChangeEvent, InputHTMLAttributes, useId } from 'react';

import cn from 'classnames';

import styles from './Toggle.module.scss';

export type ToggleProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange?: (checked: boolean) => void;
  /**
   * Whether component should be rendered as a label and be clickable.
   * Set this to false if you want to wrap this component with another label element.
   * @default true
   */
  standalone?: boolean;
};

const Toggle = ({ checked, onChange, standalone = true, ...props }: ToggleProps) => {
  const id = useId();

  const handleChange = onChange
    ? (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
      }
    : undefined;

  const Component = standalone ? 'label' : 'div';

  return (
    <Component className={cn(styles.toggle, { [styles['toggle--disabled']]: props.disabled })}>
      <input
        // `key` prop needed to force rerender of input on `checked` change.
        // For more info check KVR-402 & PR#298
        key={`toggle-${id}-${checked}`}
        className={styles.input}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        {...props}
      />
      <span aria-hidden className={styles.track} />
    </Component>
  );
};

export default Toggle;
