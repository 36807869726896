import { HStack } from '@chakra-ui/react';
import Isvg from 'react-inlinesvg';

import profileIcn from 'assets/svg/profile.svg';
import Button from 'components/Button/Button';
import Tooltip from 'components/Tooltip/Tooltip';
import { useAuth } from 'contexts/Auth/Auth.context';

import styles from './AuthView.module.scss';

const AuthView = () => {
  const { signIn, signOut, isLoggedIn, user } = useAuth();

  return (
    <HStack className={styles.wrapper} spacing="15px">
      <div className={styles.loginLabel}>
        {!isLoggedIn ? 'Joining from Netguru?' : `Hello, ${user.getName()}!`}
      </div>

      {isLoggedIn && (
        <Tooltip content={user.getEmail()} placement="bottom">
          <Button aria-label="Profile" variant="secondary" leftIcon={<Isvg src={profileIcn} />} />
        </Tooltip>
      )}

      <Button onClick={isLoggedIn ? signOut : signIn} variant="secondary">
        <span className={styles.loginButtonLabel}>{isLoggedIn ? 'Sign out' : 'Sign in'}</span>
      </Button>
    </HStack>
  );
};

export default AuthView;
