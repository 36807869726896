export enum SizeNodesBy {
  TALENTS = 'talents',
  LEADS = 'leads',
  OPPS = 'opps',
  PROJECTS = 'projects',
}

export interface Interactions {
  highlightedTechnologyId: string | null;
  highlightedQuarter: string | null;
  sizeNodesBy: SizeNodesBy;
  hoveredSearchResultNodeId: string | null;
}

type noop = (id: string | null) => void;
export interface SetInteractions {
  setHighlightedTechnologyId: noop;
  setHighlightedQuarter: noop;
  setSizeNodesBy: (type: SizeNodesBy) => void;
  setHoveredSearchResultNodeId: noop;
}
