import { ButtonHTMLAttributes, forwardRef } from 'react';

import cn from 'classnames';

import styles from './InputAdditions.module.scss';

const InputButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
  function InputButton({ className, ...props }, ref) {
    return (
      <button
        ref={ref}
        type="button"
        className={cn(styles['input-addition'], styles['input-addition--button'], className)}
        {...props}
      />
    );
  }
);

export default InputButton;
