import { FC } from 'react';

import { HStack } from '@chakra-ui/react';
import { ForceGraphMethods } from 'react-force-graph-2d';
import Isvg from 'react-inlinesvg';

import plusIcon from 'assets/svg/add.svg';
import minusIcon from 'assets/svg/remove.svg';
import Button from 'components/Button/Button';
import cnst from 'shared/constants';
import { ZoomValues } from 'types/graph.types';

import styles from './NetworkBottom.module.scss';

interface NetworkBottomProps {
  forceGraphRef: React.MutableRefObject<ForceGraphMethods | undefined>;
  currentZoom?: ZoomValues;
}

enum ZOOM_TYPE {
  ZOOM_IN = 'zoomIn',
  ZOOM_OUT = 'zoomOut',
  CENTER = 'center',
}

const NetworkBottom: FC<NetworkBottomProps> = ({ forceGraphRef, currentZoom }) => {
  const isMaxZoom = currentZoom?.k === cnst.GRAPH_SETTINGS.MAX_ZOOM;
  const isMinZoom = currentZoom?.k === cnst.GRAPH_SETTINGS.MIN_ZOOM;

  const handleButtonClick = (type: ZOOM_TYPE) => {
    if (!forceGraphRef.current || !currentZoom) return;

    switch (type) {
      case ZOOM_TYPE.ZOOM_IN:
        forceGraphRef.current.zoom(currentZoom.k * 1.25, 150);
        break;
      case ZOOM_TYPE.ZOOM_OUT:
        forceGraphRef.current.zoom(currentZoom.k * 0.75, 150);
        break;
      case ZOOM_TYPE.CENTER:
      default:
        forceGraphRef.current.zoomToFit(
          cnst.GRAPH_SETTINGS.ZOOM_DURATION,
          cnst.GRAPH_SETTINGS.ZOOM_PADDING
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <HStack className={styles.buttons} spacing="12px">
        <Button
          aria-label="Zoom out"
          variant="secondary"
          className={isMinZoom ? styles.buttonHidden : styles.buttonVisible}
          onClick={() => handleButtonClick(ZOOM_TYPE.ZOOM_OUT)}
          leftIcon={<Isvg src={minusIcon} />}
        />

        <Button variant="secondary" onClick={() => handleButtonClick(ZOOM_TYPE.CENTER)}>
          Center
        </Button>

        <Button
          aria-label="Zoom in"
          variant="secondary"
          className={isMaxZoom ? styles.buttonHidden : styles.buttonVisible}
          onClick={() => handleButtonClick(ZOOM_TYPE.ZOOM_IN)}
          leftIcon={<Isvg src={plusIcon} />}
        />
      </HStack>
    </div>
  );
};

export default NetworkBottom;
