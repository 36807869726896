import { useEffect } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isMobile } from 'react-device-detect';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import InteractionsReset from 'components/InteractionReset/InteractionsReset';
import MobileDeviceFallback from 'components/MobileDeviceFallback/MobileDeviceFallback';
import AuthWrapper from 'contexts/Auth/AuthWrapper';
import LoginCallback from 'contexts/Auth/LoginCallback';
import ContextProviders from 'contexts/ContextProviders';
import DetailsScreen from 'screens/DetailsScreen/DetailsScreen';
import NetworkScreen from 'screens/NetworkScreen/NetworkScreen';
import RadarScreen from 'screens/RadarScreen/RadarScreen';
import WelcomeScene from 'screens/WelcomeScene/WelcomeScene';
import { FeatureFlag, isFlagEnabled } from 'shared/helpers/featureFlags';
import useWhichScreenView from 'shared/hooks/useWhichScreenView';
import APP_ROUTES, { TECH_DETAILS_SUB_PATHS } from 'shared/routes';
import InfoModal from 'views/InfoModal/InfoModal';
import TechnologiesScreensWrapper from 'views/TechnologiesScreensWrapper/TechnologiesScreensWrapper';
import TechnologyDetailsArticles from 'views/TechnologyDetails/views/TechnologyDetailsArticles/TechnologyDetailsArticles';
import TechnologyDetailsOverview from 'views/TechnologyDetails/views/TechnologyDetailsOverview/TechnologyDetailsOverview';
import TechnologyDetailsRelated from 'views/TechnologyDetails/views/TechnologyDetailsRelated/TechnologyDetailsRelated';
import TechnologyDetailsResources from 'views/TechnologyDetails/views/TechnologyDetailsResources/TechnologyDetailsResources';
import TechnologyDetailsTalents from 'views/TechnologyDetails/views/TechnologyDetailsTalents/TechnologyDetailsTalents';

declare global {
  interface Window {
    dataLayer: { event: string; screenName: string }[];
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const TechnologiesRepresentation = () => {
  const { isWelcome } = useWhichScreenView();

  if (isMobile) return <MobileDeviceFallback />;
  if (isWelcome) return <WelcomeScene />;

  return (
    <TechnologiesScreensWrapper>
      <Outlet />
    </TechnologiesScreensWrapper>
  );
};

const App = () => {
  const { pathname } = window.location;
  const fireTracking = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'ScreenView', screenName: pathname });
  };

  useEffect(fireTracking, [pathname]);

  return (
    <AuthWrapper>
      <QueryClientProvider client={queryClient}>
        <ContextProviders>
          <InteractionsReset />
          <InfoModal />
          <Routes>
            <Route path="/" element={<TechnologiesRepresentation />}>
              <Route path={APP_ROUTES.RADAR} element={<RadarScreen />} />
              <Route path={`${APP_ROUTES.RADAR}/:technologyId/*`} element={<RadarScreen />}>
                <Route
                  path={TECH_DETAILS_SUB_PATHS.OVERVIEW}
                  element={<TechnologyDetailsOverview />}
                />
                {isFlagEnabled(FeatureFlag.SidePanelTalents) ? (
                  <Route
                    path={TECH_DETAILS_SUB_PATHS.TALENTS}
                    element={<TechnologyDetailsTalents />}
                  />
                ) : null}
                <Route
                  path={TECH_DETAILS_SUB_PATHS.RESOURCES}
                  element={<TechnologyDetailsResources />}
                />
                <Route
                  path={TECH_DETAILS_SUB_PATHS.RELATED}
                  element={<TechnologyDetailsRelated />}
                />
                <Route
                  path="*"
                  element={<Navigate to={TECH_DETAILS_SUB_PATHS.OVERVIEW} replace />}
                />
              </Route>

              <Route path={APP_ROUTES.GRAPH} element={<NetworkScreen />} />
              <Route path={`${APP_ROUTES.GRAPH}/:technologyId/*`} element={<DetailsScreen />}>
                <Route
                  path={TECH_DETAILS_SUB_PATHS.OVERVIEW}
                  element={<TechnologyDetailsOverview />}
                />
                {isFlagEnabled(FeatureFlag.SidePanelTalents) ? (
                  <Route
                    path={TECH_DETAILS_SUB_PATHS.TALENTS}
                    element={<TechnologyDetailsTalents />}
                  />
                ) : null}
                {isFlagEnabled(FeatureFlag.SidePanelResources) ? (
                  <Route
                    path={TECH_DETAILS_SUB_PATHS.RESOURCES}
                    element={<TechnologyDetailsResources />}
                  />
                ) : (
                  <Route
                    path={TECH_DETAILS_SUB_PATHS.ARTICLES}
                    element={<TechnologyDetailsArticles />}
                  />
                )}

                <Route
                  path={TECH_DETAILS_SUB_PATHS.RELATED}
                  element={<TechnologyDetailsRelated />}
                />
                <Route
                  path="*"
                  element={<Navigate to={TECH_DETAILS_SUB_PATHS.OVERVIEW} replace />}
                />
              </Route>

              <Route path={APP_ROUTES.LOGIN_CALLBACK} element={<LoginCallback />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <ReactQueryDevtools initialIsOpen={false} />
        </ContextProviders>
      </QueryClientProvider>
    </AuthWrapper>
  );
};

export default App;
