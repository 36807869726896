import { FC } from 'react';

import { isRedirectUri, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';

import { ChildrenProp } from 'types';

import AuthProvider from './Auth.context';
import authClient from './AuthClient';
import useOktaApi from './hooks/useOktaApi';

const OktaAuthWrapper: FC<ChildrenProp> = ({ children }) => {
  const { user, signIn, signOut, isLoggedIn } = useOktaApi();

  return (
    <AuthProvider user={user} signIn={signIn} signOut={signOut} isLoggedIn={isLoggedIn}>
      {children}
    </AuthProvider>
  );
};

const SecurityWrapper: FC<ChildrenProp> = ({ children }) => {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    if (!isRedirectUri(window.location.href, authClient)) {
      return;
    }

    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };

  return (
    <Security oktaAuth={authClient} restoreOriginalUri={restoreOriginalUri}>
      <OktaAuthWrapper>{children}</OktaAuthWrapper>
    </Security>
  );
};

export default SecurityWrapper;
