import { ChangeEvent, InputHTMLAttributes, useId } from 'react';

import styles from './Radio.module.scss';

export type RadioProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange?: (checked: boolean) => void;
  /**
   * Whether component should be rendered as a label and be clickable.
   * Set this to false if you want to wrap this component with another label element.
   * @default true
   */
  standalone?: boolean;
};

const Radio = ({ checked, onChange, standalone = true, ...props }: RadioProps) => {
  const id = useId();

  const handleChange = onChange
    ? (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
      }
    : undefined;

  const Component = standalone ? 'label' : 'div';

  return (
    <Component className={styles.wrapper}>
      <input
        // Rerenders on checked prop change. Allows to skip unneccessary useEffect
        key={`radio-${id}-${checked}`}
        type="radio"
        onChange={handleChange}
        checked={checked}
        className={styles.radio}
        {...props}
      />
    </Component>
  );
};

export default Radio;
