import { useMemo } from 'react';

import escapeStringRegexp from 'escape-string-regexp';
import { groupBy, pickBy } from 'lodash';

import { useInteractions } from 'contexts/Interactions/Interactions.context';
import { useFilterTechnologies, useTechnologies } from 'contexts/Technologies/Technologies.context';
import { TechnologiesByStage, Technologies, Technology } from 'types/technology.types';

const useSearchedTechnologies = (): {
  searchedTechnologies: Technologies;
  searchedTechnologiesByStage: TechnologiesByStage;
} => {
  const { highlightedQuarter } = useInteractions();
  const { searchPhrase } = useFilterTechnologies();

  const technologies = useTechnologies();

  const searchedTechnologies = useMemo(() => {
    if (searchPhrase === '' && highlightedQuarter === null) {
      return technologies || {};
    }

    const matchPhase = (phrase: string, item: Technology) => item.label.toLowerCase().match(phrase);

    const matchItem = (item: Technology) =>
      (highlightedQuarter === null || item.section === highlightedQuarter) &&
      Boolean(matchPhase(escapeStringRegexp(searchPhrase.toLowerCase()), item));

    return pickBy(technologies, matchItem);
  }, [highlightedQuarter, searchPhrase, technologies]);

  return {
    searchedTechnologies,
    searchedTechnologiesByStage: groupBy(searchedTechnologies, item => item.stage),
  };
};

export default useSearchedTechnologies;
