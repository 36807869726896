import { SizeNodesBy } from 'contexts/Interactions/Interactions.type';
import { InitializedNodeObject, NodeHighlightOptions } from 'types/graph.types';

import colorNode from './colorHelpers';
import determineNodeColors from './determineNodeColors';
import { drawNodeShape, BASE_NODE_SIZE } from './drawNodeShape';
import getCssColorPropertyValue from './getCssPropertyValue';

function splitLines(ctx: CanvasRenderingContext2D, label: string, maxWidth: number) {
  const words = label.replaceAll('/', ' / ').split(' ');
  const lines: string[] = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i += 1) {
    const word = words[i];
    const { width } = ctx.measureText(`${currentLine} ${word}`);

    if (width < maxWidth) {
      currentLine += ` ${word}`;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }

  lines.push(currentLine);

  return lines;
}

function drawText(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  maxWidth: number,
  scale: number,
  label: string
) {
  const FONT_WEIGHT = 400;
  const MARGIN_FROM_NODE = 20;

  const lineHeight = 31 * scale;
  const fontSize = 25 * scale;
  const offsetFromNode = (BASE_NODE_SIZE / 2 + MARGIN_FROM_NODE) * scale;

  ctx.font = `${FONT_WEIGHT} ${fontSize}px AvertaStd`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'top';
  ctx.fillStyle = getCssColorPropertyValue('--neutral-text');

  const lines = splitLines(ctx, label, maxWidth);

  lines.forEach((line, index) => {
    ctx.fillText(line, x, y + index * lineHeight + offsetFromNode);
  });
}

export default function makeNodeCanvasObject(
  node: InitializedNodeObject,
  ctx: CanvasRenderingContext2D,
  globalScale: number,
  sizeBy: SizeNodesBy = SizeNodesBy.PROJECTS,
  highlightOptions: NodeHighlightOptions | null
) {
  const { label } = node;

  const { maxWidth, scale } = drawNodeShape(node, ctx, sizeBy);

  const { nodeColor, shadeValue } = determineNodeColors(node, highlightOptions);
  colorNode(ctx, scale, globalScale, nodeColor, shadeValue);

  // render text only if node is big enough
  // it's not needed otherwise because it would be too small to read
  if (maxWidth * globalScale > 30) {
    drawText(ctx, node.x, node.y, maxWidth, scale, label);
  }
}
