import { ReactNode } from 'react';

import { HStack } from '@chakra-ui/react';

import styles from './RatingCardBase.module.scss';

interface RatingCardBaseProps {
  ratingIcons: ReactNode;
  value: number;
}

const RatingCardBase = ({ ratingIcons, value }: RatingCardBaseProps) => {
  return (
    <div className={styles.card}>
      <HStack spacing="4px">{ratingIcons}</HStack>
      <span>{value || '-'}</span>
    </div>
  );
};

export default RatingCardBase;
