import styles from './SearchResultsLabel.module.scss';

interface SearchResultsLabelProps {
  itemCount: number;
}

const SearchResultsLabel = ({ itemCount }: SearchResultsLabelProps) => {
  return <p className={styles.searchResultsLabel}>Results found: {itemCount}</p>;
};

export default SearchResultsLabel;
