import { FC } from 'react';

import InteractionsWrapper from 'contexts/Interactions/InteractionsWrapper';
import type { ChildrenProp } from 'types';

import TechnologiesWrapper from './Technologies/TechnologiesWrapper';

const ContextProviders: FC<ChildrenProp> = ({ children }) => (
  <InteractionsWrapper>
    <TechnologiesWrapper>{children}</TechnologiesWrapper>
  </InteractionsWrapper>
);

export default ContextProviders;
