import { useState } from 'react';

import { TechnologyDetails } from 'types/technology.types';

const useTechnologyDataService = () => {
  const [technology, setTechnology] = useState<TechnologyDetails | null>(null);

  const resetTechnology = () => setTechnology(null);

  return {
    technology,
    setTechnology,
    resetTechnology,
  };
};

export default useTechnologyDataService;
