import betIcon from 'assets/svg/simplifiedStageIcons/bet_empty.svg';
import experimentIcon from 'assets/svg/simplifiedStageIcons/experiment_empty.svg';
import inUseIcon from 'assets/svg/simplifiedStageIcons/in_use_empty.svg';
import noStageIcon from 'assets/svg/simplifiedStageIcons/no_stage_empty.svg';
import watchIcon from 'assets/svg/simplifiedStageIcons/watch_empty.svg';
import { SizeNodesBy } from 'contexts/Interactions/Interactions.type';
import { STAGES_ENUM } from 'types/technology.types';

const constants = {
  URLS: {
    ALL_ARTICLES:
      'https://netguru.atlassian.net/wiki/spaces/RnD/pages/3038216399/R+D+Research+Library',
  },

  SLACK: {
    TEAM_ID: 'T0251EZGA',
    RND_CHANNEL_ID: 'C032GEXS91R',
  },

  GRAPH_SETTINGS: {
    MIN_ZOOM: 0.05,
    MAX_ZOOM: 3,
    ZOOM_PADDING: 90,
    ZOOM_DURATION: 400,
  },

  ESTIMATE_PROJECT_URL: 'https://www.netguru.com/estimate-project',

  SUPPORTED_STAGES: [
    STAGES_ENUM.IN_USE,
    STAGES_ENUM.BET,
    STAGES_ENUM.EXPERIMENT,
    STAGES_ENUM.WATCH,
    STAGES_ENUM.NO_STAGE,
  ],

  SIMLIFIED_SUPPORTED_STAGES_ICONS: {
    [STAGES_ENUM.IN_USE]: inUseIcon,
    [STAGES_ENUM.BET]: betIcon,
    [STAGES_ENUM.EXPERIMENT]: experimentIcon,
    [STAGES_ENUM.WATCH]: watchIcon,
    [STAGES_ENUM.NO_STAGE]: noStageIcon,
  },

  DEFAULT_SIZE_NODES_BY: SizeNodesBy.PROJECTS,

  QUADRANT: {
    AREAS_METHODS: 'Areas & Methods',
    PRODUCTS_TOOLS: 'Products & Tools',
    PLATFORMS: 'Platforms',
    LANGUAGES_FRAMEWORKS: 'Languages & Frameworks',
  },

  QUADRANT_DESCRIPTION: {
    AREAS_METHODS:
      'These include elements of a software development & consulting process and ways of structuring software.',
    PRODUCTS_TOOLS:
      'These can be components, software development tools, or more generic categories of tools.',
    PLATFORMS:
      'Environment in which a piece of software is executed. It may be the hardware, the operating system (OS), virtual platforms, or generic kinds of platforms.',
    LANGUAGES_FRAMEWORKS:
      'Programming languages and frameworks used in computer programming to implement algorithms.',
  },

  STAGE_FILTERS_DESCRIPTION: {
    [STAGES_ENUM.IN_USE]:
      'Our current stack. Entries we have high confidence in to serve our purpose and are widely used in production environment.',
    [STAGES_ENUM.BET]:
      "We see huge potential for the whole organisation. We're actively exploring ways to bring that area to life. Perfect candidates for RnD projects and Tech Bets.",
    [STAGES_ENUM.EXPERIMENT]:
      "BET candidates. We see potential, but it's a bit too early to commit on the organisation level. Good material for the internal working groups.",
    [STAGES_ENUM.WATCH]:
      "We think it's too early to commit to this stack. Its business potential has not been proven yet.",
  },

  FILTER_SKILLS_DESCRIPTION: {
    ACTIVE_PROJECTS: 'Show only skills with Active projects.',
    RESEARCH_INCLUDED: 'Show only skills that have internal research included.',
    TECH_BET:
      'Project in engagement between Netguru and our customer when we accept that we lack commercial experience in a given technology and we are willing to prove our capability by delivering the Proof of Concept (PoC).',
  },
};

export default constants;
