import { VStack } from '@chakra-ui/react';

import Accordion from 'components/Accordion/Accordion';
import RadioCardSmall from 'components/CardButton/RadioCardSmall/RadioCardSmall';
import { Combobox, Item } from 'components/Combobox';
import RadioGroup from 'components/RadioGroup/RadioGroup';
import AllStagesSkillsList from 'components/SidePanel/AllSkillList/AllStagesSkillsList';
import ToggleButtonCard from 'components/ToggleButtonCard/ToggleButtonCard';
import { useAuth } from 'contexts/Auth/Auth.context';
import { useInteractions, useSetInteractions } from 'contexts/Interactions/Interactions.context';
import { SizeNodesBy } from 'contexts/Interactions/Interactions.type';
import { FilterOptionsEnum } from 'contexts/Technologies/services/Filters.service';
import { useFiltersState } from 'contexts/Technologies/Technologies.context';
import cnst from 'shared/constants';
import { STAGES_ENUM } from 'types/technology.types';

import useFilterOptionsHandlers from './useFilterOptionsHandlers';

const { STAGE_FILTERS_DESCRIPTION, SIMLIFIED_SUPPORTED_STAGES_ICONS } = cnst;

const FilterOptionsSection = () => {
  const { isLoggedIn } = useAuth();
  const {
    activeProjects,
    researches,
    techBet,
    stageInUse,
    stageBet,
    stageExperiment,
    stageWatch,
    stageNoStage,
  } = useFiltersState();
  const {
    handleOnActiveProjectsChange,
    handleOnResearchIncludedChange,
    handleOnTechBetChange,
    handleOnInUseStageChange,
    handleOnBetStageChange,
    handleOnExperimentStageChange,
    handleOnWatchStageChange,
    handleNoStageStageChange,
  } = useFilterOptionsHandlers();
  const { sizeNodesBy } = useInteractions();
  const { setSizeNodesBy } = useSetInteractions();

  return (
    <VStack spacing="24px" align="stretch">
      {isLoggedIn && (
        <>
          <Accordion label="Size by" defaultExpanded>
            <Combobox
              aria-label="Size by"
              selectedKey={sizeNodesBy}
              onSelectionChange={key => {
                setSizeNodesBy(key as SizeNodesBy);
              }}
            >
              <Item key={SizeNodesBy.TALENTS}>Number of talents</Item>
              <Item key={SizeNodesBy.LEADS}>Number of leads</Item>
              <Item key={SizeNodesBy.OPPS}>Number of OPPs</Item>
              <Item key={SizeNodesBy.PROJECTS}>Number of projects</Item>
            </Combobox>
          </Accordion>

          <Accordion label="Filter skills" defaultExpanded>
            <RadioGroup groupLabel="Active projects">
              <RadioCardSmall
                value={FilterOptionsEnum.yes}
                label="Yes"
                checked={activeProjects === FilterOptionsEnum.yes}
                onChange={() => handleOnActiveProjectsChange(FilterOptionsEnum.yes)}
              />
              <RadioCardSmall
                value={FilterOptionsEnum.no}
                label="No"
                checked={activeProjects === FilterOptionsEnum.no}
                onChange={() => handleOnActiveProjectsChange(FilterOptionsEnum.no)}
              />
              <RadioCardSmall
                value={FilterOptionsEnum.all}
                label="All"
                checked={activeProjects === FilterOptionsEnum.all}
                onChange={() => handleOnActiveProjectsChange(FilterOptionsEnum.all)}
              />
            </RadioGroup>
            <RadioGroup groupLabel="Research Included">
              <RadioCardSmall
                value={FilterOptionsEnum.yes}
                label="Yes"
                checked={researches === FilterOptionsEnum.yes}
                onChange={() => handleOnResearchIncludedChange(FilterOptionsEnum.yes)}
              />
              <RadioCardSmall
                value={FilterOptionsEnum.no}
                label="No"
                checked={researches === FilterOptionsEnum.no}
                onChange={() => handleOnResearchIncludedChange(FilterOptionsEnum.no)}
              />
              <RadioCardSmall
                value={FilterOptionsEnum.all}
                label="All"
                checked={researches === FilterOptionsEnum.all}
                onChange={() => handleOnResearchIncludedChange(FilterOptionsEnum.all)}
              />
            </RadioGroup>
            <RadioGroup groupLabel="Tech Bet">
              <RadioCardSmall
                value={FilterOptionsEnum.yes}
                label="Yes"
                checked={techBet === FilterOptionsEnum.yes}
                onChange={() => handleOnTechBetChange(FilterOptionsEnum.yes)}
              />
              <RadioCardSmall
                value={FilterOptionsEnum.no}
                label="No"
                checked={techBet === FilterOptionsEnum.no}
                onChange={() => handleOnTechBetChange(FilterOptionsEnum.no)}
              />
              <RadioCardSmall
                value={FilterOptionsEnum.all}
                label="All"
                checked={techBet === FilterOptionsEnum.all}
                onChange={() => handleOnTechBetChange(FilterOptionsEnum.all)}
              />
            </RadioGroup>
          </Accordion>
        </>
      )}

      <Accordion label="Show stage" defaultExpanded>
        <VStack spacing="12px" align="stretch">
          <ToggleButtonCard
            label={STAGES_ENUM.IN_USE}
            tooltip={STAGE_FILTERS_DESCRIPTION[STAGES_ENUM.IN_USE]}
            onChange={handleOnInUseStageChange}
            checked={stageInUse}
            icon={SIMLIFIED_SUPPORTED_STAGES_ICONS[STAGES_ENUM.IN_USE]}
          />
          <ToggleButtonCard
            label={STAGES_ENUM.BET}
            tooltip={STAGE_FILTERS_DESCRIPTION[STAGES_ENUM.BET]}
            onChange={handleOnBetStageChange}
            checked={stageBet}
            icon={SIMLIFIED_SUPPORTED_STAGES_ICONS[STAGES_ENUM.BET]}
          />
          <ToggleButtonCard
            label={STAGES_ENUM.EXPERIMENT}
            tooltip={STAGE_FILTERS_DESCRIPTION[STAGES_ENUM.EXPERIMENT]}
            onChange={handleOnExperimentStageChange}
            checked={stageExperiment}
            icon={SIMLIFIED_SUPPORTED_STAGES_ICONS[STAGES_ENUM.EXPERIMENT]}
          />
          <ToggleButtonCard
            label={STAGES_ENUM.WATCH}
            tooltip={STAGE_FILTERS_DESCRIPTION[STAGES_ENUM.WATCH]}
            onChange={handleOnWatchStageChange}
            checked={stageWatch}
            icon={SIMLIFIED_SUPPORTED_STAGES_ICONS[STAGES_ENUM.WATCH]}
          />
          <ToggleButtonCard
            label={STAGES_ENUM.NO_STAGE}
            onChange={handleNoStageStageChange}
            checked={stageNoStage}
            icon={SIMLIFIED_SUPPORTED_STAGES_ICONS[STAGES_ENUM.NO_STAGE]}
          />
        </VStack>
      </Accordion>

      <Accordion label="All skills">
        <AllStagesSkillsList />
      </Accordion>
    </VStack>
  );
};

export default FilterOptionsSection;
