import { ChakraProvider } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Simplebar for custom scrollbar & override styles
import '../node_modules/simplebar/src/simplebar.css';
import 'shared/styles/simplebarOverride.scss';

import 'core/radar.scss';

import App from './App';
import './index.module.scss';
import theme from './shared/styles/theme';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <ChakraProvider resetCSS={false} theme={theme}>
      <App />
    </ChakraProvider>
  </BrowserRouter>
);
