import Accordion from 'components/Accordion/Accordion';
import ExternalLinkButton from 'components/ExternalLinkButton/ExternalLinkButton';
import { useTechnology } from 'contexts/Technologies/Technologies.context';

const TechnologyDetailsOpps = () => {
  const technology = useTechnology();

  const getAllOppsUrl = () => {
    if (!technology) return '';

    return `https://netguru.lightning.force.com/lightning/r/Report/00O5J000000pa0NUAQ/view?queryScope=userFolders&fv0=${encodeURIComponent(
      technology.name
    )}`;
  };

  return (
    <Accordion label="Opps" defaultExpanded>
      <ExternalLinkButton href={getAllOppsUrl()} fullWidth>
        See all opps
      </ExternalLinkButton>
    </Accordion>
  );
};

export default TechnologyDetailsOpps;
