export interface BlogPost {
  title: string;
  url: string;
  summary: string | null;
  tags?: string[];
}

interface RelatedTechnology {
  id: string;
  name: string;
  stage: Nullable<{ name: STAGES_ENUM }>;
}

export type TechWithStage = Pick<RelatedTechnology, 'stage'>;

export interface TechnologyDetailsApi {
  id: string;
  name: string;
  description: string;
  projects: TechnologyProject[];
  section: Nullable<{ name: string }>;
  stage: Nullable<{ name: STAGES_ENUM }>;
  history: {
    [date: string]: string;
  };
  related_technologies: RelatedTechnology[];
  blog_posts?: BlogPost[];
  tech_bet: boolean;
}

export interface TechnologyDetails {
  id: string;
  name: string;
  description: string;
  get_in_touch?: Nullable<string>;
  history: { [date: string]: string };
  marketing_collateral?: Nullable<string>;
  projects: TechnologyProject[];
  section: string | null;
  stage: STAGES_ENUM | null;
  url?: string;
  related_technologies: RelatedTechnology[];
  blog_posts?: BlogPost[];
  tech_bet: boolean;
}

export interface TechnologyProject {
  id: string;
  name: string;
  active: boolean;
  salesforce_url: string;
}

export interface TechnologiesApi {
  items: {
    stage: {
      name: STAGES_ENUM;
    } | null;
    parents: string[];
    section: {
      name: string;
    } | null;
    color: string;
    id: string;
    name: string;
    children: string[];
    tech_bet: boolean;
    has_active_projects?: boolean;
    node_size: {
      children: number;
      projects?: number;
      opps?: number;
      blog_posts?: number;
      leads?: number;
      talents?: number;
    };
  }[];
}

export enum STAGES_ENUM {
  IN_USE = 'In Use',
  BET = 'Bet',
  EXPERIMENT = 'Experiment',
  WATCH = 'Watch',
  SUNSETTING = 'Sunsetting',
  DEAD = 'Dead',
  NO_STAGE = 'No Stage',
}

export interface Technology {
  id: string;
  label: string;
  color: string;
  section: string;
  stage: STAGES_ENUM;
  parents: string[];
  numberOfChildren: number;
  numberOfProjects?: number;
  hasActiveProjects?: boolean;
  numberOfResearches?: number;
  numberOfOpps?: number;
  numberOfLeads?: number;
  numberOfTalents?: number;
  techBet: boolean;
  children: string[];
  isRadarTechnology?: boolean;
}

export type Technologies = Record<string, Technology>;

export type TechnologiesByStage = Record<string, Technology[]>;
