import _ from 'lodash';
import Isvg from 'react-inlinesvg';

import starFilled from 'assets/svg/star_filled.svg';
import starOutlined from 'assets/svg/star_outline.svg';
import { SkillRatingLevel } from 'types/talents.types';

import RatingCardBase from '../RatingCardBase/RatingCardBase';

export interface SkillRatingCardProps {
  skillLevel: SkillRatingLevel;
  numberOfTalents: number;
}

const SkillRatingCard = ({ skillLevel, numberOfTalents }: SkillRatingCardProps) => {
  const ratingIcons = _.times(5, index => {
    const isFilled = skillLevel >= index + 1;
    return <Isvg key={`rating-${index}`} src={isFilled ? starFilled : starOutlined} />;
  });

  return <RatingCardBase ratingIcons={ratingIcons} value={numberOfTalents} />;
};

export default SkillRatingCard;
