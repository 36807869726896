import { Children, cloneElement, ReactElement, ReactNode, useState } from 'react';

import {
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
  FloatingPortal,
  UseFloatingProps,
} from '@floating-ui/react-dom-interactions';
import { motion, AnimatePresence } from 'framer-motion';

import styles from './Tooltip.module.scss';

interface TooltipProps extends Pick<Partial<UseFloatingProps>, 'placement'> {
  content: ReactNode;
  children: ReactElement;
  offset?: Parameters<typeof offset>[0];
}

/**
 * Requires single child element that accepts `ref` attribute.
 */
const Tooltip = ({ content, children, placement, offset: offsetValue }: TooltipProps) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset(offsetValue || { mainAxis: 6 }), flip(), shift({ padding: 24 })],
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  const child = Children.only(children);

  return (
    <>
      {cloneElement(child, { ref: reference, ...getReferenceProps() })}

      <FloatingPortal>
        <AnimatePresence>
          {open && (
            <motion.div
              ref={floating}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
              {...getFloatingProps({ className: styles.tooltip })}
            >
              {content}
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  );
};

export default Tooltip;
