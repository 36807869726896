import { select } from 'd3-selection';

import { translate, animation, getParentNode } from 'core/radarUtils';
import { Callbacks } from 'core/types';
import { FixMe } from 'types';

import getHoveredBlip from './getHoveredBlip';
import toggleQuarter from './toggleQuarter';

export default (d: FixMe, selectedClass: string | null, callbacks?: Callbacks) => {
  const hoveredElement = getHoveredBlip(d);
  const hoveredElementParent = getParentNode(hoveredElement);

  // remove hover efect from selected technology on radar
  hoveredElementParent.select('.hoverEffect').remove();
  hoveredElementParent.select('.shape').transition(animation(250)).attr('fill-opacity', 0.6);

  if (selectedClass === 'restBlips') toggleQuarter('mouseout', d.quadrant + 1);

  select('.bubble').attr('transform', translate(0, 0)).style('opacity', 0);

  callbacks?.onNodeHighlighted(null);
};
