import { FC } from 'react';

import useMemoProps from 'contexts/useMemoProps';
import useWhichScreenView from 'shared/hooks/useWhichScreenView';
import { ChildrenProp } from 'types';

import useFiltersService from './hooks/useFiltersService';
import useSearchTechnologies from './hooks/useSearchTechnologies';
import useTechnologiesDataService from './hooks/useTechnologiesDataService';
import useTechnologyDataService from './hooks/useTechnologyDataService';
import TechnologiesProvider from './Technologies.context';

const TechnologiesWrapper: FC<ChildrenProp> = ({ children }) => {
  const { isRadar } = useWhichScreenView();

  // filters data service
  const { applyFilter, filtersState } = useFiltersService();

  // technologies data service
  const { radarTechnologies, filteredTechnologies, initTechnologiesToState, technologies } =
    useTechnologiesDataService({ filters: filtersState });

  // technology data service
  const { technology, setTechnology, resetTechnology } = useTechnologyDataService();

  const filterTechnologiesProps = useSearchTechnologies();
  const filterTechnologies = useMemoProps(filterTechnologiesProps);

  return (
    <TechnologiesProvider
      filteredTechnologies={filteredTechnologies}
      technologies={isRadar ? radarTechnologies : technologies}
      initTechnologiesToState={initTechnologiesToState}
      filterTechnologies={filterTechnologies}
      applyFilter={applyFilter}
      filtersState={filtersState}
      technology={technology}
      setTechnology={setTechnology}
      resetTechnology={resetTechnology}
    >
      {children}
    </TechnologiesProvider>
  );
};

export default TechnologiesWrapper;
