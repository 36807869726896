import styles from './InfoModal.module.scss';

// the logic is in `core`, we use it by ids
// ideally, it should be refactored and moved to the component
const InfoModal = () => (
  <div id="js-info-modal" className={styles['info-modal']} role="tooltip">
    <div id="js-info-modal-header" className={styles['info-modal__header']} />
    <div id="js-info-modal-body" className={styles['info-modal__body']} />
  </div>
);

export default InfoModal;
