import { Box } from '@chakra-ui/react';

import TechnologyDetailsRelatedTechnologies from 'views/TechnologyDetails/TechnologyDetailsRelatedTechnologies/TechnologyDetailsRelatedTechnologies';

const TechnologyDetailsRelated = () => {
  return (
    <Box mb="40px">
      <TechnologyDetailsRelatedTechnologies />
    </Box>
  );
};

export default TechnologyDetailsRelated;
