import { select } from 'd3-selection';
import { has } from 'lodash';

import { animation, getParentNode, getColor, getShape } from 'core/radarUtils';
import { Callbacks } from 'core/types';
import { FixMe } from 'types';

import getHoveredBlip from './getHoveredBlip';
import hideAura from './hideAura';
import highlightQuarter from './highlightQuarter';

export default (d: FixMe, callbacks?: Callbacks) => {
  const blipData = d;
  const hoveredElement = getHoveredBlip(blipData);
  const hoveredElementParent = getParentNode(hoveredElement);

  if (select('.blipAura').size() > 0) {
    const aura = select('.blipAura');
    const parentNode = getParentNode(aura);

    // prevent to click next time the same technology
    if (parentNode.datum().id === blipData.id) return null;

    hideAura();
    highlightQuarter(blipData.quadrant, callbacks);
  }

  if (!has(blipData, 'shape')) {
    blipData.shape = getShape[blipData.ring] || getShape[0];
    blipData.bgColor = getColor[blipData.quadrant];
  }

  hoveredElementParent
    .select('.shape')
    .attr('fill-opacity', 1)
    .transition(animation(100))
    .style('transform', 'translate(-5px, -5px) scale(2)');
  hoveredElementParent.insert('g', '.shape').attr('class', 'blipAura');

  select('.blipAura')
    .append('path')
    .attr('d', blipData.shape)
    .attr('fill-opacity', 0)
    .attr('stroke-width', 0.1)
    .attr('stroke', blipData.bgColor)
    .attr('stroke-opacity', 0.05)
    .style('pointer-events', 'none')
    .transition(animation(100))
    .delay(300)
    .style('transform', 'translate(-40px, -40px) scale(9)');

  select('.blipAura')
    .append('path')
    .attr('d', blipData.shape)
    .attr('fill-opacity', 0)
    .attr('stroke-width', 0.1)
    .attr('stroke', blipData.bgColor)
    .attr('stroke-opacity', 0.1)
    .style('pointer-events', 'none')
    .transition(animation(100))
    .delay(200)
    .style('transform', 'translate(-31px, -31px) scale(7.2)');

  select('.blipAura')
    .append('path')
    .attr('d', blipData.shape)
    .attr('fill-opacity', 0)
    .attr('stroke-width', 0.1)
    .attr('stroke', blipData.bgColor)
    .attr('stroke-opacity', 0.15)
    .style('pointer-events', 'none')
    .transition(animation(100))
    .delay(100)
    .style('transform', 'translate(-22px, -22px) scale(5.4)');

  select('.blipAura')
    .append('path')
    .attr('d', blipData.shape)
    .style('transform', 'translate(-13px, -13px) scale(3.6)')
    .attr('fill', blipData.bgColor)
    .attr('fill-opacity', 0.15)
    .style('pointer-events', 'none');

  callbacks?.onNodeSelected(blipData.id);

  return null;
};
