import { BaseType, selectAll } from 'd3-selection';

import { getParentNode } from 'core/radarUtils';
import { RadarTechnology } from 'types/radar.types';

export default (eventName: string, id: string | null) => {
  const selectedBlip = selectAll<BaseType, RadarTechnology>('g[class^=blip] .shape').filter(
    e => e.id === id
  );

  const parentNode = getParentNode(selectedBlip);

  parentNode.dispatch(eventName);
};
