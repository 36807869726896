import { useCallback } from 'react';

import { FilterOptionsEnum } from 'contexts/Technologies/services/Filters.service';
import { useApplyFilter } from 'contexts/Technologies/Technologies.context';

const useFilterOptionsHandlers = () => {
  const applyFilter = useApplyFilter();

  const handleOnActiveProjectsChange = useCallback(
    (option: FilterOptionsEnum) => {
      applyFilter({ activeProjects: option });
    },
    [applyFilter]
  );

  const handleOnResearchIncludedChange = useCallback(
    (option: FilterOptionsEnum) => {
      applyFilter({ researches: option });
    },
    [applyFilter]
  );

  const handleOnTechBetChange = useCallback(
    (option: FilterOptionsEnum) => {
      applyFilter({ techBet: option });
    },
    [applyFilter]
  );

  const handleOnInUseStageChange = useCallback(
    (value: boolean) => {
      applyFilter({ stageInUse: value });
    },
    [applyFilter]
  );

  const handleOnBetStageChange = useCallback(
    (value: boolean) => {
      applyFilter({ stageBet: value });
    },
    [applyFilter]
  );

  const handleOnExperimentStageChange = useCallback(
    (value: boolean) => {
      applyFilter({ stageExperiment: value });
    },
    [applyFilter]
  );

  const handleOnWatchStageChange = useCallback(
    (value: boolean) => {
      applyFilter({ stageWatch: value });
    },
    [applyFilter]
  );

  const handleNoStageStageChange = useCallback(
    (value: boolean) => {
      applyFilter({ stageNoStage: value });
    },
    [applyFilter]
  );

  return {
    handleOnActiveProjectsChange,
    handleOnResearchIncludedChange,
    handleOnTechBetChange,
    handleOnInUseStageChange,
    handleOnBetStageChange,
    handleOnExperimentStageChange,
    handleOnWatchStageChange,
    handleNoStageStageChange,
  };
};

export default useFilterOptionsHandlers;
