const APP_ROUTES = {
  WELCOME: '/',
  RADAR: '/radar',
  GRAPH: '/graph',
  LOGIN_CALLBACK: '/login/callback',
} as const;

export const TECH_DETAILS_SUB_PATHS = {
  OVERVIEW: 'overview',
  TALENTS: 'talents',
  ARTICLES: 'articles',
  RESOURCES: 'resources',
  RELATED: 'related',
} as const;

export default APP_ROUTES;
