import { select } from 'd3-selection';

import { translate, animation, getParentNode } from 'core/radarUtils';
import { Callbacks } from 'core/types';
import { FixMe } from 'types';

import getHoveredBlip from './getHoveredBlip';
import toggleQuarter from './toggleQuarter';

export default (d: FixMe, callbacks?: Callbacks) => {
  const tooltipText = d.numberOfProjects
    ? `${d.label} • ${d.numberOfProjects} project${d.numberOfProjects > 1 ? 's' : ''}`
    : d.label;
  const tooltip = select<SVGGElement, unknown>('.bubble text').text(tooltipText);

  const bbox = tooltip.node()?.getBBox();

  callbacks?.onNodeHighlighted(d.id);

  const hoveredElement = getHoveredBlip(d);
  const hoveredElementParent = getParentNode(hoveredElement);
  let hoverColor = d.bgColor;

  if (hoveredElement.classed('greyedBlip')) {
    hoverColor = '#d8d8d8';
  }

  hoveredElementParent
    .insert('path', '.shape')
    .attr('class', 'hoverEffect')
    .attr('d', d.shape)
    .attr('fill', hoverColor)
    .attr('fill-opacity', 0.15)
    .transition(animation(250))
    .style('transform', 'translate(-13px, -13px) scale(3.6)');

  // add hover efect to selected technology on radar
  hoveredElementParent.select('.hoverEffect').style('display', 'block');
  hoveredElementParent.select('.shape').transition(animation(250)).attr('fill-opacity', 1);

  toggleQuarter('mouseover', d.quadrant + 1);

  select('.bubble')
    .attr('transform', translate(d.x + 35, d.y + 35))
    .style('opacity', 1);

  select('.bubble rect')
    .attr('x', -13)
    .attr('y', -24)
    .attr('width', (bbox ? bbox.width : 0) + 26)
    .attr('height', '40px');
};
