import { ReactNode, useId } from 'react';

import cn from 'classnames';

import CardVertical from 'components/Card/CardVertical';

import styles from './StatisticsDataCard.module.scss';
import { ComparisonUnit } from './types';

interface StatisticsDataCardProps {
  title: ReactNode;
  value: number;
  diff: number;
  unit: ComparisonUnit;
}

const StatisticsDataCard = ({ title, value, diff, unit }: StatisticsDataCardProps) => {
  const labelId = useId();

  const getStyledDiffValue = () => {
    if (diff === 0 || (diff === value && unit === 'percentage')) return '-';

    const visibleDiffValue =
      unit === 'number' ? diff : `${Math.round((diff / (value - diff)) * 100)}%`;

    return `${diff > 0 ? '+' : ''}${visibleDiffValue}`;
  };

  return (
    <CardVertical
      aria-labelledby={labelId}
      supLabel={<span id={labelId}>{title}</span>}
      label={value}
      subLabel={
        <span
          className={cn(styles.diff, {
            [styles['diff--increase']]: diff > 0,
            [styles['diff--decrease']]: diff < 0,
          })}
        >
          {getStyledDiffValue()}
        </span>
      }
    />
  );
};

export default StatisticsDataCard;
