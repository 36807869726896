import MailTo from 'components/MailTo/MailTo';
import slackUriBuilder from 'shared/utils/slackUriBuilder';

import styles from './NoRelatedArticlesMessage.module.scss';

interface NoRelatedArticlesMessageProps {
  technologyName: string;
}

const NoRelatedArticlesMessage = ({ technologyName }: NoRelatedArticlesMessageProps) => {
  const mail = 'rnd@netguru.com';
  const subject = `[Tech Radar] Technology: ${technologyName} - Question about articles`;

  return (
    <div className={styles.noArticlesPlaceholder}>
      There are no internal researches related to this skill. If you know about any that should be
      listed here, please contact
      <MailTo email={mail} subject={subject}>
        {' rnd@netguru.com '}
      </MailTo>
      or
      <a className={styles.link} href={slackUriBuilder()}>
        {' #rnd '}
      </a>
      on Slack.
    </div>
  );
};

export default NoRelatedArticlesMessage;
