import { extendTheme } from '@chakra-ui/react';

import colors from './_colors.module.scss';
import typography from './_typography.module.scss';

const theme = extendTheme({
  styles: {
    global: {
      // https://chakra-ui.com/docs/styled-system/global-styles
      // do not override body styles with chakra provided ones
      body: null,
      ul: {
        padding: 0,
      },
    },
  },
  components: {
    SliderThumb: { baseStyle: null },
  },
  fonts: {
    body: typography.ffamily,
  },
  colors: {
    green: {
      900: colors.green900,
      800: colors.green800,
      700: colors.green700,
      600: colors.green600,
      500: colors.green500,
      400: colors.green400,
      300: colors.green300,
      200: colors.green200,
      100: colors.green100,
      50: colors.green50,
    },
    navy: {
      900: colors.navy900,
      800: colors.navy800,
      700: colors.navy700,
      600: colors.navy600,
      550: colors.navy550,
      500: colors.navy500,
      400: colors.navy400,
      300: colors.navy300,
      200: colors.navy200,
      100: colors.navy100,
      50: colors.navy50,
    },
    semantic: {
      positive: colors.positive,
      neutral: colors.neutral,
      neutral_v2: colors.neutral_v2,
      critical: colors.critical,
      negative: colors.negative,
    },
  },
});

export default theme;
