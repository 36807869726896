import styles from './MailTo.module.scss';

export interface MailToProps {
  children: string;
  email: string;
  subject?: string;
  body?: string;
}

const MailTo = ({ children, email, subject, body }: MailToProps) => {
  const createMailToHref = () => {
    const queryArgs: string[] = [];
    let url = `mailto:${email}`;

    if (subject) queryArgs.push(`subject=${encodeURIComponent(subject)}`);
    if (body) queryArgs.push(`body=${encodeURIComponent(body)}`);

    if (queryArgs.length !== 0) {
      url += `?${queryArgs.join('&')}`;
    }

    return url;
  };

  return (
    <a data-testid="components-mailTo" className={styles.link} href={createMailToHref()}>
      {children}
    </a>
  );
};

export default MailTo;
