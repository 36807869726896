import SearchInput from 'components/SearchInput/SearchInput';
import { useFilterTechnologies } from 'contexts/Technologies/Technologies.context';

const Search = () => {
  const { searchPhrase, handleSearchChange } = useFilterTechnologies();

  return <SearchInput value={searchPhrase} onChange={handleSearchChange} placeholder="Search" />;
};

export default Search;
