import { Skeleton as SkeletonBase, SkeletonProps as SkeletonPropsBase } from '@chakra-ui/react';

interface SkeletonProps extends SkeletonPropsBase {
  /* loadingHeight: Used to set static height of Skeleton (in case the children are null and doesn't have height at time of loading). */
  loadingHeight?: string;
}

const Skeleton = ({ children, loadingHeight = undefined, ...props }: SkeletonProps) => {
  return (
    <SkeletonBase
      height={props.isLoaded ? undefined : loadingHeight}
      data-testid="component-skeleton"
      startColor="navy.550"
      endColor="navy.600"
      {...props}
    >
      {children}
    </SkeletonBase>
  );
};

export default Skeleton;
