export type SkillRatingLevel = 1 | 2 | 3 | 4 | 5;

export enum TalentStatuses {
  Total = 'Total',
  InProject = 'In project',
  OnBench = 'On bench',
}

export interface TalentsByStatus {
  sum: number;
  favorite: number;
  bySkillRating: {
    [level in SkillRatingLevel]: number;
  };
}

export interface Talents {
  [TalentStatuses.Total]: TalentsByStatus;
  [TalentStatuses.InProject]: TalentsByStatus;
  [TalentStatuses.OnBench]: TalentsByStatus;
}
