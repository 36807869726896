import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import { useInteractions, useSetInteractions } from 'contexts/Interactions/Interactions.context';
import { useTechnologies } from 'contexts/Technologies/Technologies.context';
import decorateTechnologiesForRadar from 'contexts/Technologies/utils/decorateTechnologiesForRadar';
import {
  focusQuarter,
  hideAura,
  radarVisualization,
  showBlipAura,
  toggleQuarter,
  triggerEvent,
} from 'core/radar';
import radarConfig from 'core/radarConfig';
import APP_ROUTES from 'shared/routes';
import { TechnologiesRadar } from 'types/radar.types';

import styles from './RadarContent.module.scss';

export default function RadarContent() {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  // a trick to doesn't have to include navigate in depsArray of useEffect and still have
  // the latest possible value of an object
  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  const { technologyId } = useParams();
  const interactions = useSetInteractions();
  const { hoveredSearchResultNodeId } = useInteractions();
  const technologies = useTechnologies();
  const [radarTechnologies, setRadarTechnologies] = useState<TechnologiesRadar>({});
  useEffect(() => {
    setRadarTechnologies(prevValue => decorateTechnologiesForRadar(technologies || {}, prevValue));
  }, [technologies]);

  useEffect(() => {
    radarVisualization({
      callbacks: {
        onNodeSelected: nodeId => {
          navigateRef.current(nodeId ? `${APP_ROUTES.RADAR}/${nodeId}` : APP_ROUTES.RADAR);
        },
        onNodeHighlighted: nodeId => {
          interactions.setHighlightedTechnologyId(nodeId);
        },
        onQuarterHighlighted: quarter => {
          interactions.setHighlightedQuarter(quarter);
        },
      },
      technologies: radarTechnologies,
    });
  }, [radarTechnologies, interactions]);

  // highlight proper node and quarter based of selected tech
  useEffect(() => {
    const selectedTechnology = technologyId ? radarTechnologies?.[technologyId] : null;

    if (!selectedTechnology) {
      return undefined;
    }

    showBlipAura(selectedTechnology);
    focusQuarter(selectedTechnology.quadrant + 1, true);

    return () => {
      hideAura();
      focusQuarter(selectedTechnology.quadrant + 1, false);
    };
  }, [radarTechnologies, technologyId]);

  // highlight proper node and quarter based of hovered tech
  useEffect(() => {
    if (!hoveredSearchResultNodeId) {
      return undefined;
    }

    triggerEvent('mouseover', hoveredSearchResultNodeId);

    return () => {
      triggerEvent('mouseout', hoveredSearchResultNodeId);

      const technology = hoveredSearchResultNodeId
        ? radarTechnologies?.[hoveredSearchResultNodeId]
        : null;

      if (technology) {
        toggleQuarter('mouseout', technology.quadrant + 1);
      }
    };
  }, [hoveredSearchResultNodeId, radarTechnologies]);

  return (
    <div className={styles.radarWrapper}>
      <svg id={radarConfig.svg_id} className={cn(styles.downsizeRadar, styles.svgRadar)} />
    </div>
  );
}
