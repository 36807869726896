import Isvg from 'react-inlinesvg';

import externalLinkIcn from 'assets/svg/external_link.svg';
import { ChildrenProp } from 'types';

import styles from './ExternalLinkChip.module.scss';

interface ExternalLinkChipProps extends ChildrenProp {
  href: string;
}

const ExternalLinkChip = ({ href, children }: ExternalLinkChipProps) => {
  return (
    <a className={styles.chip} href={href} target="_blank" rel="noopener noreferrer">
      <span>{children}</span>
      <Isvg className={styles.icon} src={externalLinkIcn} />
    </a>
  );
};

export default ExternalLinkChip;
