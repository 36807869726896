import Accordion from 'components/Accordion/Accordion';
import ExternalLinkButton from 'components/ExternalLinkButton/ExternalLinkButton';
import { useTechnology } from 'contexts/Technologies/Technologies.context';

const TechnologyDetailsLeads = () => {
  const technology = useTechnology();

  const getAllLeadsUrl = () => {
    if (!technology) return '';

    return `https://netguru.lightning.force.com/lightning/r/Report/00O5J000000pgOaUAI/view?queryScope=userFolders&fv1=${encodeURIComponent(
      technology.name
    )}`;
  };

  return (
    <Accordion label="Leads" defaultExpanded>
      <ExternalLinkButton href={getAllLeadsUrl()} fullWidth>
        See all leads
      </ExternalLinkButton>
    </Accordion>
  );
};

export default TechnologyDetailsLeads;
