import Isvg from 'react-inlinesvg';

import heart from 'assets/svg/heart.svg';
import Tooltip from 'components/Tooltip/Tooltip';

import RatingCardBase from '../RatingCardBase/RatingCardBase';

interface FavoriteSkillCardProps {
  numberOfTalents: number;
}

const FavoriteSkillCard = ({ numberOfTalents }: FavoriteSkillCardProps) => {
  const favoriteIcon = (
    <Tooltip content="Favourite skill" placement="top-end">
      <div>
        <Isvg src={heart} />
      </div>
    </Tooltip>
  );

  return <RatingCardBase ratingIcons={favoriteIcon} value={numberOfTalents} />;
};

export default FavoriteSkillCard;
