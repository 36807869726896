import { useInteractions } from 'contexts/Interactions/Interactions.context';

import styles from './List.module.scss';
import ListItem from './ListItem/ListItem';

interface ListProps {
  items: {
    id: string;
    label: string;
  }[];
}

const List = ({ items }: ListProps) => {
  const { highlightedTechnologyId } = useInteractions();

  return (
    <ul data-testid="views-list_wrapper" className={styles.list}>
      {items.map(item => (
        <ListItem key={item.id} id={item.id} highlighted={item.id === highlightedTechnologyId}>
          {item.label}
        </ListItem>
      ))}
    </ul>
  );
};

export default List;
