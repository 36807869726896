import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import { useFilterTechnologies } from 'contexts/Technologies/Technologies.context';
import APP_ROUTES from 'shared/routes';

/**
 * Whole idea behind this component is to reset interactions
 * (which affect highlighting etc) on each pathname change on Network subpath.
 * This way we ensure that whenever user changes page, they're not presented
 * with old highlights or selections.
 */
const InteractionsReset = () => {
  const { resetSelections, handleSearchChange } = useFilterTechnologies();
  const { pathname } = useLocation();

  const resetSelectionsRef = useRef(resetSelections);
  const handleSearchChangeRef = useRef(handleSearchChange);

  // Used ref to ensure that next useEffect callback has always up-to-date function reference without the need to include them in the deps array.
  useEffect(() => {
    resetSelectionsRef.current = resetSelections;
    handleSearchChangeRef.current = handleSearchChange;
  }, [resetSelections, handleSearchChange]);

  useEffect(() => {
    if (!pathname.includes(APP_ROUTES.GRAPH)) return;

    handleSearchChangeRef.current('');
    resetSelectionsRef.current();
  }, [pathname]);

  return null;
};

export default InteractionsReset;
