import ScreenLayout from 'components/ScreenLayout/ScreenLayout';

import Technologies from './DetailsContent/Technologies/Technologies';
import DetailsLeftside from './DetailsLeftside/DetailsLeftside';
import DetailsTop from './DetailsTop/DetailsTop';

const DetailsScreen = () => {
  return (
    <ScreenLayout top={<DetailsTop />} leftSide={<DetailsLeftside />} content={<Technologies />} />
  );
};

export default DetailsScreen;
