import NavigationTab from 'components/NavigationTab/NavigationTab';
import NavigationTabGroup from 'components/NavigationTabGroup/NavigationTabGroup';
import { FeatureFlag, isFlagEnabled } from 'shared/helpers/featureFlags';
import useWhichScreenView from 'shared/hooks/useWhichScreenView';
import APP_ROUTES, { TECH_DETAILS_SUB_PATHS } from 'shared/routes';

interface DetailsScreenSwitcherParams {
  technologyId: string;
}

const DetailsScreenSwitcher = ({ technologyId }: DetailsScreenSwitcherParams) => {
  const { isRadar } = useWhichScreenView();

  const createUrl = (subpath: string) => {
    return `${isRadar ? APP_ROUTES.RADAR : APP_ROUTES.GRAPH}/${technologyId}/${subpath}`;
  };

  return (
    <NavigationTabGroup variant="withoutBackground">
      <NavigationTab to={createUrl(TECH_DETAILS_SUB_PATHS.OVERVIEW)}>Overview</NavigationTab>

      {isFlagEnabled(FeatureFlag.SidePanelTalents) ? (
        <NavigationTab to={createUrl(TECH_DETAILS_SUB_PATHS.TALENTS)}>Talents</NavigationTab>
      ) : null}

      {isFlagEnabled(FeatureFlag.SidePanelResources) ? (
        <NavigationTab to={createUrl(TECH_DETAILS_SUB_PATHS.RESOURCES)}>Resources</NavigationTab>
      ) : (
        <NavigationTab to={createUrl(TECH_DETAILS_SUB_PATHS.ARTICLES)}>Articles</NavigationTab>
      )}

      <NavigationTab to={createUrl(TECH_DETAILS_SUB_PATHS.RELATED)}>Related</NavigationTab>
    </NavigationTabGroup>
  );
};

export default DetailsScreenSwitcher;
