import { Box } from '@chakra-ui/react';
import Isvg from 'react-inlinesvg';

import externalLinkIcon from 'assets/svg/external_link.svg';
import Chip from 'components/Chip/Chip';
import ChipList from 'components/ChipList/ChipList';

import { Resource } from '../../types';
import styles from './ResourcesListItem.module.scss';

interface ResourcesListItemProps {
  resource: Resource;
  shouldDisplayTags?: boolean;
  shouldDisplaySummary?: boolean;
}

const ResourcesListItem = ({
  resource,
  shouldDisplayTags = true,
  shouldDisplaySummary = true,
}: ResourcesListItemProps) => {
  const { title, summary, tags, url } = resource;

  return (
    <li className={styles.articleListItem}>
      <a href={url} target="_blank" rel="noopener noreferrer" className={styles.itemLink}>
        <h4 className={styles.header}>
          <span>{title}</span>

          <Isvg className={styles.externalLinkIcon} src={externalLinkIcon} aria-hidden />
        </h4>

        {shouldDisplaySummary ? (
          <Box mt="12px" className={styles.summary}>
            {summary}
          </Box>
        ) : null}

        {shouldDisplayTags && tags?.length ? (
          <Box mt="24px">
            <ChipList>
              {tags.map(tag => (
                <Chip key={tag}>{tag}</Chip>
              ))}
            </ChipList>
          </Box>
        ) : null}
      </a>
    </li>
  );
};

export default ResourcesListItem;
