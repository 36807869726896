import { ForwardedRef, forwardRef } from 'react';

import { useObjectRef } from '@react-aria/utils';
import { Node } from '@react-types/shared';
import classNames from 'classnames';
import { useOption } from 'react-aria';
import { ListState } from 'react-stately';

import styles from './Option.module.scss';

type OptionProps<T> = {
  item: Node<T>;
  state: ListState<T>;
};

const Option = forwardRef(function Option<T>(
  { item, state }: OptionProps<T>,
  ref: ForwardedRef<HTMLLIElement>
) {
  const optionRef = useObjectRef(ref);

  const { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: item.key },
    state,
    optionRef
  );

  return (
    <li
      {...optionProps}
      ref={optionRef}
      className={classNames(styles.option, {
        [styles['option--disabled']]: isDisabled,
        [styles['option--focused']]: isFocused,
        [styles['option--selected']]: isSelected,
      })}
    >
      {item.rendered}
    </li>
  );
});

export default Option;
