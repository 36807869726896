import { KeyboardEventHandler, MouseEventHandler, ReactNode, useId, useRef, useState } from 'react';

import cn from 'classnames';
import Isvg from 'react-inlinesvg';

import caret from 'assets/svg/caret.svg';
import { ChildrenProp } from 'types';

import styles from './Accordion.module.scss';

interface AccordionProps extends ChildrenProp {
  label: ReactNode;
  defaultExpanded?: boolean;
  headerGap?: string;
  level?: number;
}

const Accordion = ({
  label,
  children,
  defaultExpanded = false,
  headerGap = '16px',
  level = 1,
}: AccordionProps) => {
  const id = useId();
  const contentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(defaultExpanded ? undefined : '0px');
  const isExpanded = maxHeight !== '0px';

  const toggleExpand = () => {
    if (!contentRef.current) {
      return;
    }

    const { scrollHeight } = contentRef.current;

    // maxHeight must be set to allow for initial closing animation
    if (isExpanded && maxHeight === undefined) {
      setMaxHeight(`${scrollHeight}px`);
    }

    // requestAnimationFrame used to allow previous setMaxHeight to actually set the value
    requestAnimationFrame(() => {
      setMaxHeight(`${!isExpanded ? scrollHeight : 0}px`);
    });
  };

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = () => {
    toggleExpand();
  };

  const handleOnKeyDown: KeyboardEventHandler<HTMLButtonElement> = ({ type, key }) => {
    if (type === 'keydown' && (key === 'Enter' || key === 'Space')) {
      toggleExpand();
    }
  };

  return (
    <div>
      <button
        type="button"
        id={`accordion-header-${id}`}
        className={cn(styles.header, styles[`header--level-${level}`])}
        onClick={handleOnClick}
        onKeyDown={handleOnKeyDown}
        aria-expanded={isExpanded}
        aria-controls={`accordion-panel-${id}`}
      >
        <span className={styles.label}>{label}</span>
        <Isvg src={caret} className={styles.caret} aria-hidden />
      </button>

      <div className={styles.content_wrapper} style={{ maxHeight }}>
        <div
          id={`accordion-panel-${id}`}
          ref={contentRef}
          style={{ paddingTop: headerGap }}
          className={cn(styles.content, {
            [styles['content--hidden']]: !isExpanded,
          })}
          aria-labelledby={`accordion-header-${id}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
