import { useQuery } from '@tanstack/react-query';

import { useAuth } from 'contexts/Auth/Auth.context';

import { StatisticsPeriod, TechnologyStatistics } from './types';

interface UseStatisticsQueryParams {
  technologyId: string;
  statisticsPeriod: StatisticsPeriod;
}

const useStatisticsQuery = (params: UseStatisticsQueryParams) => {
  const { user } = useAuth();
  const accessToken = user.getToken();

  return useQuery<TechnologyStatistics>({
    queryKey: ['statistics', params, accessToken],
    queryFn: async ({ signal }) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/technologies/${
          params.technologyId
        }/statistics?${new URLSearchParams({
          daysBack: params.statisticsPeriod,
        })}`,
        {
          signal,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.json();
    },
    refetchOnMount: false,
  });
};

export default useStatisticsQuery;
