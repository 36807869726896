import MailTo from 'components/MailTo/MailTo';
import slackUriBuilder from 'shared/utils/slackUriBuilder';

import { ResourceType } from '../../types';
import styles from './NoResources.module.scss';

interface NoResourcesProps {
  resourceType?: ResourceType;
  technologyName: string;
}

const NoResources = ({ resourceType, technologyName }: NoResourcesProps) => {
  const mail = 'rnd@netguru.com';
  const subject = `[Tech Radar] Technology: ${technologyName} - Question about resource`;

  const getResourceTypeToText = () => {
    if (resourceType === ResourceType.Article) return 'articles';
    if (resourceType === ResourceType.CaseStudy) return 'case studies';
    return 'resources';
  };

  return (
    <div className={styles.noResourcesText}>
      No related {getResourceTypeToText()} found. Contact
      <MailTo email={mail} subject={subject}>
        {' rnd@netguru.com '}
      </MailTo>
      or
      <a className={styles.link} href={slackUriBuilder()}>
        {' #rnd '}
      </a>
      on slack to provide updates.
    </div>
  );
};

export default NoResources;
