import { useState } from 'react';

import { Box, HStack, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import RadioCardBig from 'components/CardButton/RadioCardBig/RadioCardBig';
import { Combobox, Item } from 'components/Combobox';
import ExternalLinkButton from 'components/ExternalLinkButton/ExternalLinkButton';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import FavoriteSkillCard from 'components/RatingCard/FavoriteSkillCard/FavoriteSkillCard';
import SkillRatingCard from 'components/RatingCard/SkillRatingCard/SkillRatingCard';
import SidePanelSectionHeader from 'components/SidePanel/SidePanelSectionHeader/SidePanelSectionHeader';
import SidePanelSectionSubheader from 'components/SidePanel/SidePanelSectionSubheader/SidePanelSectionSubheader';
import Skeleton from 'components/Skeleton/Skeleton';
import { useTechnology } from 'contexts/Technologies/Technologies.context';
import { TalentStatuses } from 'types/talents.types';

import useTalentsQuery from './useTalentsQuery';
import useUnitsQuery from './useUnitsQuery';

const TechnologyDetailsTalents = () => {
  const initialUnitSelection = { id: 'ALL_UNITS', name: 'All units' };
  const technology = useTechnology();
  const { technologyId } = useParams();
  const [talentStatusSelection, setTalentStatuses] = useState<TalentStatuses>(TalentStatuses.Total);
  const [unitSelectionId, setUnitSelectionId] = useState<string>(initialUnitSelection.id);

  if (!technologyId) {
    throw new Error('technologyId query param is missing');
  }

  const talentsQuery = useTalentsQuery({
    technologyId,
    unitId: unitSelectionId === initialUnitSelection.id ? undefined : unitSelectionId,
  });
  const unitsQuery = useUnitsQuery({ technologyId });

  const getAllTalentsUrl = () => {
    if (!technology) return '';

    return `https://netguru.lightning.force.com/lightning/r/Report/00O5J000000pgKEUAY/view?queryScope=userFolders&fv2=${encodeURIComponent(
      technology.name
    )}`;
  };

  const getUnitSelectionChoices = () => {
    const unitsSelectionChoices = [initialUnitSelection];

    if (!unitsQuery.isLoading && unitsQuery.data?.units.length) {
      unitsSelectionChoices.push(...unitsQuery.data.units);
    }

    return unitsSelectionChoices;
  };

  return (
    <VStack spacing="32px" align="stretch">
      {/* Header & Salesforce Link */}
      <Box>
        <SidePanelSectionHeader>
          <HStack spacing="8px">
            <span>Talents</span>
            <InfoTooltip
              tooltipText={`Number of talents with ${technology?.name} skill and ratings. To see more details visit SalesForce.`}
            />
          </HStack>
        </SidePanelSectionHeader>

        <ExternalLinkButton href={getAllTalentsUrl()} fullWidth>
          See all talents with this skill
        </ExternalLinkButton>
      </Box>

      {/* Combobox & Radiobuttons "Total / In project / On bench" */}
      <Box>
        <SidePanelSectionSubheader>
          Number of talents with skill per unit:
        </SidePanelSectionSubheader>

        <Skeleton isLoaded={!unitsQuery.isLoading}>
          <Combobox
            aria-label="Department selection"
            selectedKey={unitSelectionId}
            onSelectionChange={key => {
              if (!key) return;

              setUnitSelectionId(key.toString());
            }}
          >
            {getUnitSelectionChoices().map(unitChoice => {
              return <Item key={unitChoice.id}>{unitChoice.name}</Item>;
            })}
          </Combobox>
        </Skeleton>

        <HStack mt="16px" spacing="12px">
          <Skeleton isLoaded={!talentsQuery.isLoading} style={{ width: '100%' }}>
            <RadioCardBig
              aria-label="Total of talents"
              label={talentsQuery.data?.[TalentStatuses.Total].sum || 0}
              subLabel={TalentStatuses.Total}
              value={TalentStatuses.Total}
              checked={talentStatusSelection === TalentStatuses.Total}
              onChange={() => setTalentStatuses(TalentStatuses.Total)}
            />
          </Skeleton>
          <Skeleton isLoaded={!talentsQuery.isLoading} style={{ width: '100%' }}>
            <RadioCardBig
              aria-label="Talents in projects"
              label={talentsQuery.data?.[TalentStatuses.InProject].sum || 0}
              subLabel={TalentStatuses.InProject}
              value={TalentStatuses.InProject}
              checked={talentStatusSelection === TalentStatuses.InProject}
              onChange={() => setTalentStatuses(TalentStatuses.InProject)}
            />
          </Skeleton>
          <Skeleton isLoaded={!talentsQuery.isLoading} style={{ width: '100%' }}>
            <RadioCardBig
              aria-label="Talents on bench"
              label={talentsQuery.data?.[TalentStatuses.OnBench].sum || 0}
              subLabel={TalentStatuses.OnBench}
              value={TalentStatuses.OnBench}
              checked={talentStatusSelection === TalentStatuses.OnBench}
              onChange={() => setTalentStatuses(TalentStatuses.OnBench)}
            />
          </Skeleton>
        </HStack>
      </Box>

      {/* Talents Rating cards */}
      <Box>
        <SidePanelSectionSubheader>
          <HStack spacing="8px">
            <span>Talents by skill rate: </span>
            <InfoTooltip tooltipText="See how talents rated this skill in Source and how many marked it as favourite." />
          </HStack>
        </SidePanelSectionSubheader>
        <VStack spacing="16px" align="stretch">
          <Skeleton isLoaded={!talentsQuery.isLoading}>
            <FavoriteSkillCard
              numberOfTalents={talentsQuery.data?.[talentStatusSelection].favorite || 0}
            />
          </Skeleton>
          <Skeleton isLoaded={!talentsQuery.isLoading}>
            <SkillRatingCard
              skillLevel={5}
              numberOfTalents={talentsQuery.data?.[talentStatusSelection].bySkillRating[5] || 0}
            />
          </Skeleton>
          <Skeleton isLoaded={!talentsQuery.isLoading}>
            <SkillRatingCard
              skillLevel={4}
              numberOfTalents={talentsQuery.data?.[talentStatusSelection].bySkillRating[4] || 0}
            />
          </Skeleton>
          <Skeleton isLoaded={!talentsQuery.isLoading}>
            <SkillRatingCard
              skillLevel={3}
              numberOfTalents={talentsQuery.data?.[talentStatusSelection].bySkillRating[3] || 0}
            />
          </Skeleton>
          <Skeleton isLoaded={!talentsQuery.isLoading}>
            <SkillRatingCard
              skillLevel={2}
              numberOfTalents={talentsQuery.data?.[talentStatusSelection].bySkillRating[2] || 0}
            />
          </Skeleton>
          <Skeleton isLoaded={!talentsQuery.isLoading}>
            <SkillRatingCard
              skillLevel={1}
              numberOfTalents={talentsQuery.data?.[talentStatusSelection].bySkillRating[1] || 0}
            />
          </Skeleton>
        </VStack>
      </Box>
    </VStack>
  );
};

export default TechnologyDetailsTalents;
