import { Children, createContext, isValidElement, ReactNode, useMemo } from 'react';

import cn from 'classnames';

import styles from './NavigationTabGroup.module.scss';

type NavigationTabContextValue = { variant: 'withBackground' | 'withoutBackground' };
export const NavigationTabContext = createContext<NavigationTabContextValue>({
  variant: 'withBackground',
});

interface NavigationTabGroupProps {
  children: ReactNode;
  variant?: 'withBackground' | 'withoutBackground';
}

const NavigationTabGroup = ({ children, variant = 'withBackground' }: NavigationTabGroupProps) => {
  const navigationTabContextValue = useMemo(() => ({ variant }), [variant]);

  return (
    <NavigationTabContext.Provider value={navigationTabContextValue}>
      <nav>
        <ul className={cn(styles.navigation, styles[`navigation--${variant}`])}>
          {Children.map(children, child => {
            if (!isValidElement(child)) return null;

            return <li className={styles.li}>{child}</li>;
          })}
        </ul>
      </nav>
    </NavigationTabContext.Provider>
  );
};

export default NavigationTabGroup;
