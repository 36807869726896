import { useId } from 'react';

import Isvg from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';

import ngLogo from 'assets/svg/ng-logo.svg';
import playIcn from 'assets/svg/play.svg';
import APP_ROUTES from 'shared/routes';

import styles from './WelcomeScene.module.scss';

const WelcomeScene = () => {
  const ctaId = useId();
  const navigate = useNavigate();
  const handlePlayButton = () => navigate(APP_ROUTES.RADAR);

  return (
    <div className={styles.container}>
      <div className={styles.leftSideLogo}>
        <Isvg src={ngLogo} />
        <div className={styles.logo__title}>Software Radar</div>
        <div className={styles.logo__version}>{`V${process.env.REACT_APP_VERSION}`}</div>
      </div>
      <section className={styles.welcomeContent}>
        <div className={styles.welcomeContent__title}>Welcome to the Netguru Software Radar</div>
        <div className={styles.welcomeContent__description}>
          With a decade of experience in providing all kinds of digital solutions for clients around
          the world, we only trust the best tech. Take a look at the tools we use on an everyday
          basis, the ones we experiment with, and those which we find to be passe.
        </div>
        <div className={styles.welcomeContent__wrapper}>
          <div id={ctaId} className={styles.welcomeContent__explore}>
            Start exploring
          </div>

          <button
            aria-labelledby={ctaId}
            className={styles.welcomeContent__playButton}
            onClick={handlePlayButton}
            type="button"
          >
            <Isvg src={playIcn} />
          </button>
        </div>
      </section>
    </div>
  );
};

export default WelcomeScene;
