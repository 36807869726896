import { select } from 'd3-selection';

import { animation, getParentNode } from 'core/radarUtils';

export default () => {
  const aura = select('.blipAura');
  const parentNode = getParentNode(aura);

  parentNode
    .select('.shape')
    .transition(animation(200))
    .style('transform', 'translate(0, 0) scale(1)');

  select('.blipAura').remove();
};
