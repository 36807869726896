import cn from 'classnames';
import { Outlet } from 'react-router-dom';

import SidePanelSection from 'components/SidePanel/SidePanelSection/SidePanelSection';
import Skeleton from 'components/Skeleton/Skeleton';
import DetailsScreenSwitcher from 'views/ScreenSwitcher/DetailsScreenSwitcher';

import styles from './TechnologyDetailsAuthed.module.scss';

interface TechnologyDetailsAuthedProps {
  isLoading: boolean;
  technologyId: string;
}

const TechnologyDetailsAuthed = ({ isLoading, technologyId }: TechnologyDetailsAuthedProps) => {
  return (
    <>
      {/* TAB SWITCHER */}
      <Skeleton
        isLoaded={!isLoading}
        className={cn(styles['skeleton-screenSwitcher'], {
          [styles['skeleton-screenSwitcher-loaded']]: !isLoading,
        })}
      >
        <DetailsScreenSwitcher technologyId={technologyId} />
      </Skeleton>

      {/* OUTLET - DISPLAY CONTENT BASED ON ROUTE */}
      <SidePanelSection>
        <Skeleton isLoaded={!isLoading}>
          <Outlet />
        </Skeleton>
      </SidePanelSection>
    </>
  );
};

export default TechnologyDetailsAuthed;
