import cnst from 'shared/constants';

import styles from './TechnologyDetailsAskFreely.module.scss';

interface TechnologyDetailsAskFreelyProps {
  technologyName: string;
}

const TechnologyDetailsAskFreely = ({ technologyName }: TechnologyDetailsAskFreelyProps) => {
  if (!technologyName.trim()) return null;

  return (
    <div className={styles.askFreelyWrapper}>
      <div className={styles.interestedInText}>Interested in {technologyName} services?</div>
      <a
        href={cnst.ESTIMATE_PROJECT_URL}
        target="_blank"
        rel="noreferrer"
        className={styles.askFreelyButton}
      >
        Ask freely!
      </a>
    </div>
  );
};

export default TechnologyDetailsAskFreely;
