import SidePanelSection from 'components/SidePanel/SidePanelSection/SidePanelSection';
import Skeleton from 'components/Skeleton/Skeleton';
import { TechnologyDetails } from 'types/technology.types';

import TechnologyDetailsNavigation from '../TechnologyDetailsNavigation/TechnologyDetailsNavigation';
import TechnologyDetailsTags from '../TechnologyDetailsTags/TechnologyDetailsTags';
import styles from './TechnologyDetailsHeader.module.scss';

interface TechnologyDetailsHeaderProps {
  technology: TechnologyDetails | null;
  isLoading: boolean;
}

const TechnologyDetailsHeader = ({ technology, isLoading }: TechnologyDetailsHeaderProps) => {
  return (
    <SidePanelSection hasBottomBorder={!isLoading}>
      {/* BACK BUTTON */}
      <Skeleton
        mb="30px"
        isLoaded={!isLoading}
        data-testid="views-technologydetails-skeleton"
        loadingHeight="28px"
      >
        <TechnologyDetailsNavigation />
      </Skeleton>

      {/* NAME AND TAGS */}
      <Skeleton isLoaded={!isLoading} loadingHeight="70px">
        <div className={styles['tech-details-header']}>
          <h2 className={styles['tech-details-header-text']}>
            {technology?.name || 'Technology not found'}
          </h2>
          {technology ? <TechnologyDetailsTags technology={technology} /> : null}
        </div>
      </Skeleton>
    </SidePanelSection>
  );
};

export default TechnologyDetailsHeader;
