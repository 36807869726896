import { ComponentType, FC, ReactElement, useEffect, useState } from 'react';

import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { OnAuthResumeFunction } from '@okta/okta-react/bundles/types/OktaContext';
import { useNavigate } from 'react-router-dom';

interface OktaErrorProps {
  error: Error;
}

const OktaError: FC<OktaErrorProps> = ({ error }) => {
  if (error.name && error.message) {
    return (
      <p>
        {error.name}: {error.message}
      </p>
    );
  }
  return <p>Error: {error.toString()}</p>;
};

// This is a slightly modified copy of https://github.com/okta/okta-react#logincallback component.

interface LoginCallbackProps {
  errorComponent?: ComponentType<{ error: Error }>;
  onAuthResume?: OnAuthResumeFunction;
  loadingElement?: ReactElement;
}

const LoginCallback: FC<LoginCallbackProps> = ({
  errorComponent,
  loadingElement = null,
  onAuthResume,
}) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [callbackError, setCallbackError] = useState(null);
  const navigate = useNavigate();

  const ErrorReporter = errorComponent || OktaError;

  useEffect(() => {
    if (onAuthResume && oktaAuth.idx.isInteractionRequired()) {
      onAuthResume();
      return;
    }

    const originalUri = oktaAuth.getOriginalUri();

    if (!oktaAuth.isLoginRedirect()) {
      navigate(toRelativeUrl('/', window.location.origin), { replace: true });
    }

    oktaAuth.handleLoginRedirect(undefined, originalUri).catch(e => {
      setCallbackError(e);
    });

    // should fire only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authError = authState?.error;
  const displayError = callbackError || authError;
  if (displayError) {
    return <ErrorReporter error={displayError} />;
  }

  return loadingElement;
};

export default LoginCallback;
