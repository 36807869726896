import Isvg from 'react-inlinesvg';

import ngLogo from 'assets/svg/ng-logo.svg';

import styles from './MobileDeviceFallback.module.scss';

const MobileDeviceFallback = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <div className={styles.header}>
          <Isvg src={ngLogo} />
          <h3 className={styles.header__text}>Something went wrong...</h3>
        </div>
        <p className={styles.info}>
          Radar is not available on mobile phones, please use desktop or tablet.
        </p>
      </div>
    </div>
  );
};

export default MobileDeviceFallback;
