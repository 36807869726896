import { useState, useCallback } from 'react';

import { FiltersState, FilterOptionsEnum } from '../services/Filters.service';

export const initFiltersState: FiltersState = {
  activeProjects: FilterOptionsEnum.all,
  researches: FilterOptionsEnum.all,
  techBet: FilterOptionsEnum.all,
  stageInUse: true,
  stageBet: true,
  stageExperiment: true,
  stageWatch: true,
  stageNoStage: true,
};

const useFiltersService = () => {
  const [filtersState, setFiltersState] = useState<FiltersState>(initFiltersState);

  const applyFilter = useCallback((filter: Partial<FiltersState>) => {
    setFiltersState(prevFilters => ({ ...prevFilters, ...filter }));
  }, []);

  return {
    applyFilter,
    filtersState,
  };
};

export default useFiltersService;
