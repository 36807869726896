import { ForwardedRef, forwardRef, ReactNode } from 'react';

import { useObjectRef } from '@react-aria/utils';
import { usePopover, DismissButton, AriaPopoverProps } from 'react-aria';
import { OverlayTriggerState } from 'react-stately';

import styles from './Popover.module.scss';

type PopoverProps = Pick<AriaPopoverProps, 'triggerRef'> & {
  state: OverlayTriggerState;
  children: ReactNode;
};

const Popover = forwardRef(function Popover(
  { state, children, ...props }: PopoverProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const popoverRef = useObjectRef(ref);

  // Handle events that should cause the popup to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  const { popoverProps } = usePopover(
    {
      ...props,
      popoverRef,
      containerPadding: 0,
      placement: 'bottom start',
      offset: 8,
      isNonModal: true,
    },
    state
  );

  // Add a hidden <DismissButton> component at the end of the popover
  // to allow screen reader users to dismiss the popup easily.
  return (
    <div {...popoverProps} ref={popoverRef} className={styles.popover}>
      {children}
      <DismissButton onDismiss={state.close} />
    </div>
  );
});

export default Popover;
