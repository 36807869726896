import { ReactNode } from 'react';

import styles from './SidePanelSectionHeader.module.scss';

interface SidePanelSectionHeader {
  children: ReactNode;
}

const SidePanelSectionHeader = ({ children }: SidePanelSectionHeader) => {
  return <h2 className={styles.header}>{children}</h2>;
};

export default SidePanelSectionHeader;
