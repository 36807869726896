import { useEffect, useRef, useState } from 'react';

import { ForceGraphMethods } from 'react-force-graph-2d';

import ScreenLayout from 'components/ScreenLayout/ScreenLayout';
import { useSetTechnology } from 'contexts/Technologies/Technologies.context';
import { ZoomValues } from 'types/graph.types';
import FiltersPanel from 'views/FiltersPanel/FiltersPanel';

import NetworkBottom from './NetworkBottom/NetworkBottom';
import NetworkGraph from './NetworkContent/NetworkGraph';
import NetworkTop from './NetworkTop/NetworkTop';

const NetworkScreen = () => {
  const [currentZoom, setCurrentZoom] = useState<ZoomValues>();
  const forceGraphRef = useRef<ForceGraphMethods>();
  const { resetTechnology } = useSetTechnology();

  useEffect(() => {
    resetTechnology();
    // Should fire only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScreenLayout
      top={<NetworkTop />}
      content={<NetworkGraph forceGraphRef={forceGraphRef} onZoomChange={setCurrentZoom} />}
      bottom={<NetworkBottom forceGraphRef={forceGraphRef} currentZoom={currentZoom} />}
      leftSide={<FiltersPanel />}
    />
  );
};

export default NetworkScreen;
