import cn from 'classnames';
import Isvg from 'react-inlinesvg';

import logo from 'assets/svg/ng_logo_loader.svg';

import styles from './Loader.module.scss';

const Loader = () => (
  <div className={styles.container}>
    <svg className={cn(styles.spinner)} viewBox="0 0 122 122">
      <circle className={styles.track} cx="61" cy="61" r="60" fill="none" strokeWidth="2" />
      <circle className={styles.path} cx="61" cy="61" r="60" fill="none" strokeWidth="2" />
    </svg>
    <Isvg src={logo} className={styles.logo} />
  </div>
);

export default Loader;
