import { FC, createContext } from 'react';

import useContextWithCheck from 'contexts/useContextWithCheck';
import useMemoProps from 'contexts/useMemoProps';
import { noop, noopStr } from 'shared/helpers/noop';
import { ChildrenProp } from 'types';

import { AuthType } from './Auth.type';

const defaultValue = {
  isLoggedIn: false,
  signIn: noop,
  signOut: noop,
  user: {
    getName: noopStr,
    getEmail: noopStr,
    getToken: noopStr,
  },
};

const AuthContext = createContext(defaultValue);

export const useAuth = () => useContextWithCheck(AuthContext, 'AuthContext');

interface AuthProviderProps extends ChildrenProp, AuthType {}

const AuthProvider: FC<AuthProviderProps> = ({ children, ...props }) => {
  const value = useMemoProps(props);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
