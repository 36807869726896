import { BaseType, Selection } from 'd3-selection';

import config from './radarConfig';

const addRadiants = <GElement extends BaseType, Datum, PElement extends BaseType, PDatum>(
  node: Selection<GElement, Datum, PElement, PDatum>
) => {
  config.rings.forEach((ring, index) => {
    if (index !== 4 && ring.offset) {
      const rad = node.append('radialGradient').attr('id', `gradient${index}`);

      rad.append('stop').attr('offset', `${ring.offset[0]}%`).attr('stop-color', `${ring.shadow}`);
      rad.append('stop').attr('offset', `${ring.offset[1]}%`).attr('stop-color', `${ring.bgColor}`);
    }
  });
};

const addFocusQuarterGradient = <
  GElement extends BaseType,
  Datum,
  PElement extends BaseType,
  PDatum
>(
  node: Selection<GElement, Datum, PElement, PDatum>
) => {
  config.rings.forEach((ring, index) => {
    if (index !== 4) {
      const rad = node
        .append('linearGradient')
        .attr('id', `focusQuarterGradient${index}`)
        .attr('x1', ring.x1!)
        .attr('y1', ring.y1!)
        .attr('x2', ring.x2!)
        .attr('y2', ring.y2!);

      rad.append('stop').attr('offset', '0%').attr('stop-color', '#78bcff');
      rad.append('stop').attr('offset', '100%').attr('stop-color', 'transparent');
    }
  });
};

const addRadarSweepGradient = <GElement extends BaseType, Datum, PElement extends BaseType, PDatum>(
  node: Selection<GElement, Datum, PElement, PDatum>
) => {
  node
    .append('linearGradient')
    .attr('id', 'sweepGradient')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '100%')
    .attr('y2', '60%')
    .selectAll('stop')
    .data([
      { offset: '20%', color: 'rgb(35,52,70)', opacity: '0.5' },
      { offset: '100%', color: 'transparent', opacity: '0' },
    ])
    .enter()
    .append('stop')
    .attr('offset', d => d.offset)
    .attr('stop-color', d => d.color)
    .attr('stop-opacity', d => d.opacity)
    .attr('spreadMethod', 'pad');
};

export { addRadiants, addRadarSweepGradient, addFocusQuarterGradient };
