import { select } from 'd3-selection';

import config from 'core/radarConfig';
import { animation } from 'core/radarUtils';

export default () => {
  const grid = select('svg .grid');

  grid
    .append('line')
    .attr('x1', 0)
    .attr('y1', -400)
    .attr('x2', 0)
    .attr('y2', 400)
    .style('stroke', config.colors.grid)
    .style('stroke-width', 1)
    .style('opacity', 0)
    .transition(animation(300))
    .delay(2000)
    .style('opacity', 1);

  grid
    .append('line')
    .attr('x1', -400)
    .attr('y1', 0)
    .attr('x2', 400)
    .attr('y2', 0)
    .style('stroke', config.colors.grid)
    .style('stroke-width', 1)
    .style('opacity', 0)
    .transition(animation(300))
    .delay(2000)
    .style('opacity', 1);

  // draw dot in the center of radar
  grid
    .append('circle')
    .attr('r', 4)
    .attr('stroke-width', '3.5')
    .attr('stroke', '#161718')
    .attr('fill', '#d8d8d8')
    .style('opacity', 0)
    .transition(animation(300))
    .delay(1800)
    .style('opacity', 1);
};
