import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useSetInteractions } from 'contexts/Interactions/Interactions.context';
import useWhichScreenView from 'shared/hooks/useWhichScreenView';
import APP_ROUTES from 'shared/routes';
import { ChildrenProp } from 'types';

import styles from './ListItem.module.scss';

interface ListItemProps extends ChildrenProp {
  id: string;
  highlighted?: boolean;
}

const ListItem = ({ id, highlighted, children }: ListItemProps) => {
  const navigate = useNavigate();
  const { isGraph } = useWhichScreenView();
  const { setHoveredSearchResultNodeId } = useSetInteractions();

  return (
    <li>
      <button
        type="button"
        role="link"
        className={cn(styles.button, highlighted && styles.highlighted)}
        onClick={() => {
          setHoveredSearchResultNodeId(null);

          navigate(`${isGraph ? APP_ROUTES.GRAPH : APP_ROUTES.RADAR}/${id}`);
        }}
        onMouseEnter={() => {
          setHoveredSearchResultNodeId(id);
        }}
        onMouseLeave={() => {
          setHoveredSearchResultNodeId(null);
        }}
      >
        {children}
      </button>
    </li>
  );
};

export default ListItem;
