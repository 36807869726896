import Chip from 'components/Chip/Chip';
import ChipList from 'components/ChipList/ChipList';
import { TechnologyDetails } from 'types/technology.types';

interface TechnologyDetailsTagsProps {
  technology: TechnologyDetails;
}

const TechnologyDetailsTags = ({ technology }: TechnologyDetailsTagsProps) => {
  const { stage, section, tech_bet: techBet } = technology;
  if (!stage && !section && !techBet) return null;

  return (
    <ChipList>
      {section && <Chip>{section}</Chip>}
      {stage && <Chip>{stage}</Chip>}
      {techBet && <Chip>Tech Bet</Chip>}
    </ChipList>
  );
};

export default TechnologyDetailsTags;
