import { Children, isValidElement } from 'react';

import { ChildrenProp } from 'types';

import styles from './ChipList.module.scss';

const ChipList = ({ children }: ChildrenProp) => {
  return (
    <ul className={styles['chip-list']}>
      {Children.map(children, child => {
        return isValidElement(child) ? <li>{child}</li> : null;
      })}
    </ul>
  );
};

export default ChipList;
