import { useParams } from 'react-router-dom';

import SidePanel from 'components/SidePanel/SidePanel';
import TechnologyDetails from 'views/TechnologyDetails/TechnologyDetails';

const DetailsLeftside = () => {
  const { technologyId } = useParams();

  if (!technologyId) return null;

  return (
    <SidePanel>
      <TechnologyDetails technologyId={technologyId} />
    </SidePanel>
  );
};

export default DetailsLeftside;
