import Radio, { RadioProps } from 'components/Radio/Radio';

import BigCardWrapper, { BigCardWrapperProps } from '../CardWrapper/BigCardWrapper/BigCardWrapper';

type RadioCardBigProps = Omit<BigCardWrapperProps, 'children'> & Omit<RadioProps, 'standalone'>;

const RadioCardBig = ({ label, subLabel, disabled, ...inputProps }: RadioCardBigProps) => {
  return (
    <BigCardWrapper subLabel={subLabel} label={label} disabled={disabled}>
      <Radio {...inputProps} disabled={disabled} standalone={false} />
    </BigCardWrapper>
  );
};

export default RadioCardBig;
