import { select, selectAll } from 'd3-selection';

import { FixMe } from 'types';

export default (id: number | null, state: boolean) => {
  const quarters = select('.quarters');
  const quadrantTitles = select('.legend');
  const blips = selectAll('g[class^=blip]');

  quadrantTitles
    .selectAll('.quadrantTitle')
    .classed('fadedQuadrantTitle focusedQuadrantTitle', false);

  blips.selectAll('path').classed('greyedBlip fadedBlip', false);

  quarters.selectAll('path').classed('focusedQuadrant', false);

  if (state && id) {
    quadrantTitles
      .selectAll('.quadrantTitle')
      .filter((elem, index) => index !== id - 1)
      .classed('focusedQuadrantTitle', state);

    blips
      .filter((blip: FixMe) => blip.quadrant !== id - 1)
      .selectAll('path')
      .classed('greyedBlip', state);

    quarters.select(`path:nth-child(${id})`).classed('focusedQuadrant', true);
  }
};
