import { FC } from 'react';

import { Box } from '@chakra-ui/react';

import QuadrantHeader from 'components/QuadrantHeader/QuadrantHeader';
import { useInteractions } from 'contexts/Interactions/Interactions.context';
import { useFilterTechnologies } from 'contexts/Technologies/Technologies.context';
import { ChildrenProp } from 'types';

import styles from './SearchResultsWrapper.module.scss';

const SearchResultsWrapper: FC<ChildrenProp> = ({ children }) => {
  const { highlightedQuarter } = useInteractions();
  const { resetSelections } = useFilterTechnologies();

  return (
    <div data-testid="views-searchresults_wrapper" className={styles.searchResultsWrapper}>
      {highlightedQuarter !== null && (
        <Box mb="16px">
          <QuadrantHeader sectionName={highlightedQuarter} onClose={resetSelections} />
        </Box>
      )}

      {children}
    </div>
  );
};

export default SearchResultsWrapper;
