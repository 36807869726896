import { VStack } from '@chakra-ui/react';

import SidePanelSection from 'components/SidePanel/SidePanelSection/SidePanelSection';
import Skeleton from 'components/Skeleton/Skeleton';

import TechnologyDetailsAskFreely from '../TechnologyDetailsAskFreely/TechnologyDetailsAskFreely';
import TechnologyDetailsRelatedTechnologies from '../TechnologyDetailsRelatedTechnologies/TechnologyDetailsRelatedTechnologies';

interface TechnologyDetailsUnauthedProps {
  isLoading: boolean;
  technologyName: string;
}

const TechnologyDetailsUnauthed = ({
  isLoading,
  technologyName,
}: TechnologyDetailsUnauthedProps) => {
  return (
    <SidePanelSection>
      <VStack spacing="32px" align="stretch">
        <Skeleton height="200px" isLoaded={!isLoading}>
          <TechnologyDetailsAskFreely technologyName={technologyName} />
        </Skeleton>

        <Skeleton height="250px" isLoaded={!isLoading}>
          <TechnologyDetailsRelatedTechnologies />
        </Skeleton>
      </VStack>
    </SidePanelSection>
  );
};

export default TechnologyDetailsUnauthed;
